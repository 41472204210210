const initialState = {
  variation: {},
};

const AuthDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VARIATION":
      return { ...state, variation: action.payload };

    default:
      return state;
  }
};

export default AuthDetails;
