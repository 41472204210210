import apis from "../../apis/marcom";

export const createContact = (payload) => {
  return async (dispatch) => {
    try {
      console.log("hello", payload);

      let { data } = await apis.createContactUs(payload);

      console.log(data, "CONTACTDATA");
      if (data) dispatch(setContactData(data.data));
      return data;
    } catch (error) {}
  };
};
export const setContactData = (data) => {
  console.log(data, "hello");
  return {
    type: "SET_CONTACT_DATA",
    payload: data,
  };
};
