import React, { useEffect } from "react";
// import Home from "pages/home/Home";
import Home1 from "pages/home/Home1";
import Home2 from "pages/home/Home2";
import Home3 from "pages/home/Home3";
import Home4 from "pages/home/Home4";
import Home5 from "pages/home/Home5";
import HomeMain from "pages/home/HomeMain";
import About from "pages/about/About";
import Service from "pages/service/Services";
import Contact from "pages/contact/Contact";
import Portfolio from "pages/portfolio/Portfolio";
import Privacy from "pages/privacy-policy/privacy/Privacy";
import Terms from "pages/privacy-policy/terms/Terms";
import Page404 from "pages/page404";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import NavbarHeader from "navbar/NavabarHeader";
import Footer from "footer/Footer";
import Blogoverview from "./components/myblogs/Blogoverview";
import Blog from "./pages/Blog";
import ScrollToTop from "./components/ScrollToTop";
import EditorQuill from "components/quilleditor/EditorQuill";
import Work from "pages/work";
import axios from "axios";
import whatsapp from "./assets/images/WhatsApp.png";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;
export default () => {
  const appendgtag = async () => {
    const headers = {
      "Content-Type": "application/json",
      "community-name": process.env.REACT_APP_COMMUNITY_NAME,
    };
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL + "project/getProject",
      {},
      {
        headers: headers,
      }
    );
    // console.log(data.data, data.data.gTag, "Yooo Anish");
    if (data.data && data.data.gTag) {
      let s = document.createElement("script");
      s.async = true;
      s.src = "https://www.googletagmanager.com/gtag/js?id=" + data.data.gTag;
      s.onload = () => {
        window.datalayer = window.datalayer || [];
        window.datalayer.push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
          js: new Date(),
          config: data.data.gTag,
        });
      };
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  };
  useEffect(() => {
    // appendgtag();
  }, []);

  let open = (number) => {
    window.open(
      `https://api.whatsapp.com/send/?phone=%2B91${number}&text&type=phone_number&app_absent=0`
    );
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <button className="bottom_right" onClick={()=>open(9324601767)}>
				<div className="flex items-center">
					
				<img width={40} style={{backgroundColor:'transparent',paddingRight:"10px"}} src={whatsapp}/>
				<p>
				Chat With Us

				</p>
				</div>
				</button> */}
        <Router>
          <GlobalStyles />
          <ScrollToTop />
          <NavbarHeader />

          <div className="margin-nav">
            <Switch>
              <Route exact path="/" component={HomeMain} />
              <Route exact path="/home" component={HomeMain} />
              <Route exact path="/home/:token" component={HomeMain} />
              <Route exact path="/home1" component={Home1} />
              <Route exact path="/home1/:token" component={Home1} />
              <Route exact path="/home2" component={Home2} />
              <Route exact path="/home2/:token" component={Home2} />
              <Route exact path="/home3" component={Home3} />
              <Route exact path="/home3/:token" component={Home3} />
              <Route exact path="/home4" component={Home4} />
              <Route exact path="/home4/:token" component={Home4} />
              <Route exact path="/blog overview" component={Blogoverview} />
              <Route
                exact
                path="/blog overview/:token"
                component={Blogoverview}
              />
              <Route exact path="/Blog Categories" component={Blog} />
              <Route exact path="/Blog Categories/:token" component={Blog} />
              <Route exact path="/home5" component={Home5} />
              <Route exact path="/home5/:token" component={Home5} />

              <Route exact path="/Work" component={Work} />
              <Route exact path="/Work/:token" component={Work} />

              <Route exact path="/about us" component={About} />
              <Route exact path="/about us/:token" component={About} />
              <Route exact path="/services" component={Service} />
              <Route exact path="/services/:token" component={Service} />
              <Route exact path="/Contact Us" component={Contact} />
              <Route exact path="/Contact Us/:token" component={Contact} />
              <Route exact path="/portfolio" component={Portfolio} />
              <Route exact path="/portfolio/:token" component={Portfolio} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/privacy/:token" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/terms/:token" component={Terms} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/write-blog" component={EditorQuill} />
              <Route path="*" component={Page404} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};
