import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-scroll";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Services"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index11"} />
      <Helmet>
        <title>Services</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        align-items="center"
        flex-direction="column"
        justify-content="center"
      >
        <Override slot="SectionContent" margin="24px auto 24px auto" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          flex-direction="column"
        >
          <Box min-width="100px" min-height="100px" md-min-height="max-content">
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              sm-margin="0px 0px 6px 0px"
              sm-font="--subheadlined4"
              className="hoverText"
              id="1704594585"
              onClick={() => isSideBarOpen("1704594585")}
            >
              {data ? data["1704594585"] : "no data"}

              {/* Our Services */}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined2"
              sm-font="--subheadlined3"
              className="hoverText"
              id="5575869050"
              onClick={() => isSideBarOpen("5575869050")}
            >
              {data ? data["5575869050"] : "no data"}

              {/* Check what we serve.{" "} */}
            </Text>
          </Box>
          <div className="pb-4 lg:pt-0 pt-3">
            <Box className="container mx-auto grid lg:grid-cols-6 grid-cols-2 gap-4 sm-gap-2 p-0">
              <Link
                to="section1"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                >
                  <Image
                    src={
                      data
                        ? data["5320389563"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("5320389563")}
                    className="hoverText"
                    id="5320389563"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="7630048336"
                      onClick={() => isSideBarOpen("7630048336")}
                    >
                      {data ? data["7630048336"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>
              <Link
                to="section2"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                  lg-padding="30px"
                >
                  <Image
                    src={
                      data
                        ? data["6313928633"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("6313928633")}
                    className="hoverText"
                    id="6313928633"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box
                    margin="0px 0px 0px 0px"
                    padding="0px 0px 0px 0px"
                    lg-width="max-content"
                  >
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="8708178606"
                      onClick={() => isSideBarOpen("8708178606")}
                    >
                      {data ? data["8708178606"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>

              <Link
                to="section3"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-hover-hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                  lg-padding="30px"
                >
                  <Image
                    src={
                      data
                        ? data["9012616194"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("9012616194")}
                    className="hoverText"
                    id="9012616194"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="5884005428"
                      onClick={() => isSideBarOpen("5884005428")}
                    >
                      {data ? data["5884005428"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>

              <Link
                to="section4"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                  lg-padding="30px"
                >
                  <Image
                    src={
                      data
                        ? data["1478826725"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("1478826725")}
                    className="hoverText"
                    id="1478826725"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="1410639127"
                      onClick={() => isSideBarOpen("1410639127")}
                    >
                      {data ? data["1410639127"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>

              <Link
                to="section5"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                  lg-padding="30px"
                >
                  <Image
                    src={
                      data
                        ? data["9019835785"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("9019835785")}
                    className="hoverText"
                    id="9019835785"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box
                    margin="0px 0px 0px 0px"
                    padding="0px 0px 0px 0px"
                    text-align="center"
                  >
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="7327763483"
                      onClick={() => isSideBarOpen("7327763483")}
                    >
                      {data ? data["7327763483"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>

              <Link
                to="section6"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <Box
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="30px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-style="solid"
                  hover-border-color="--color-secondary"
                  box-shadow="--l"
                  sm-margin="0px 12px 0px 0px"
                  lg-padding="30px"
                >
                  <Image
                    src={
                      data
                        ? data["8819533121"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("8819533121")}
                    className="hoverText"
                    id="8819533121"
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="50px"
                    height="50px"
                  />
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined4"
                      color="--dark"
                      text-align="center"
                      lg-width="max-content"
                      sm-font="--subheadlined4"
                      sm-font-size="14px"
                      className="hoverText"
                      id="6872398152"
                      onClick={() => isSideBarOpen("6872398152")}
                    >
                      {data ? data["6872398152"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    display="none"
                  >
                    We are boutique firm design for Private Equit, Family
                    Offices and Growth - Stage Companies seeking to maximize
                    results.
                  </Text>
                </Box>
              </Link>
            </Box>
          </div>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
