import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "qapi";

import "assets/css/index.css";
import "assets/css/swiper.css";
import "../src/assets/scss/Index.scss";
import "../src/assets/scss/Font.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { i18n } from "element-react";
import locale from "element-react/src/locale/lang/en";
import "element-theme-default";
import "../src/assets/styles/tailwind.css";
import "../src/assets/styles/tailwind.generated.css";
// import 'rsuite/dist/styles/rsuite-default.min.css';
// import "@szhsin/react-menu/dist/index.css";
// import "@szhsin/react-menu/dist/transitions/slide.css";
i18n.use(locale);
window.QAPI = QAPI;
ReactDOM.render(<App />, document.getElementById("root"));
