const initialState = {
  //   project: {},
  Blog: [],
  Category: [],
  addSingleBlog: {},
};
const BlogDetails = (state = initialState, action) => {
  switch (action.type) {
    case "setBlog":
      return { ...state, Blog: action.payload };
    case "setCategory":
      return { ...state, Category: action.payload };

    case "ADD_SINGLE_BLOG":
      return {
        ...state,
        addSingleBlog: action.payload,
      };
    default:
      return state;
  }
};

export default BlogDetails;
