import React, { useEffect } from "react";
import theme from "theme";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Input, Section } from "@quarkly/widgets";
import { createContact } from "redux/marcom/marcom-action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Checkbox } from "element-react";
import "sweetalert2/src/sweetalert2.scss";

import Btn from "../Btn";

import Fade from "react-reveal/Fade";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  addSingleProduct,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[^\s]+(\s[^\s]+)*[^\s]$/, "Invalid Format")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Full Name is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(6, "Enter valid number")
    .max(10, "Enter valid number"),
  email: Yup.string().email("Not Valid Email").required("Email id is required"),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Field cannot exceed 500 characters"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
);
const defaultProps = {
  background: "--color-primary",
  "sm-align-items": "center",
  "sm-justify-content": "center",

  "justify-content": "center",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      display: "flex",
      "justify-content": "space-between",
      "sm-flex-direction": "column",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "50%",
      padding: "0px 12px 0px 0px",
      "lg-font": "--headline4",
      "sm-width": "100%",
      "sm-margin": "0px 0px 48px 0px",
      "md-width": "50%",
      "md-display": "flex",
      "md-align-items": "flex-start",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      "sm-width": "100%",
      "sm-font": "--headline4",
      "md-font": "--headline3",
      "lg-font": "--headline3",
      font: "--headline1",
      children: (
        <>
          Let’s <br />
          Work Together{" "}
        </>
      ),
      className: "hoverText",
      id: "3740607205",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      width: "60%",
      "sm-width": "100%",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 16px 0px",
      font: "--subheadlined3",
      color: "--primary",
      padding: "0px 0px 0 0px",
      "letter-spacing": "4px",
      "sm-font": "--subheadlined4",
      "md-font": "--subheadlined4",
      "md-display": "none",
      children: "Connect Us",
      id: "3265742750",
      className: "hoverText",
    },
  },
  box3: {
    kind: "Box",
    props: {
      "align-items": "center",
      margin: "0px 0px 40px 0px",
      "sm-margin": "0px 0px 16px 0px",
    },
  },
  input: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "--dark",
      width: "100%",
      margin: "0px 0px 0px 0px",
      height: "60px",
      color: "black",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "#f4f5f6",
      placeholder: "Full Name*",
      id: "5339832869",
      "font-weight": "500",
    },
  },
  box4: {
    kind: "Box",
    props: {
      display: "flex",
      "align-items": "center",
      margin: "0px 0px 32px 0px",
      "sm-display": "flex",
      "sm-flex-direction": "column",
      "sm-margin": "0px 0px 16px 0px",
    },
  },
  input1: {
    kind: "Input",
    props: {
      "placeholder-color": "--dark",
      width: "100%",
      margin: "0px 24px 0px 0px",
      height: "60px",
      color: "black",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "#f4f5f6",
      placeholder: "Phone Number*",
      display: "flex",
      "sm-margin": "0px 0 16px 0px",
      id: "6885361906",
      "font-weight": "500",
    },
  },
  input2: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "--dark",
      width: "100%",
      height: "60px",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      color: "black",
      background: "#f4f5f6",
      placeholder: "Email Id*",
      id: "0302473180",
      "font-weight": "500",
    },
  },
  box5: {
    kind: "Box",
    props: {
      "align-items": "center",
      margin: "0px 0px 32px 0px",
    },
  },
  input3: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "--dark",
      width: "100%",
      margin: "0px 0px 0px 0px",
      height: "60px",
      color: "black",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "#f4f5f6",
      placeholder: "Your Message",
      id: "2426214357",
      "font-weight": "500",
    },
  },
  btn: {
    kind: "Btn",
    props: {
      width: "100%",
      color: "--light",
      height: "max-content",
      padding: "24px 24px 24px 24px",
      "border-radius": "10px",
      "sm-font": "--bt2",
      "md-padding": "16px 24px 16px 24px",
      "sm-align-items": "center",
      "sm-justify-content": "center",
      className: "brad-4 textwhite skinButton",
      id: "5748094947",
    },
  },
};

const ContactUs = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState("");
  const dispatch = useDispatch();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleemailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlemessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handlecheckChange = (event) => {
    setCheck(event);
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Data");
    let payload = {
      name: formik.values.username.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      description: formik.values.message.trim(),
    };
    if (
      payload.name !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== ""
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Thankyou for reaching out",
          text: "Our team will connect with you shortly!",
          icon: "success",
        });

        console.log(data.code, "registercode");
      }
    } else {
      // alert(data.message);
      Swal.fire({
        title: "Error",
        text: "Please enter valid credential",
        icon: "error",
      });
      console.log(data.message, "datamessage");
    }
  };
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;

  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );

  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        color="#ffffff"
        margin="60px auto 60px auto"
        sm-margin="16px auto 16px auto"
      />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Fade bottom>
            <Text
              {...override("text")}
              onClick={() => isSideBarOpen("3740607205")}
            >
              {data ? data["3740607205"] : "no data"}
            </Text>
          </Fade>
        </Box>
        <Box {...override("box2")} className="contactcss">
          <Fade bottom>
            <Text
              {...override("text1")}
              onClick={() => isSideBarOpen("3265742750")}
            >
              {data ? data["3265742750"] : "no data"}
            </Text>
          </Fade>
          <form>
            <Fade bottom>
              <Box {...override("box3")}>
                <Input
                  id="username"
                  name="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // value={name}
                  value={formik.values.username}
                  {...override("input")}
                />
                {formik.touched.username && formik.errors.username && (
                  <ErrorComponent error={formik.errors.username} />
                )}
              </Box>
            </Fade>
            <Fade bottom>
              <Box {...override("box4")} className="lg:gap-8 md:gap-8">
                <Box className="lg:w-1/2 md:w-1/2 w-full">
                  <Input
                    {...override("input1")}
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <ErrorComponent error={formik.errors.phoneNumber} />
                  )}
                </Box>
                <Box className="lg:w-1/2 md:w-1/2 w-full">
                  <Input
                    {...override("input2")}
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <ErrorComponent error={formik.errors.email} />
                  )}
                </Box>
              </Box>
            </Fade>
            <Fade bottom>
              <Box {...override("box5")}>
                <Input
                  {...override("input3")}
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message && (
                  <ErrorComponent error={formik.errors.message} />
                )}
              </Box>
            </Fade>

            <div className="pb-3">
              <Fade bottom>
                <Checkbox
                  value={check}
                  onChange={handlecheckChange}
                  className="text-dark fsize15"
                  checked
                >
                  Check to subscribe to our Newsletter
                </Checkbox>
              </Fade>
            </div>
            <Fade bottom>
              <div
                onClick={() => isSideBarOpen("5748094947")}
                id="5748094947"
                className="hoverText"
              >
                <Btn
                  onClick={handleSubmit}
                  className="text-white"
                  {...override("btn")}
                />
              </div>
            </Fade>
          </form>
        </Box>
      </Box>
      {children}
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

Object.assign(ContactUs, { ...Section, defaultProps, overrides });
export default ContactUs;
