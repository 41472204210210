import React from "react";
import error from "../assets/images/error.svg";
import { NavLink } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="bg-white container flex justify-center items-center mx-auto lg:py-32 py-10">
      <div className="p-12 bg-white text-center shadow border">
        <div className="flex justify-center">
          <img
            src={error}
            className="rounded-full object-contain w-40 h-40"
            alt="profile"
          />
        </div>
        <h2 className="text-danger text-2xl font-semibold">Page Not Found</h2>
        <p className="text-secondary text-sm mt-2">
          The link you clicked may be broken or the page may have been
          <br />
          removed or renamed
        </p>
        <div className="flex justify-center w-full mt-4">
          <NavLink to="/Home">
            <button className="rounded-full fsize14 text-white bg-dark py-2 px-12">
              Back to Home
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Page404;
