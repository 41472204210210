import API from "./index";

const get = "get";

const apiBase = (route) => {
  return `ecom/product/${route}`;
};

export default {
  getAllProduct() {
    return API.post(apiBase(get));
  },
};
