const initialState = {
  product: [],
};
const product_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setProductData":
      return { ...state, product: action.payload };
    case "ADD_SINGLE_PRODUCT":
      return {
        ...state,
        singleProduct: action.payload,
      };
    case "ADD_DEFAULT_IMAGE":
      return { ...state, defaultImage: action.payload };

    case "ADD_DEFAULT_Variant":
      return { ...state, defaultVariantData: action.payload };

    case "ADD_SELECTED_COLOR":
      return { ...state, addSelectedColor: action.payload };

    default:
      return state;
  }
};

export default product_reducer;
