import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
const defaultProps = {
  margin: "0 auto 0 auto",
  "sm-align-items": "center",
  "sm-justify-content": "center",
  "align-items": "center",
  "justify-content": "center",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      "sm-display": "flex",
      "sm-align-items": "center",
      "sm-flex-direction": "column",
      "sm-justify-content": "center",
    },
  },
  box1: {
    kind: "Box",
    props: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      margin: "0px 0px 48px 0px",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 16px 0px",
      font: "--subheadlined3",
      "letter-spacing": "4PX",
      color: "--primary",
      "sm-letter-spacing": "1px",
      "md-font": "--subheadlined4",
      "lg-font": "--subheadlined4",
      "lg-margin": "0px 0px 8px 0px",
      children: "Portfolio",
      "sm-font": "--subheadlined4",
      className: "hoverText",
      id: "8199816201",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 24px 0px",
      font: "--subheadlined1",
      "sm-text-align": "center",
      "lg-margin": "0px 0px 16px 0px",
      "md-font": "--subheadlined2",
      children: "Industries What We Serve",
      "sm-font": "--subheadlined3",
      className: "hoverText",
      id: "0975635559",
    },
  },
  box2: {
    kind: "Box",
    props: {
      display: "flex",
      width: "60%",
      "sm-width": "100%",
      "md-width": "80%",
      "lg-width": "80%",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--p2",
      "text-align": "center",
      "lg-font": "--p3",
      "md-font": "--p3",
      children:
        "We are boutique  firm design for Private Equit, Family Offices and Growth - Stage Companies seeking to maximize results.",
      "sm-font": "--p4",
      className: "hoverText",
      id: "3498353673",
    },
  },
  box3: {
    kind: "Box",
    props: {
      "min-width": "300px",
      "min-height": "300px",
      width: "100%",
    },
  },
  image: {
    kind: "Image",
    props: {
      src: "https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
      display: "block",
      width: "100%",
      height: "350px",
      "object-fit": "cover",
      "border-radius": "16px",
      "border-width": "0 0 10px 0",
      "border-color": "--color-secondary",
      "hover-box-shadow": "--xl",
      "hover-border-style": "solid",
      "box-shadow": "0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1",
      className: "hoverText",
      id: "9201747495",
    },
  },

  SectionContent: {
    props: {
      "sm-margin": "8px 18.75px 0 18.75px",
    },
  },
};

const Portfolio = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <Section display="flex" justify-content="center">
      <Override
        slot="SectionContent"
        margin="40px 80px 40px 80px"
        sm-margin="8px 15px 8px 15px"
        sm-align-items="center"
        sm-justify-content="center"
        lg-margin="40px auto 40px auto"
        className="w-full"
      />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Text {...override("text")} />
          <Text {...override("text1")} />
          <Box {...override("box2")}>
            <Text {...override("text2")} />
          </Box>
        </Box>
      </Box>
      <Box>
        <Swiper
          observer={true}
          observeParents={true}
          className=""
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <SwiperSlide className="">
            <Box>
              <Box {...override("box3")}>
                <Image {...override("image")} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <Box {...override("box3")}>
                <Image {...override("image")} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <Box {...override("box3")}>
                <Image {...override("image")} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <Box {...override("box3")}>
                <Image {...override("image")} />
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Section>
  );
};

Object.assign(Portfolio, { ...Section, defaultProps, overrides });
export default Portfolio;
