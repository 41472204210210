import React, { useEffect } from "react";
import theme from "theme";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import Reveal from "react-reveal/Reveal";

// import {
//   addSingleProduct,
//   defaultImage,
//   defaultVariantData,
// } from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

const defaultProps = {
  margin: "0 auto 0 auto",
  "sm-align-items": "center",
  "sm-justify-content": "center",
  "align-items": "center",
  "justify-content": "center",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      "sm-display": "flex",
      "sm-align-items": "center",
      "sm-flex-direction": "column",
      "sm-justify-content": "center",
    },
  },
  box1: {
    kind: "Box",
    props: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      margin: "0px 0px 48px 0px",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 16px 0px",
      font: "--subheadlined3",
      "letter-spacing": "4PX",
      color: "--primary",
      "sm-letter-spacing": "1px",
      "md-font": "--subheadlined4",
      "lg-font": "--subheadlined4",
      "lg-margin": "0px 0px 8px 0px",
      children: "Portfolio",
      "sm-font": "--subheadlined4",
      className: "hoverText",
      id: "8199816201",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 24px 0px",
      font: "--subheadlined1",
      "sm-text-align": "center",
      "lg-margin": "0px 0px 16px 0px",
      "md-font": "--subheadlined2",
      children: "Industries What We Serve",
      "sm-font": "--subheadlined3",
      className: "hoverText",
      id: "0975635559",
    },
  },
  box2: {
    kind: "Box",
    props: {
      display: "flex",
      width: "60%",
      "sm-width": "100%",
      "md-width": "80%",
      "lg-width": "80%",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--p2",
      "text-align": "center",
      "lg-font": "--p3",
      "md-font": "--p3",
      children:
        "We are boutique  firm design for Private Equit, Family Offices and Growth - Stage Companies seeking to maximize results.",
      "sm-font": "--p4",
      className: "hoverText",
      id: "3498353673",
    },
  },
  box3: {
    kind: "Box",
    props: {},
  },
  image: {
    kind: "Image",
    props: {
      display: "block",
      width: "100%",
      height: "350px",
      "object-fit": "cover",
      "border-radius": "16px",
      "border-width": "0 0 10px 0",
      "border-color": "--color-secondary",
      "hover-box-shadow": "--xl",
      "hover-border-style": "solid",
      "box-shadow": "0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1",
    },
  },

  SectionContent: {
    props: {
      "sm-margin": "8px 18.75px 0 18.75px",
    },
  },
};

const Portfolio = (props) => {
  const { override } = useOverrides(props, overrides, defaultProps);
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  // let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  // let userData = useSelector((state) => state.cms.userData);
  // let productData = useSelector((state) => state.product.product);
  // let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());
    //
    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  // function showProduct(data) {
  //   console.log("show product");
  //   dispatch(addSingleProduct(data));
  //   let defImage = data.productImageId[0].productImage;
  //   dispatch(defaultImage(defImage));
  //   let defVarint = data.variationId[0];
  //   dispatch(defaultVariantData(defVarint));
  //   let defAttributeName = data.productImageId[0].da;
  //   dispatch(defaultVariantData(defVarint));
  //   console.log(defImage, "hellos");
  // }
  // function getFavicon(value) {
  //   return value
  //     ? value.favIcon
  //       ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
  //       : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
  //     : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  // }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Section display="flex" justify-content="center">
      <Override
        slot="SectionContent"
        margin="40px 80px 40px 80px"
        sm-margin="8px 15px 8px 15px"
        sm-align-items="center"
        sm-justify-content="center"
        lg-margin="40px auto 40px auto"
        className="w-full cust-w100"
      />
      <Box {...override("box")}>
        <Reveal>
          <Box {...override("box1")}>
            <Text
              {...override("text")}
              onClick={() => isSideBarOpen("8199816201")}
            >
              {" "}
              {data ? data["8199816201"] : "no data"}
            </Text>
            <Text
              {...override("text1")}
              onClick={() => isSideBarOpen("0975635559")}
            >
              {" "}
              {data ? data["0975635559"] : "no data"}
            </Text>
            <Box {...override("box2")}>
              <Text
                {...override("text2")}
                onClick={() => isSideBarOpen("3498353673")}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: data ? data["3498353673"] : "no-data",
                  }}
                ></p>
              </Text>
            </Box>
          </Box>
        </Reveal>
      </Box>
      <Reveal>
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper container relative lg:mx-auto md:mx-5  overflow-x-hidden"
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <Reveal>
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>

            <SwiperSlide
              onClick={() => isSideBarOpen("0048959205")}
              className="hoverText"
            >
              <Box margin="15px 0" width="100%">
                <Box {...override("box3")}>
                  <Image
                    src={
                      data
                        ? data["0048959205"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    className=""
                    id="0048959205"
                    display="block"
                    width="100%"
                    height="350px"
                    object-fit="cover"
                    border-radius="16px"
                    hover-border-width="0 0 10px 0"
                    border-width="0 0 0px 0"
                    border-color="--color-secondary"
                    hover-box-shadow="--xl"
                    hover-border-style="solid"
                    box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
                  />
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                margin="15px 0"
                onClick={() => isSideBarOpen("8922235061")}
                className="hoverText"
                width="100%"
              >
                <Box>
                  <Box {...override("box3")}>
                    <Image
                      src={
                        data
                          ? data["8922235061"]
                          : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      }
                      id="8922235061"
                      display="block"
                      width="100%"
                      height="350px"
                      object-fit="cover"
                      border-radius="16px"
                      hover-border-width="0 0 10px 0"
                      border-width="0 0 0px 0"
                      border-color="--color-secondary"
                      hover-box-shadow="--xl"
                      hover-border-style="solid"
                      box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
                      className=""
                    />
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                margin="15px 0"
                onClick={() => isSideBarOpen("7593644922")}
                className="hoverText"
                width="100%"
              >
                <Box {...override("box3")}>
                  <Image
                    src={
                      data
                        ? data["7593644922"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    onClick={() => isSideBarOpen("7593644922")}
                    className="hoverText "
                    id="7593644922"
                    display="block"
                    width="100%"
                    height="350px"
                    object-fit="cover"
                    border-radius="16px"
                    hover-border-width="0 0 10px 0"
                    border-width="0 0 0px 0"
                    border-color="--color-secondary"
                    hover-box-shadow="--xl"
                    hover-border-style="solid"
                    box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
                  />
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                margin="15px 0"
                onClick={() => isSideBarOpen("9772929606")}
                className="hoverText"
                width="100%"
              >
                <Box {...override("box3")}>
                  <Image
                    src={
                      data
                        ? data["9772929606"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    id="9772929606"
                    display="block"
                    width="100%"
                    height="350px"
                    object-fit="cover"
                    border-radius="16px"
                    hover-border-width="0 0 10px 0"
                    border-width="0 0 0px 0"
                    border-color="--color-secondary"
                    hover-box-shadow="--xl"
                    hover-border-style="solid"
                    box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
                    className=""
                  />
                </Box>
              </Box>
            </SwiperSlide>
          </Reveal>
          {/* <SwiperSlide>
          <Box
            onClick={() => isSideBarOpen("9772929606")}
            className="hoverText"
            width="100%"
          >
            <Box {...override("box3")}>
              <Image
                src={
                  data
                    ? data["9772929606"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                id="9772929606"
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                border-width="0 0 10px 0"
                border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
          </Box>
        </SwiperSlide> */}
        </Swiper>
      </Reveal>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

Object.assign(Portfolio, { ...Section, defaultProps, overrides });
export default Portfolio;
