import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";

import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";

import theme from "theme/index1";
import { Theme, Image, Box, Button, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";

import Brands from "./components/Brands";
import Testimonial from "components/testimonial/Testimonial";
import ContactUs from "components/contact/Contact";
import Team from "components/team/Team";
import Portfolio from "components/portfolio/Portfolio";

import {
  addSingleProduct,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

import { getBrandProfile } from "redux/BrandDetails/bd-actions";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);

      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  const handleClick = () => {
    const element = document.getElementById("contactpage");
    element.scrollIntoView();
  };

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");

    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home-1</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        position="relative"
        padding="40px 60px 44px 60px"
        md-padding="140px 40px 44px 40px"
        sm-padding="80px 20px 22px 20px"
        background="--color-primary"
        z-index="99"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 24px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
          flex-direction="row"
          md-flex-direction="column"
          sm-align-items="flex-start"
          sm-margin="0px auto 0px auto"
        />

        <Box
          align-items="center"
          display="flex"
          justify-content="center"
          border-radius="40px"
          position="relative"
          sm-height="260px"
          md-width="100%"
          md-padding="0px 0px 0px 0px"
          lg-width="40%"
          margin="0px 60px 0px 0px"
          width="50%"
          lg-margin="0px 40px 0px 0px"
          md-margin="0px 0px 0px 0px"
          // border-width="2px"
          className="hoverText"
          id="9399343161"
          onClick={() => isSideBarOpen("9399343161")}
        >
          <Fade bottom>
            <Image
              display="block"
              height="450px"
              object-fit="cover"
              border-radius="40px"
              sm-height="100%"
              md-width="100%"
              width="100%"
              className="hoverText"
              md-height="360px"
              sm-border-radius="20px"
              src={
                data
                  ? data["9399343161"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/digital-agency/cms/default2.jpg-vTMGz4RUpEI_d4KtybVGZ-1686316156"
              }
              border-width="6px"
              border-style="solid"
              border-color="--color-darkL1"
            />
          </Fade>
        </Box>

        <Fade bottom>
          <Box
            display="flex"
            justify-content="space-between"
            align-items="flex-start"
            flex-direction="column"
            margin="90PX 0px 60px 0px"
            md-width="100%"
            sm-margin="20PX 0px 24px 0px"
            width="60%"
            lg-width="60%"
            md-margin="40px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 18px 0px"
              font="--poppin72B"
              text-align="left"
              display="flex"
              flex-direction="column"
              color="#fff"
              sm-width="90%"
              sm-font="--subheadlined2"
              md-font="--headline4"
              className="hoverText"
              align-items="flex-start"
              width="100%"
              align-content="flex-end"
              sm-margin="0px 0px 8px 0px"
              line-height="80px"
              id="8922600886"
              onClick={() => isSideBarOpen("8922600886")}
            >
              {data ? data["8922600886"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              text-align="left"
              display="flex"
              flex-direction="column"
              font="--p1"
              color="#fff"
              sm-font="--p5"
              lg-font="--p2"
              md-font="--p3"
              className="hoverText"
              align-items="flex-start"
              width="80%"
              sm-width="100%"
              id="8978450592"
              dangerouslySetInnerHTML={{
                __html: data ? data["8978450592"] : "no data",
              }}
              onClick={() => isSideBarOpen("8978450592")}
            >
              {/* {data ? data["8978450592"] : "no data"} */}
            </Text>
          </Box>
        </Fade>

        <Box
          background="--color-darkL1"
          position="absolute"
          top="auto"
          bottom="-141px"
          height="810.4120523158483px"
          left="auto"
          right="-690px"
          z-index="-1"
          lg-bottom="-31px"
          lg-left="auto"
          lg-right="-320px"
          lg-top="auto"
          lg-height="748.7072998046875px"
          md-bottom="-301px"
          md-left="-133px"
          md-right="auto"
          md-top="auto"
          sm-bottom="-488px"
          sm-left="auto"
          sm-right="-216px"
          sm-top="auto"
        />
      </Section>
      <Brands />
      <Section
        sm-align-items="center"
        sm-justify-content="center"
        sm-padding="22px 0 8px 0"
        align-items="center"
        justify-content="center"
        height="auto"
      >
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          lg-margin="40px auto 40px auto"
          sm-margin="20px auto 26px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
          lg-align-items="center"
          lg-justify-content="center"
          align-items="center"
          lg-height="380px"
          sm-height="528px"
          width="100%"
        >
          <Box
            width="50%"
            margin="0px 40px 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            sm-width="100%"
            sm-padding="0px 0 0px 0px"
            sm-margin="0px 0px 26px 0px"
            lg-padding="0px 0px 0px 0px"
            lg-height="max-content"
            lg-width="50%"
            md-justify-content="space-between"
            md-margin="0px 20px 0px 0px"
            sm-align-items="center"
          >
            <Fade bottom>
              <Text
                margin="0px 0px 8px 0px"
                font="--subheadlined4"
                letter-spacing="4PX"
                color="--primary"
                sm-font="--subheadlined5"
                lg-margin="0px 0px 8px 0px"
                md-font="--subheadlined4"
                lg-font="--subheadlined4"
                className="hoverText"
                sm-letter-spacing="1px"
                sm-text-align="center"
                sm-margin="0px 0px 2px 0px"
                id="1632289585"
                onClick={() => isSideBarOpen("1632289585")}
              >
                {data ? data["1632289585"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 16px 0px"
                font="--headline4"
                sm-font="--subheadlined2"
                sm-margin="0px 0px 22px 0px"
                lg-margin="0px 0px 16px 0px"
                md-font="--subheadlined2"
                md-margin="0px 0px 24px 0px"
                lg-font="--subheadlined2"
                className="hoverText sm-fsize24"
                sm-width="100%"
                sm-text-align="center"
                id="7689255120"
                onClick={() => isSideBarOpen("7689255120")}
              >
                {data ? data["7689255120"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 24px 0px"
                font="--p2"
                sm-font="--p4"
                lg-font="--p4"
                md-margin="0px 0px 24px 0px"
                className="hoverText"
                width="80%"
                sm-width="100%"
                sm-text-align="center"
                id="3313680024"
                onClick={() => isSideBarOpen("3313680024")}
                dangerouslySetInnerHTML={{
                  __html: data ? data["3313680024"] : "no-data",
                }}
              ></Text>

              <div
                id="0412288477"
                className="hoverText"
                onClick={() => isSideBarOpen("0412288477")}
              >
                <Button
                  background="--color-primary"
                  color="--light"
                  font="--subheadlined5"
                  padding="15px 26px 15px 26px"
                  sm-font="--bt2"
                  sm-display="flex"
                  sm-flex-direction="row"
                  sm-margin="0px 8px 0px 0px"
                  md-display="block"
                  className="hoverText brad-4   skinButton"
                  sm-padding="16px 26px 16px 26px"
                  sm-border-radius="6px"
                  border-radius="8px"
                >
                  {data ? data["0412288477"] : "Enquire Now"}
                </Button>
              </div>
            </Fade>
          </Box>

          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            sm-width="100%"
            // lg-border-width="8px 0 0  0"
            lg-border-color="--color-green"
            lg-height="100%"
            className="hoverText"
            onClick={() => isSideBarOpen("4186580645")}
          >
            <Fade bottom>
              <Image
                src={
                  data
                    ? data["4186580645"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                object-fit="cover"
                border-radius="40px"
                id="4186580645"
                height="100%"
                sm-border-radius="0px"
              />
            </Fade>
          </Box>
        </Box>
      </Section>

      <Section
        position="static"
        sm-align-items="flex-end"
        align-items="center"
        justify-content="center"
        padding="60px 0 60px 0"
        sm-padding="28px 0 28px 0"
      >
        <Override slot="SectionContent" margin="0px auto 0px auto" />
        <Box
          width="100%"
          sm-margin="0px auto 0px auto"
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          className="cust-w100"
        >
          <Reveal>
            <Box
              display="flex"
              flex-direction="column"
              align-items="center"
              margin="0px 0px 60px 0px"
              md-margin="0px 0px 40px 0px"
            >
              <Text
                margin="0px 0px 16px 0px"
                font="--subheadlined3"
                letter-spacing="4PX"
                color="--primary"
                sm-font="--subheadlined5"
                sm-letter-spacing="1px"
                md-font="--subheadlined4"
                md-margin="0px 0px 8px 0px"
                lg-font="--subheadlined4"
                className="hoverText"
                id="0633521212"
                onClick={() => isSideBarOpen("0633521212")}
              >
                {data ? data["0633521212"] : "no data"}
                {/* Our Key Services */}
              </Text>
              <Text
                margin="0px 0px 24px 0px"
                font="--subheadlined1"
                sm-font="--subheadlined3"
                sm-text-align="center"
                md-margin="0px 0px 24px 0px"
                md-font="--subheadlined2"
                sm-margin="0px 0px 12px 0px"
                className="hoverText"
                id="3734049364"
                onClick={() => isSideBarOpen("3734049364")}
              >
                {data ? data["3734049364"] : "no data"}
                {/* Industries What We Serve */}
              </Text>
              <Box
                display="flex"
                width="60%"
                sm-width="100%"
                md-width="80%"
                lg-width="80%"
                id="0629982000"
                className="hoverText"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  lg-font="--p3"
                  lg-min-width="none"
                  lg-min-height="none"
                  className="hoverText"
                  id="0629982000"
                  onClick={() => isSideBarOpen("0629982000")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["0629982000"] : "no data",
                  }}
                >
                  {/* {data ? data["0629982000"] : "no data"} */}
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Box>
          </Reveal>
          <Reveal>
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper"
              autoplay={{
                delay: "1000",
              }}
              loop
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 2.5,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 2.5,
                  spaceBetween: 30,
                },
                505: {
                  slidesPerView: 1.2,
                  spaceBetween: 15,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 15,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 15,
                },
              }}
            >
              <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>

              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="8380951052"
                    onClick={() => isSideBarOpen("8380951052")}
                  >
                    <Image
                      src={
                        data
                          ? data["8380951052"]
                          : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("4459966185")}
                      id="4459966185"
                    >
                      {data ? data["4459966185"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="2184136934"
                    onClick={() => isSideBarOpen("2184136934")}
                  >
                    {data ? data["2184136934"] : " no data"}
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="9338462607"
                    onClick={() => isSideBarOpen("9338462607")}
                  >
                    <Image
                      src={
                        data
                          ? data["9338462607"]
                          : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20485.png?v=2023-06-08T07:03:21.205Z"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("7573397008")}
                      id="7573397008"
                    >
                      {data ? data["7573397008"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="7999605823"
                    onClick={() => isSideBarOpen("7999605823")}
                  >
                    {data ? data["7999605823"] : "no data"}
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="5127224140"
                    onClick={() => isSideBarOpen("5127224140")}
                  >
                    <Image
                      src={
                        data
                          ? data["5127224140"]
                          : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20486.png?v=2023-06-08T07:03:21.208Z"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("5994371340")}
                      id="5994371340"
                    >
                      {data ? data["5994371340"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="8506712069"
                    onClick={() => isSideBarOpen("8506712069")}
                    dangerouslySetInnerHTML={{
                      __html: data ? data["8506712069"] : "no data",
                    }}
                  >
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="5378513421"
                    onClick={() => isSideBarOpen("5378513421")}
                  >
                    <Image
                      src={
                        data
                          ? data["5378513421"]
                          : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("2932463085")}
                      id="2932463085"
                    >
                      {data ? data["2932463085"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="6460377051"
                    onClick={() => isSideBarOpen("6460377051")}
                  >
                    {data ? data["6460377051"] : "no data"}
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="1540216442"
                    onClick={() => isSideBarOpen("1540216442")}
                  >
                    <Image
                      src={
                        data
                          ? data["1540216442"]
                          : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("4178111992")}
                      id="4178111992"
                    >
                      {data ? data["4178111992"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="8216480916"
                    onClick={() => isSideBarOpen("8216480916")}
                  >
                    {data ? data["8216480916"] : "no data "}
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box
                  min-width="100px"
                  min-height="100px"
                  display="flex"
                  flex-direction="column"
                  align-items="center"
                  padding="55px 40px 55px 40px"
                  md-padding="45px 30px 45px 30px"
                  sm-padding="14px 14px 14px 14px"
                  background="--color-darkL1"
                  hover-border-width="10px 0 0 0"
                  border-width="0px 0 0 0"
                  border-style="solid"
                  border-color="--color-secondary"
                  box-shadow="--l"
                  href="/index11"
                >
                  <div
                    className="hoverText"
                    id="9041677121"
                    onClick={() => isSideBarOpen("9041677121")}
                  >
                    <Image
                      src={
                        data
                          ? data["9041677121"]
                          : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      }
                      display="block"
                      margin="0px 0px 24px 0px"
                      width="60px"
                      height="60px"
                    />
                  </div>
                  <Box margin="0px 0px 0px 0px" padding="0px 0px 15px 0px">
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--subheadlined3"
                      color="--dark"
                      text-align="center"
                      sm-font="--subheadlined4"
                      md-font="--subheadlined4"
                      className="hoverText"
                      onClick={() => isSideBarOpen("1457927004")}
                      id="1457927004"
                    >
                      {data ? data["1457927004"] : "no data"}
                    </Text>
                  </Box>
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--p2"
                    text-align="center"
                    sm-font="--p4"
                    md-font="--p3"
                    lg-font="--p4"
                    className="fsize15 hoverText"
                    id="4126878104"
                    onClick={() => isSideBarOpen("4126878104")}
                  >
                    {data ? data["4126878104"] : "no data"}
                    {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                  </Text>
                </Box>
              </SwiperSlide>
            </Swiper>
          </Reveal>
        </Box>
      </Section>
      <Portfolio />
      <Testimonial />
      <Team />
      <ContactUs id="contactpage" />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
