import React from "react";
import Sidebar from "react-sidebar";
import { Navigation } from "swiper";
import { connect } from "react-redux";
import {
  getElementById,
  updateStore,
  updateCms,
  uploadImage,
} from "redux/CMS/cms-actions";
import FeatherIcon from "feather-icons-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Input, Upload } from "element-react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

class ContentUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: props.isShow,
      content: "",
      imageUrl: "",
      baseUrl: process.env.REACT_APP_STORAGE_URL,
      file: {},
      formData: new FormData(),
      quillContent: "",
      loop: [],
      selectedData: {},
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    console.log(this.props, "props");
  }

  async componentDidMount() {
    console.log("MOUNT");
    if (this.props.isShow) {
      let data = await this.props.dispatch(
        getElementById(this.props.selectedElement)
      );
      console.log(data, "cmsData");
      if (data.length) {
        this.setState({
          selectedData: data[0],
        });
        if (data[0].contentType === "Image") {
          this.setState({
            imageUrl: this.state.baseUrl + data[0].content,
          });
          this.setState({ content: data[0].content });
        } else if (data[0].contentType === "Cards") {
          if (data[0].hasOwnProperty("loop") && data[0].loop.length) {
            let data1 = data[0].loop;
            let cards = [];
            for (let i = 0; i < data1.length; i++) {
              let cardElements = Object.keys(data1[i]);
              let elementIndex = 0;
              cardElements.forEach((x) => {
                console.log(parseInt(x.slice(-1)));
                if (parseInt(x.slice(-1)) > elementIndex)
                  elementIndex = parseInt(x.slice(-1));
              });
              let card = [];
              for (let j = 1; j <= elementIndex; j++) {
                let payload = {};

                payload["fieldValue"] = data1[i][`field${j}`];
                payload["type"] = data1[i][`fieldType${j}`];
                payload["id"] = data1[i][`fieldId${j}`];
                payload["link"] = data1[i][`fieldLink${j}`]
                  ? data1[i][`fieldLink${j}`]
                  : "";
                payload["loopLink"] = data1[i][`fieldLink${j}`] ? true : false;
                payload["newFile"] = false;
                payload["file"] = "";
                card.push(payload);
              }
              cards.push(card);
            }
            console.log(cards, "cards");
            this.setState({ loop: cards });
          }
        } else {
          this.setState({ content: data[0].content });
        }
      }
    }
  }

  handleEditorChange = (value) => {
    this.setState({ quillContent: value });
  };
  async componentDidUpdate() {
    console.log("useeffect");
    // if (!this.props.isShow && this.state.content) {
    //   await this.setState({ content: "" });
    //   console.log(this.props.selectedData, "after close");
    // }
    // if (this.props.isShow && !!this.props.selectedData && !this.state.content) {
    //   console.log("fetch");
    //   await this.props.dispatch(getElementById(this.props.selectedElement));
    //   console.log(!this.state.content);
    //   if (!this.state.content) {
    //     this.setState({ content: this.props.selectedData.content });
    //     console.log(this.props, "update state");
    //   }
    //   if (!this.state.imageUrl) {
    //     this.setState({
    //       imageUrl: this.state.baseUrl + this.props.selectedData.content,
    //     });
    //     console.log(this.props, "update state");
    //   }
    // }
  }

  async handleInput(event) {
    this.setState({ content: event.target.value });
    console.log(
      this.state.content,
      this.state.selectedData.fieldId,
      "update cms"
    );
    let payload = {
      pageName: this.state.selectedData.pageName,
      elementId: this.state.selectedData.fieldId,
      content: event.target.value,
    };
    await this.props.dispatch(updateStore(payload));
  }

  async handleTextArea(event) {
    console.log(event, "cmsafter1");
    if (event.keyCode === 13) {
      // event.preventDefault();
      let value = await event.target.value;
      let index = await event.target.selectionStart;
      let newValue =
        (await value.substring(0, index)) + "<br />" + value.substring(index);
      await this.setState({ content: newValue });
    } else {
      console.log(event, "cmsafter2");
      await this.setState({ content: event });
    }

    let payload = {
      pageName: this.state.selectedData.pageName,
      elementId: this.state.selectedData.fieldId,
      content: this.state.content,
    };
    await this.props.dispatch(updateStore(payload));
  }

  async updateLoop(cardIndex, itemIndex, content) {
    console.log("update loop");
    let loopData = this.state.loop;
    if (loopData[cardIndex][itemIndex].type === "Image") {
      let file = content.target.files[0];
      let image = URL.createObjectURL(file);
      loopData[cardIndex][itemIndex].fieldValue = image;
      loopData[cardIndex][itemIndex].file = file;
      loopData[cardIndex][itemIndex].newFile = true;
    } else if (loopData[cardIndex][itemIndex].type === "Textfield") {
      loopData[cardIndex][itemIndex].fieldValue = content;
    } else {
      {
        loopData[cardIndex][itemIndex].fieldValue = content.target.value;
      }
    }
    console.log(loopData, "updateCards");
    this.setState({ loop: loopData });
    let updatedData = [];
    for (let i = 0; i < this.state.loop.length; i++) {
      let payload = {};
      for (let j = 0; j < this.state.loop[i].length; j++) {
        payload[`field${j + 1}`] = this.state.loop[i][j].fieldValue;
        payload[`fieldType${j + 1}`] = this.state.loop[i][j].type;

        if (this.state.loop[i][j].link)
          payload[`fieldLink${j + 1}`] = this.state.loop[i][j].link;

        payload[`fieldId${j + 1}`] = !this.state.loop[i][j].id;
      }
      updatedData.push(payload);
    }
    let payload = {
      pageName: this.state.selectedData.pageName,
      elementId: this.state.selectedData.fieldId,
      content: updatedData,
    };
    await this.props.dispatch(updateStore(payload));
    this.setState({ loop: loopData });
  }

  async handleCursorChange(event) {
    console.log(event.target.selectionStart, "cursor");
  }

  async onEdit() {
    console.log("submit");
    if (this.state.selectedData.contentType === "Image") {
      let payload = {
        _id: this.state.selectedData._id,
        path: this.state.selectedData.projectName + "/cms",
        projectName: this.state.selectedData.projectName,
        image: this.state.file,
        contentType: this.state.selectedData.contentType,
      };

      await this.props.dispatch(updateCms(payload));
    } else {
      let payload = {
        _id: this.state.selectedData._id,
        content: this.state.content,
        projectName: this.state.selectedData.projectName,
        contentType: this.state.selectedData.contentType,
        loopData: this.state.loop,
      };
      console.log(this.state, payload, "after");
      await this.props.dispatch(updateCms(payload));
    }

    this.props.closeSidebar(false);
  }

  async imageChange(event) {
    let file = event.target.files[0];
    this.setState({ file: file });
    let image = URL.createObjectURL(file);
    this.setState({ imageUrl: image });
    console.log(
      this.state.imageUrl,
      this.state.selectedData.fieldId,
      this.state.selectedData.pageName,
      "update cms"
    );
    let payload = {
      pageName: this.state.selectedData.pageName,
      elementId: this.state.selectedData.fieldId,
      content: image,
    };
    await this.props.dispatch(updateStore(payload));
  }
  // onSetSidebarClose(close) {
  //   this.setState({ sidebarClose: close });
  // }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  async uploadLoopImage(cardIndex, itemIndex) {
    let payload = {
      image: this.state.loop[cardIndex][itemIndex]["file"],
      path: process.env.REACT_APP_COMMUNITY_NAME + "/cms",
    };
    let data = await this.props.dispatch(uploadImage(payload));
    if (data && data.length) {
      let loopData = this.state.loop;
      loopData[cardIndex][itemIndex].fieldValue = data[0];
      loopData[cardIndex][itemIndex].file = {};
      loopData[cardIndex][itemIndex].newFile = false;
      this.setState({ loop: loopData });
      let updatedData = [];
      for (let i = 0; i < this.state.loop.length; i++) {
        let payload = {};
        for (let j = 0; j < this.state.loop[i].length; j++) {
          payload[`field${j + 1}`] = this.state.loop[i][j].fieldValue;
          payload[`fieldType${j + 1}`] = this.state.loop[i][j].type;

          if (this.state.loop[i][j].link)
            payload[`fieldLink${j + 1}`] = this.state.loop[i][j].link;

          payload[`fieldId${j + 1}`] = !this.state.loop[i][j].id;
        }
        updatedData.push(payload);
      }
      let payload = {
        pageName: this.state.selectedData.pageName,
        elementId: this.state.selectedData.fieldId,
        content: updatedData,
      };
      await this.props.dispatch(updateStore(payload));
      this.setState({ loop: loopData });
    }

    console.log(data, "uploaded image");
  }

  render() {
    const suggestionData = [
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "About Us",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Contact Us",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },

      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
      {
        id: "01",
        pageName: "Home",
        text: " Select a plan based on your business needs Choose a pricing plan & start scoping your business requirements.",
      },
    ];
    //quill
    const modules = {
      toolbar: [
        //[{ 'font': [] }],
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"],
      ],
    };

    const formats = [
      //'font',
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "color",
      "background",
    ];
    const {} = this.props;
    const { content, imageUrl, selectedData, loop } = this.state;
    return (
      <Sidebar
        pullRight
        rootClassName="stop-abcall"
        overlayClassName="stop-abchild"
        sidebarClassName="sidebarclass sidebarfixcls"
        sidebar={
          <div className="sidebar-overlay">
            <div className="flexsetjustify px-20px">
              <h2 className="sideheader">Content Management System</h2>
              <p
                className="cursor-pointer"
                onClick={() => {
                  this.props.closeSidebar(false);
                }}
              >
                <FeatherIcon icon="x" className="text-white" />
              </p>
            </div>
            <div className="py-8 bg-white px-20px pt-20px">
              <div className="relative">
                {selectedData && selectedData.contentType === "Text" ? (
                  <textarea
                    placeholder="Enter..."
                    value={content}
                    onChange={this.handleInput}
                    className="border w-full p-2 text-dark"
                    rows="4"
                    cols="100"
                  ></textarea>
                ) : (
                  ""
                )}
                {selectedData && selectedData.contentType === "Textfield" ? (
                  // <textarea
                  //   placeholder="Enter..."
                  //   value={content}
                  //   onChange={this.handleTextArea}
                  //   className="inputcss"
                  //   rows="4"
                  //   cols="50"
                  // ></textarea>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={this.handleTextArea}
                    modules={modules}
                    formats={formats}
                  />
                ) : (
                  ""
                )}
                {selectedData && selectedData.contentType === "Image" ? (
                  <div className="relative">
                    <img src={imageUrl} className="h-60 w-full" alt="images" />
                    <div className="absolute top-0 w-full">
                      <input
                        type="file"
                        onChange={this.imageChange}
                        className="h-60 w-full border opactid"
                      ></input>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {selectedData && selectedData.contentType === "Button" ? (
                  <div>
                    <input
                      type="text"
                      value={content}
                      onChange={this.handleInput}
                      className="inputcss text-dark"
                    ></input>
                  </div>
                ) : (
                  ""
                )}
                {selectedData && selectedData.contentType === "Cards" ? (
                  <Swiper
                    spaceBetween={20}
                    modules={[Navigation]}
                    // className="mySwiper container relative lg:mx-auto md:mx-5  overflow-x-hidden"
                    autoplay={{
                      delay: "1000",
                    }}
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                    }}
                    className="mySwiper p-5px "
                    breakpoints={{
                      1536: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      1280: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      991: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      640: {
                        slidesPerView: 1.2,
                        spaceBetween: 10,
                      },
                      425: {
                        slidesPerView: 1.2,
                        spaceBetween: 10,
                      },
                      325: {
                        slidesPerView: 1.2,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                      </svg>
                    </div>
                    <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                      </svg>
                    </div>
                    {loop.map((card, cardIndex) => {
                      return (
                        <div>
                          <SwiperSlide className="remflex elshadow p-15px rounded-lg">
                            {card.map((item, itemIndex) => {
                              return (
                                <div className="w-full mb-2">
                                  {item && item.type === "Text" ? (
                                    <input
                                      placeholder="Enter..."
                                      value={item.fieldValue}
                                      onChange={(e) =>
                                        this.updateLoop(cardIndex, itemIndex, e)
                                      }
                                      className="inputcss1"
                                      rows="4"
                                      cols="100"
                                    ></input>
                                  ) : (
                                    ""
                                  )}
                                  {item && item.type === "Textfield" ? (
                                    // <textarea
                                    //   placeholder="Enter..."
                                    //   value={fieldValue}
                                    //   onChange={this.handleTextArea}
                                    //   className="inputcss"
                                    //   rows="4"
                                    //   cols="50"
                                    // ></textarea>
                                    <ReactQuill
                                      theme="snow"
                                      value={item.fieldValue}
                                      onChange={(e) =>
                                        this.updateLoop(cardIndex, itemIndex, e)
                                      }
                                      modules={modules}
                                      formats={formats}
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {item && item.type === "link" ? (
                                    // <textarea
                                    //   placeholder="Enter..."
                                    //   value={fieldValue}
                                    //   onChange={this.handleTextArea}
                                    //   className="inputcss"
                                    //   rows="4"
                                    //   cols="50"
                                    // ></textarea>
                                    <input
                                      placeholder="Enter..."
                                      value={item.fieldValue}
                                      onChange={(e) =>
                                        this.updateLoop(cardIndex, itemIndex, e)
                                      }
                                      className="inputcss1"
                                      rows="4"
                                      cols="100"
                                    ></input>
                                  ) : (
                                    ""
                                  )}

                                  {item && item.type === "Image" ? (
                                    <div className="relative">
                                      <img
                                        src={
                                          item.fieldValue.includes("blob")
                                            ? item.fieldValue
                                            : process.env
                                                .REACT_APP_STORAGE_URL_2 +
                                              item.fieldValue
                                        }
                                        className="h-60 w-full"
                                        alt="images"
                                      />

                                      {item.newFile ? (
                                        <div className="text-center mt-4">
                                          <button
                                            className="updatebutton fsize17 sm-fsize12 cursor-pointer"
                                            onClick={() =>
                                              this.uploadLoopImage(
                                                cardIndex,
                                                itemIndex
                                              )
                                            }
                                          >
                                            Upload
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="absolute top-0 w-full">
                                        <input
                                          type="file"
                                          onChange={(e) =>
                                            this.updateLoop(
                                              cardIndex,
                                              itemIndex,
                                              e
                                            )
                                          }
                                          className="h-60 w-full border opactid"
                                        ></input>
                                        {/* {true ? (
                                          <button
                                            onClick={() =>
                                              this.uploadLoopImage(
                                                cardIndex,
                                                itemIndex
                                              )
                                            }
                                          >
                                            upload
                                          </button>
                                        ) : (
                                          ""
                                        )} */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {item && item.type === "Button" ? (
                                    <div>
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          this.updateLoop(
                                            cardIndex,
                                            itemIndex,
                                            e
                                          )
                                        }
                                        className="h-60 w-full border opactid"
                                      ></input>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {item && item.type === "Button" ? (
                                    <div>
                                      <input
                                        type="text"
                                        value={item.fieldValue}
                                        onChange={(e) =>
                                          this.updateLoop(
                                            cardIndex,
                                            itemIndex,
                                            e
                                          )
                                        }
                                        className="inputcss"
                                      ></input>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })}
                          </SwiperSlide>
                        </div>
                      );
                    })}
                  </Swiper>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-6 flex justify-center">
                <button
                  className="submitbutton fsize17 sm-fsize12 cursor-pointer"
                  onClick={() => {
                    console.log("clicked");
                    this.onEdit();
                  }}
                >
                  {" "}
                  Update
                </button>
              </div>

              {/* suggestion content */}

              {/* <div className="mt-4 ">
                <div className="mb-2  px-2">
                  {" "}
                  <input
                    type="text"
                    placeholder="Search..."
                    className="cms-searchinput themefamily w-full "
                  />
                </div>

                <div className="cms-scrollbar p-2">
                  {suggestionData.map((e) => {
                    return (
                      <div className=" mb-3 elshadow-md rounded-md p-3 ">
                        <h6 className="textprimary mb-1 fsize14  font-semibold">
                          {e.pageName}
                        </h6>
                        <div className=" flex items-center">
                          <p className="themefamily text-gray fsize12  w-11/12">
                            {e.text}
                          </p>
                          <div className="w-1/12 flex justify-end">
                            <FeatherIcon
                              icon="plus"
                              size={30}
                              className="text-white bgsecondary cursor-pointer rounded-full p5px"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            {/* <div className="p-3">
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={this.state.quillContent}
                onChange={this.handleEditorChange}
              />
            </div> */}
            {/* <div className="p-3 ">
              <div className="flex overflow-x-auto gap-4 w-full">
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
                <div className="wid-upload">
                  {" "}
                  <Upload
                    className="upload-demo  wid-upload"
                    drag
                    action="//jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i className="el-icon-upload"></i>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="p-3  border">
              <div className="flex overflow-x-auto gap-4 w-full">
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
                <div className="wid-upload2 border p-2">
                  <div className="wid-upload">
                    {" "}
                    <Upload
                      className="upload-demo  wid-upload"
                      drag
                      action="//jsonplaceholder.typicode.com/posts/"
                      multiple
                    >
                      <i className="el-icon-upload"></i>
                    </Upload>
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                  <div className="py-1">
                    <Input placeholder="Please input" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        }
        open={this.props.isShow}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white" } }}
      ></Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state, "mapstate");
  if (!!state.cms.selectedData) {
    return {
      selectedData: state.cms.selectedData,
    };
  } else {
    return {
      selectedData: {},
    };
  }
};

export default connect(mapStateToProps)(ContentUpdate);
