import React, { useEffect } from "react";
import theme from "theme";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Hr, Image, Icon, Section } from "@quarkly/widgets";
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowRoundForward } from "react-icons/io";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import { getAllMenu } from "redux/Menu/menu-actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import Fade from "react-reveal/Fade";

const defaultProps = {
  position: "static",
  "sm-align-items": "center",
  "sm-flex-direction": "column",
  "sm-justify-content": "center",
  "md-height": "max-content",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "100%",
      margin: "0px 0px 0px 0px",
      padding: "0px 40PX 0px 0px",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "flex-start",
      "text-align": "center",
      "sm-width": "100%",
      "sm-padding": "0px 0 0px 0px",
      "md-margin": "0px 8px 0px 0px",
      "md-padding": "0px 32px 0px 0px",
      "md-justify-content": "flex-start",
      "md-align-items": "flex-start",
      "sm-margin": "0px 0px 24px 0",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--subheadlined3",
      color: "--primary",
      "text-align": "center",
      padding: "0px 0px 0 0px",
      "sm-margin": "0px 0px 4px 0px",
      "sm-letter-spacing": "1px",
      "lg-letter-spacing": "4px",
      "md-font": "--subheadlined4",
      "md-margin": "0px 0px 8px 0px",
      "lg-font": "--subheadlined4",
      children: "Testimonials",
      "sm-font": "--subheadlined5",
      "letter-spacing": "4px",
      width: "100%",
      className: "hoverText",
      id: "5117533237",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--subheadlined1",
      "sm-width": "100%",
      "text-align": "center",
      width: "100%",
      "sm-font": "--subheadlined3",
      "sm-margin": "0px 0px 8px 0px",
      "lg-font": "--subheadlined2",
      "md-font": "--subheadlined3",
      "md-margin": "0px 0px 16px 0px",
      children: "Check what our satisfied clients said",
      className: "hoverText",
      id: "1170245677",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--p2",
      "sm-margin": "0px 0px 0px 0px",
      "lg-font": "--p4",
      "md-font": "--p4",
      children:
        "Why I say old chap that is, spiffing off his nut color blimey and guvnords geeza bloke knees up bobby sloshed arse",
      "sm-color": "--greyD1",
      "sm-font": "--p4",
      className: "hoverText",
      id: "1385195005",
    },
  },
  hr: {
    kind: "Hr",
    props: {
      "min-height": "10px",
      "min-width": "3px",
      margin: "0px 0px 0px 0px",
      "sm-height": "2px",
      "sm-min-height": 0,
      "sm-min-width": 0,
      "sm-margin": "0px 0px 16px 0px",
      "sm-background": "--color-lightD2",
      "sm-width": "80%",
      "sm-display": "none",
      "md-width": "2px",
      "md-background": "--color-lightD1",
      background: "--color-lightD2",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      width: "100%",
      "align-items": "center",
      display: "flex",
      "justify-content": "center",
      "sm-width": "100%",
      "sm-margin": "0px 0px 16px 0px",
      "md-margin": "0px 0px 0px 8px",
      "lg-margin": "0px 0px 0px 8px",
      "sm-border-radius": "8px",
    },
  },
  box3: {
    kind: "Box",
    props: {
      padding: "24px 24px 24px 24px",
      background: "--color-darkL1",
      "box-shadow": "--l",

      "sm-box-shadow": "0 2px 15px -3px rgba(0, 0, 0, 0.32)",
      "md-box-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.17)",

      "sm-border-radius": "8px",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "10px 0px 24px 0px",
      font: "--p2",
      "lg-font": "--p4",
      "md-font": "--p4",
      "lg-color": "--darkL2",
      children:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus consectetur euismod aenean. Urna nibh risus nisl consequat consectetur. Ornare et, lorem mauris ligula. Et a tortor, velit vel.",
      "sm-color": "--greyD1",
      "sm-font": "--p4",
      className: "hoverText",
      id: "8564704404",
    },
  },
  box4: {
    kind: "Box",
    props: {
      display: "block",
      "sm-align-items": "center",
      "align-items": "center",
      "justify-content": "space-around",
      "md-align-items": "flex-end",
      "lg-justify-content": "space-between",
      "lg-align-items": "flex-end",
      "sm-justify-content": "flex-start",
    },
  },
  box5: {
    kind: "Box",
    props: {
      "lg-width": "60px",
      "lg-height": "60px",
      "lg-min-width": "none",
      "lg-min-height": "none",
      width: "100%",
      height: "80px",
      display: "flex",
      "align-items": "center",
      "justify-content": "flex-start",
      "sm-justify-content": "flex-start",
      margin: "0px 24px 0px 0px",
      "md-width": "100px",
      "md-height": "60px",
      "md-margin": "0px 16px 0px 0px",
      "lg-margin": "0px 8px 0px 0px",
      "sm-width": "80px",
      "sm-margin": "0px 8px 0px 0px",
    },
  },
  image: {
    kind: "Image",
    props: {
      display: "block",
      height: "70px",
      width: "70px",
      "object-fit": "cover",
      "border-radius": "50%",
      margin: "0px 0px 0px 0px",
      "lg-margin": "0px 0px 0px 0px",

      "md-margin": "0px 0 0px 0px",
      "md-width": "70%",
      "sm-width": "50px",
      "sm-height": "50px",
      className: "hoverText",
      id: "8395731289",
    },
  },
  box6: {
    kind: "Box",
    props: {
      height: "max-content",
      width: "100%",
      "lg-width": "60%",
    },
  },
  text4: {
    kind: "Text",
    props: {
      margin: "0px 0px 8px 0px",
      font: "--subheadlined3",
      color: "--dark",
      "lg-font": "--subheadlined4",
      "md-font": "--subheadlined4",
      children: "Vijay D. Chauhan",
      "sm-margin": "0px 0px 4px 0px",
      "sm-font": "--subheadlined5",
      className: "hoverText",
      id: "9796888065",
    },
  },
  text5: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--p4",
      "text-align": "left",
      "lg-font": "--p3",
      "md-font": "--p4",
      children: "CEO & Co Founder",
      "sm-font": "--p5",
      className: "hoverText",
      id: "9261270788",
    },
  },
  box7: {
    kind: "Box",
    props: {
      display: "flex",
      "align-items": "flex-end",
      "sm-display": "none",
      "md-width": "60px",
      "lg-width": "20%",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      category: "bs",
      icon: BsArrowLeft,
      size: "32px",
      width: "50px",
      height: "50px",
      color: "--grey",
      className: "",
      id: "3579432792",
    },
  },
  icon1: {
    kind: "Icon",
    props: {
      category: "io",
      icon: IoIosArrowRoundForward,
      size: "32px",
      width: "50px",
      height: "50px",
      className: "",
      id: "3579432792",
    },
  },
  box8: {
    kind: "Box",
    props: {
      display: "none",
      "align-items": "flex-end",
      "sm-display": "flex",
      "sm-align-items": "center",
      "sm-justify-content": "center",
    },
  },
  icon2: {
    kind: "Icon",
    props: {
      category: "bs",
      icon: BsArrowLeft,
      size: "64px",
      width: "50px",
      height: "50px",
      color: "--grey",
      "sm-height": "40px",
      "sm-width": "40px",
    },
  },
  icon3: {
    kind: "Icon",
    props: {
      category: "io",
      icon: IoIosArrowRoundForward,
      width: "50px",
      height: "50px",
      "sm-height": "40px",
      size: "40px",
    },
  },
  SectionContent: {
    props: {
      "sm-margin": "8px 18.75px 8px 18.75px",
    },
  },
};

const Testimonial = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        margin="80px auto 80px auto"
        sm-margin="16px auto 16px auto"
        md-margin="8px auto 8px auto"
      />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Text
            {...override("text")}
            className="text-center"
            onClick={() => isSideBarOpen("5117533237")}
          >
            {" "}
            <Reveal> {data ? data["5117533237"] : "no data"} </Reveal>
          </Text>
          <Text
            className="text-center"
            {...override("text1")}
            onClick={() => isSideBarOpen("1170245677")}
          >
            {" "}
            <Reveal> {data ? data["1170245677"] : "no data"} </Reveal>
          </Text>
          <Text
            {...override("text2")}
            onClick={() => isSideBarOpen("1385195005")}
          >
            {" "}
            <Reveal> {data ? data["1385195005"] : "no data"} </Reveal>
          </Text>
        </Box>

        <Box {...override("box2")}>
          {/* fda */}
          <div className="grid lg:grid-cols-2 mt-5 w-full lg:gap-4 md:gap-4 gap-3">
            <Fade bottom>
              <Box {...override("box3")} className="border rounde-10">
                <Box {...override("box4")}>
                  <Box {...override("box5")}>
                    {/* <Box
                  className="hoverText"
                  id="8395731289"
                  onClick={() => isSideBarOpen("8395731289")}
                >
                  <img
                    className="w-24 h-20 rounded-full hoverText"
                    src={
                      data
                        ? data["8395731289"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                  />
                </Box> */}
                    <div
                      onClick={() => isSideBarOpen("1819022388")}
                      className="hoverText"
                      id="1819022388"
                    >
                      <Image
                        {...override("image")}
                        src={
                          data
                            ? data["1819022388"]
                            : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                        }
                      />
                    </div>
                  </Box>
                  <Box {...override("box6")}>
                    <Text
                      {...override("text4")}
                      onClick={() => isSideBarOpen("9796888065")}
                    >
                      {" "}
                      {data ? data["9796888065"] : "no data"}
                    </Text>
                    <Text
                      {...override("text5")}
                      onClick={() => isSideBarOpen("9261270788")}
                    >
                      {" "}
                      {data ? data["9261270788"] : "no data"}
                    </Text>
                  </Box>
                </Box>
                <Text
                  {...override("text3")}
                  onClick={() => isSideBarOpen("8564704404")}
                >
                  {" "}
                  {data ? data["8564704404"] : "no data"}
                </Text>
              </Box>
            </Fade>
            <Fade bottom>
              <Box {...override("box3")} className="border rounde-10">
                <Box {...override("box4")}>
                  <Box {...override("box5")}>
                    {/* <Box
                  className="hoverText"
                  id="8395731289"
                  onClick={() => isSideBarOpen("8395731289")}
                >
                  <img
                    className="w-24 h-20 rounded-full hoverText"
                    src={
                      data
                        ? data["8395731289"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                  />
                </Box> */}
                    <div
                      onClick={() => isSideBarOpen("1819022388")}
                      className="hoverText"
                      id="1819022388"
                    >
                      <Image
                        {...override("image")}
                        src={
                          data
                            ? data["1819022388"]
                            : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                        }
                      />
                    </div>
                  </Box>
                  <Box {...override("box6")}>
                    <Text
                      {...override("text4")}
                      onClick={() => isSideBarOpen("9796888065")}
                    >
                      {" "}
                      {data ? data["9796888065"] : "no data"}
                    </Text>
                    <Text
                      {...override("text5")}
                      onClick={() => isSideBarOpen("9261270788")}
                    >
                      {" "}
                      {data ? data["9261270788"] : "no data"}
                    </Text>
                  </Box>
                </Box>
                <Text
                  {...override("text3")}
                  onClick={() => isSideBarOpen("8564704404")}
                >
                  {" "}
                  {data ? data["8564704404"] : "no data"}
                </Text>
              </Box>
            </Fade>
            <Fade bottom>
              <Box {...override("box3")} className="border rounde-10">
                <Box {...override("box4")}>
                  <Box {...override("box5")}>
                    {/* <Box
                  className="hoverText"
                  id="8395731289"
                  onClick={() => isSideBarOpen("8395731289")}
                >
                  <img
                    className="w-24 h-20 rounded-full hoverText"
                    src={
                      data
                        ? data["8395731289"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                  />
                </Box> */}
                    <div
                      onClick={() => isSideBarOpen("1819022388")}
                      className="hoverText"
                      id="1819022388"
                    >
                      <Image
                        {...override("image")}
                        src={
                          data
                            ? data["1819022388"]
                            : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                        }
                      />
                    </div>
                  </Box>
                  <Box {...override("box6")}>
                    <Text
                      {...override("text4")}
                      onClick={() => isSideBarOpen("9796888065")}
                    >
                      {" "}
                      {data ? data["9796888065"] : "no data"}
                    </Text>
                    <Text
                      {...override("text5")}
                      onClick={() => isSideBarOpen("9261270788")}
                    >
                      {" "}
                      {data ? data["9261270788"] : "no data"}
                    </Text>
                  </Box>
                </Box>
                <Text
                  {...override("text3")}
                  onClick={() => isSideBarOpen("8564704404")}
                >
                  {" "}
                  {data ? data["8564704404"] : "no data"}
                </Text>
              </Box>
            </Fade>
            <Fade bottom>
              <Box {...override("box3")} className="border rounde-10">
                <Box {...override("box4")}>
                  <Box {...override("box5")}>
                    {/* <Box
                  className="hoverText"
                  id="8395731289"
                  onClick={() => isSideBarOpen("8395731289")}
                >
                  <img
                    className="w-24 h-20 rounded-full hoverText"
                    src={
                      data
                        ? data["8395731289"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                  />
                </Box> */}
                    <div
                      onClick={() => isSideBarOpen("1819022388")}
                      className="hoverText"
                      id="1819022388"
                    >
                      <Image
                        {...override("image")}
                        src={
                          data
                            ? data["1819022388"]
                            : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                        }
                      />
                    </div>
                  </Box>
                  <Box {...override("box6")}>
                    <Text
                      {...override("text4")}
                      onClick={() => isSideBarOpen("9796888065")}
                    >
                      {" "}
                      {data ? data["9796888065"] : "no data"}
                    </Text>
                    <Text
                      {...override("text5")}
                      onClick={() => isSideBarOpen("9261270788")}
                    >
                      {" "}
                      {data ? data["9261270788"] : "no data"}
                    </Text>
                  </Box>
                </Box>
                <Text
                  {...override("text3")}
                  onClick={() => isSideBarOpen("8564704404")}
                >
                  {" "}
                  {data ? data["8564704404"] : "no data"}
                </Text>
              </Box>
            </Fade>
          </div>

          {/* vdfsdz */}
        </Box>
      </Box>

      {children}
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

Object.assign(Testimonial, { ...Section, defaultProps, overrides });
export default Testimonial;
