import React from "react";

const Subscribe = () => {
  return (
    <div className="bgprimary py-10">
      <div className="container mx-auto lg:px-24">
        <div className="p-5 flex justify-center w-full bg-white rounde-10">
          <div>
            <h4 className="fsize37 sm-fsize20 text-center font-semibold">
              Get Strat with What<br></br>grow morre faster
            </h4>
            <div className="flex  lg:w-full  mt-3 items-center">
              <input className="sub-input border" />
              <button className="sub-btn bg-dark text-white">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
