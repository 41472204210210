const initialState = {
  pages: {},
  cmsActive: false,
};
const Cms_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "getcms":
      return { ...state, pages: action.payload };
    case "setSelectedData":
      return { ...state, selectedData: action.payload };
    case "setFooterCms":
      return { ...state, footerCms: action.payload };
    case "updateStore":
      console.log(state.pages);
      const updatepages = {
        ...state.pages,

        [action.payload.pageName]: {
          ...state.pages[action.payload.pageName],
          [action.payload.elementId]: action.payload.content,
        },
      };

      console.log(updatepages);

      return {
        ...state,

        pages: updatepages,
      };
    case "setUserData":
      return { ...state, userData: action.payload };
    case "setCmsActive":
      return { ...state, cmsActive: action.payload };
    default:
      return state;
  }
};

export default Cms_reducer;
