import React, { useEffect } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import theme from "theme/index3";
import {
  Theme,
  Image,
  LinkBox,
  Link,
  Box,
  Button,
  Icon,
  Section,
  Text,
  Span,
  Hr,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import Brands from "./components/Brands";
import Testimonial3 from "components/testimonial/Testimonial3";
import Team3 from "../../components/team/Team3";
import ContactUs4 from "components/contact/Contact4";
import { getAllMenu } from "redux/Menu/menu-actions";
import Fade from "react-reveal/Fade";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  const handleClick = () => {
    const element = document.getElementById("contactpage");
    element.scrollIntoView();
  };

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home-3</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        position="static"
        padding="210px 60px 150px 60px"
        lg-padding="140px 20px 44px 20px"
        md-padding="140px 40px 44px 40px"
        sm-padding="80px 20px 22px 20px"
        background="--color-primary"
        z-index="-1"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 24px auto"
          justify-content="center"
          width="100%"
          position="static"
          flex-direction="row"
          md-flex-direction="column"
          sm-align-items="flex-start"
          sm-margin="0px auto 0px auto"
        />
        <Box
          display="flex"
          justify-content="space-between"
          align-items="center"
          flex-direction="column"
          md-width="100%"
          sm-margin="20PX 0px 24px 0px"
          width="70%"
          lg-width="60%"
          md-margin="40px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 18px 0px"
            font="--poppin72B"
            text-align="center"
            display="flex"
            flex-direction="column"
            color="white"
            sm-width="90%"
            sm-font="--subheadlined2"
            md-font="--headline4"
            className="hoverText"
            align-items="flex-start"
            width="100%"
            align-content="flex-end"
            sm-margin="0px 0px 8px 0px"
            justify-content="center"
            id="8922600886"
            onClick={() => isSideBarOpen("8922600886")}
          >
            <Reveal> {data ? data["8922600886"] : "no data"}</Reveal>
          </Text>

          <Text
            margin="0px 0px 0px 0px"
            text-align="center"
            justify-content="center"
            display="flex"
            font="--p1"
            color="white"
            sm-font="--p5"
            lg-font="--p2"
            md-font="--p3"
            className="hoverText sm-textwidth"
            align-items="flex-start"
            sm-width="100%"
            width="100%"
            line-height="28px"
            id="8978450592"
            dangerouslySetInnerHTML={{
              __html: data ? data["8978450592"] : "no data",
            }}
            onClick={() => isSideBarOpen("8978450592")}
          ></Text>
        </Box>
      </Section>
      <Brands />
      <Section
        sm-align-items="center"
        sm-justify-content="center"
        sm-padding="22px 0 8px 0"
        align-items="center"
        justify-content="center"
        height="auto"
      >
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          lg-margin="40px auto 40px auto"
          sm-margin="20px auto 26px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          margin="0px 0px 8px 0px"
          display="flex"
          flex-direction="column"
          align-items="center"
        >
          <Text
            margin="0px 0px 2px 0px"
            font="--subheadlined4"
            letter-spacing="4PX"
            color="--primary"
            sm-font="--subheadlined5"
            lg-margin="0px 0px 8px 0px"
            md-font="--subheadlined4"
            lg-font="--subheadlined4"
            className="hoverText"
            sm-letter-spacing="1px"
            sm-text-align="center"
            sm-margin="0px 0px 2px 0px"
            text-align="center"
            id="1632289585"
            onClick={() => isSideBarOpen("1632289585")}
          >
            <Reveal> {data ? data["1632289585"] : "no data"}</Reveal>
          </Text>
          <Text
            margin="10px 0px 15px 0px"
            font="--subheadlined1"
            sm-font="--subheadlined2"
            sm-margin="0px 0px 22px 0px"
            lg-margin="0px 0px 16px 0px"
            md-font="--subheadlined2"
            md-margin="0px 0px 24px 0px"
            lg-font="--subheadlined2"
            className="hoverText sm-fsize24"
            sm-width="100%"
            sm-text-align="center"
            text-align="center"
            color="--darkL2"
            id="7689255120"
            onClick={() => isSideBarOpen("7689255120")}
          >
            <Reveal> {data ? data["7689255120"] : "no data"}</Reveal>
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            font="--p2"
            sm-font="--p4"
            lg-font="--p4"
            md-margin="0px 0px 24px 0px"
            className="hoverText"
            id="3313680024"
            sm-width="100%"
            sm-text-align="center"
            width="60%"
            text-align="center"
            onClick={() => isSideBarOpen("3313680024")}
            dangerouslySetInnerHTML={{
              __html: data ? data["3313680024"] : "no-data",
            }}
          ></Text>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
          lg-align-items="center"
          lg-justify-content="center"
          align-items="center"
          lg-height="380px"
          sm-height="528px"
          flex-direction="column"
          margin="18px 0px 0px 0px"
          className="cust-w100"
        >
          <Reveal>
            <Box
              min-width="100px"
              min-height="100px"
              width="80%"
              height="400px"
              sm-width="100%"
              // lg-border-width="8px 0 0  0"
              lg-height="100%"
              sm-border-radius="20px"
              className="hoverText w-full"
              onClick={() => isSideBarOpen("4186580645")}
            >
              <Image
                src={
                  data
                    ? data["4186580645"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                id="4186580645"
                display="block"
                width="100%"
                object-fit="cover"
                border-radius="28px"
                height="100%"
                sm-border-radius="20px"
              />
            </Box>
          </Reveal>
          <Box
            display="flex"
            flex-direction="column"
            justify-content="center"
            align-items="center"
            sm-width="100%"
            sm-padding="0px 0 0px 0px"
            sm-margin="10px 0px 26px 0px"
            lg-padding="0px 0px 0px 0px"
            lg-height="max-content"
            md-justify-content="space-between"
            md-margin="0px 20px 0px 0px"
            sm-align-items="center"
            margin="40px 0px 0px 0px"
            height="100%"
          >
            <Reveal>
              <div
                id="0412288477"
                className="hoverText"
                onClick={() => isSideBarOpen("0412288477")}
              >
                <Button
                  background="--color-primary"
                  color="--light"
                  font="--subheadlined5"
                  padding="15px 26px 15px 26px"
                  sm-font="--bt2"
                  sm-display="flex"
                  sm-flex-direction="row"
                  sm-margin="0px 8px 0px 0px"
                  md-display="block"
                  className="hoverText brad-4   skinButton"
                  sm-padding="16px 26px 16px 26px"
                  sm-border-radius="6px"
                  border-radius="8px"
                >
                  {data ? data["0412288477"] : "Enquire Now"}
                </Button>
              </div>
            </Reveal>
          </Box>
        </Box>
      </Section>
      <Section
        position="static"
        sm-align-items="flex-end"
        align-items="center"
        justify-content="center"
        padding="60px 0 60px 0"
        sm-padding="28px 0 28px 0"
      >
        <Override slot="SectionContent" margin="0px auto 0px auto" />
        <Box
          width="100%"
          sm-margin="0px auto 0px auto"
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 0px 40px 0px"
            md-margin="0px 0px 40px 0px"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--subheadlined3"
              letter-spacing="4PX"
              color="--primary"
              sm-font="--subheadlined5"
              sm-letter-spacing="1px"
              md-font="--subheadlined4"
              md-margin="0px 0px 8px 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="0633521212"
              onClick={() => isSideBarOpen("0633521212")}
            >
              <Reveal> {data ? data["0633521212"] : "no data"} </Reveal>
              {/* Our Key Services */}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--subheadlined1"
              sm-font="--subheadlined3"
              sm-text-align="center"
              md-margin="0px 0px 24px 0px"
              md-font="--subheadlined2"
              sm-margin="0px 0px 12px 0px"
              className="hoverText"
              id="3734049364"
              onClick={() => isSideBarOpen("3734049364")}
            >
              <Reveal> {data ? data["3734049364"] : "no data"} </Reveal>
              {/* Industries What We Serve */}
            </Text>
            <Box
              display="flex"
              width="60%"
              sm-width="100%"
              md-width="80%"
              lg-width="80%"
              id="0629982000"
              className="hoverText"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                lg-font="--p3"
                lg-min-width="none"
                lg-min-height="none"
                className="hoverText"
                id="0629982000"
                onClick={() => isSideBarOpen("0629982000")}
                dangerouslySetInnerHTML={{
                  __html: data ? data["0629982000"] : "no data",
                }}
              >
                {/* <Reveal> {data ? data["0629982000"] : "no data"}</Reveal> */}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
          </Box>
          <Box
            display="grid"
            grid-gap="30px"
            sm-grid-template-columns="repeat(1, 1fr)"
            sm-min-height={0}
            sm-min-width={0}
            lg-grid-template-columns="repeat(6, 1fr)"
            md-margin="0px 0px 0px 0px"
            width="100%"
            padding="0px 0px 20px 0px"
            align-items="center"
            justify-items="center"
            grid-template-columns="repeat(3, 1fr)"
            className="card100-reveal bdr-4"
          >
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                hover-box-shadow="--l"
                href="/index11"
                width="380px"
                height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
                md-height="330px"
              >
                <div
                  onClick={() => isSideBarOpen("8380951052")}
                  className="hoverText"
                  id="8380951052"
                >
                  <Image
                    src={
                      data
                        ? data["8380951052"]
                        : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    text-align="center"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("4459966185")}
                    id="4459966185"
                  >
                    {data ? data["4459966185"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="2184136934"
                  onClick={() => isSideBarOpen("2184136934")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["2184136934"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                hover-box-shadow="--l"
                href="/index11"
                width="380px"
                height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
                md-height="330px"
              >
                <div
                  onClick={() => isSideBarOpen("9338462607")}
                  className="hoverText"
                  id="9338462607"
                >
                  <Image
                    src={
                      data
                        ? data["9338462607"]
                        : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20485.png?v=2023-06-08T07:03:21.205Z"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("7573397008")}
                    id="7573397008"
                  >
                    {data ? data["7573397008"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="7999605823"
                  onClick={() => isSideBarOpen("7999605823")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["7999605823"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                hover-box-shadow="--l"
                href="/index11"
                width="380px"
                height="330px"
                md-height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
              >
                <div
                  onClick={() => isSideBarOpen("5127224140")}
                  className="hoverText"
                  id="5127224140"
                >
                  <Image
                    src={
                      data
                        ? data["5127224140"]
                        : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20486.png?v=2023-06-08T07:03:21.208Z"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    text-align="center"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("5994371340")}
                    id="5994371340"
                  >
                    {data ? data["5994371340"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="8506712069"
                  onClick={() => isSideBarOpen("8506712069")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["8506712069"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                hover-box-shadow="--l"
                href="/index11"
                width="380px"
                height="330px"
                md-height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
              >
                <div
                  onClick={() => isSideBarOpen("5378513421")}
                  className="hoverText"
                  id="5378513421"
                >
                  <Image
                    src={
                      data
                        ? data["5378513421"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("2932463085")}
                    id="2932463085"
                  >
                    {data ? data["2932463085"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="6460377051"
                  onClick={() => isSideBarOpen("6460377051")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["6460377051"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                hover-box-shadow="--l"
                href="/index11"
                width="380px"
                height="330px"
                md-height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
              >
                <div
                  onClick={() => isSideBarOpen("1540216442")}
                  className="hoverText"
                  id="1540216442"
                >
                  <Image
                    src={
                      data
                        ? data["1540216442"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("4178111992")}
                    id="4178111992"
                  >
                    {data ? data["4178111992"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="8216480916"
                  onClick={() => isSideBarOpen("8216480916")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["8216480916"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
            <Reveal>
              <Box
                display="flex"
                flex-direction="column"
                align-items="center"
                background="--color-darkL1"
                border-width="2px"
                hover-border-color="--color-primary"
                href="/index11"
                width="380px"
                height="330px"
                md-height="330px"
                justify-content="center"
                padding="20px 28px 20px 28px"
                border-radius="12px"
                sm-height="280px"
                sm-padding="12px 12px 12px 12px"
                sm-justify-content="center"
                sm-align-items="center"
                sm-width="100%"
                hover-box-shadow="--l"
              >
                <div
                  onClick={() => isSideBarOpen("9041677121")}
                  className="hoverText"
                  id="9041677121"
                >
                  <Image
                    src={
                      data
                        ? data["9041677121"]
                        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    }
                    display="block"
                    margin="0px 0px 24px 0px"
                    width="60px"
                    height="60px"
                  />
                </div>
                <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                  <Text
                    margin="0px 0px 0px 0px"
                    font="--subheadlined3"
                    color="--dark"
                    text-align="center"
                    sm-font="--subheadlined4"
                    md-font="--subheadlined4"
                    className="hoverText"
                    onClick={() => isSideBarOpen("1457927004")}
                    id="1457927004"
                  >
                    {data ? data["1457927004"] : "no data"}
                  </Text>
                </Box>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  md-font="--p3"
                  lg-font="--p4"
                  className="fsize15 hoverText line-clamp-6"
                  id="4126878104"
                  onClick={() => isSideBarOpen("4126878104")}
                  dangerouslySetInnerHTML={{
                    __html: data ? data["4126878104"] : "no-data",
                  }}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Reveal>
          </Box>
        </Box>
      </Section>

      <Section
        sm-align-items="center"
        sm-justify-content="center"
        align-items="center"
        justify-content="center"
        padding="60px 0 60px 0"
        sm-padding="60px 0 4px 0"
        background="--color-primary"
      >
        <Override slot="SectionContent" margin="0px 160px 40px 160px" />
        <Box
          min-width="100px"
          min-height="100px"
          sm-display="flex"
          sm-align-items="center"
          sm-flex-direction="column"
          sm-justify-content="center"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 0px 48px 0px"
            height="fit-content"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined4"
              letter-spacing="4PX"
              color="--primary"
              sm-letter-spacing="1px"
              md-font="--subheadlined4"
              lg-font="--subheadlined4"
              lg-margin="0px 0px 8px 0px"
              sm-font="--subheadlined5"
              className="hoverText"
              id="8199816201"
              onClick={() => isSideBarOpen("8199816201")}
            >
              <Reveal> {data ? data["8199816201"] : "no data"} </Reveal>
            </Text>
            <Text
              margin="0px 0px 14px 0px"
              font="--subheadlined1"
              sm-text-align="center"
              lg-margin="0px 0px 16px 0px"
              md-font="--subheadlined2"
              sm-font="--subheadlined3"
              className="hoverText"
              color="--darkL1"
              id="0975635559"
              onClick={() => isSideBarOpen("0975635559")}
              dangerouslySetInnerHTML={{
                __html: data ? data["0975635559"] : "no-data",
              }}
            >
              {/* <Reveal> {data ? data["0975635559"] : "no data"}</Reveal> */}
            </Text>
            <Box
              display="flex"
              width="60%"
              sm-width="100%"
              md-width="80%"
              lg-width="80%"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                lg-font="--p3"
                md-font="--p3"
                sm-font="--p4"
                className="hoverText"
                color="--darkL1"
                id="3498353673"
                onClick={() => isSideBarOpen("3498353673")}
                dangerouslySetInnerHTML={{
                  __html: data ? data["3498353673"] : "no-data",
                }}
              >
                {/* <Reveal> {data ? data["3498353673"] : "no data"}</Reveal> */}
              </Text>
            </Box>
          </Box>
          <Box
            width="100%"
            className="lg:flex md:flex"
            grid-gap="28px"
            sm-margin="0px 0px 30px 0px"
          >
            <Box
              onClick={() => isSideBarOpen("0048959205")}
              className="hoverText lg:w-1/2 md:w-1/2 "
            >
              <Fade bottom>
                <Image
                  src={
                    data
                      ? data["0048959205"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  display="block"
                  width="100%"
                  height="280px"
                  object-fit="cover"
                  border-radius="16px"
                  border-width="0 0 10px 0"
                  border-color="--color-primary"
                  hover-box-shadow="--xl"
                  hover-border-style="solid"
                  box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px --color-primary"
                  id="0048959205"
                  className=""
                />
              </Fade>
            </Box>

            <Box
              className="hoverText lg:w-1/2 md:w-1/2"
              onClick={() => isSideBarOpen("8922235061")}
            >
              <Fade bottom>
                <Image
                  src={
                    data
                      ? data["8922235061"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  display="block"
                  width="100%"
                  height="280px"
                  object-fit="cover"
                  border-radius="16px"
                  border-width="0 0 10px 0"
                  border-color="--color-primary"
                  hover-box-shadow="--xl"
                  hover-border-style="solid"
                  box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px --color-primary"
                  sm-display="none"
                  id="8922235061"
                  className=""
                />{" "}
              </Fade>
            </Box>
          </Box>
          <Reveal>
            <Box
              sm-margin="0px 0px 40px 0px"
              width="100%"
              onClick={() => isSideBarOpen("7593644922")}
              className="hoverText lg:mt-8 md:mt-8"
            >
              <Image
                src={
                  data
                    ? data["7593644922"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="280px"
                object-fit="cover"
                border-radius="16px"
                border-width="0 0 10px 0"
                border-color="--color-primary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px --color-primary"
                sm-display="none"
                id="7593644922"
                className=""
                grid-column="1 / span 2"
              />
            </Box>
          </Reveal>
          <Box
            width="8%"
            display="none"
            justify-content="center"
            align-items="center"
            sm-width="20%"
          >
            <Box
              background="--color-greyD2"
              width="12px"
              height="12px"
              border-radius="10px"
              margin="0px 16px 0px 0px"
              sm-width="10px"
              sm-height="10px"
              sm-margin="0px 8px 0px 0px"
            />
            <Box
              background="--color-lightD2"
              width="12px"
              height="12px"
              border-radius="10px"
              sm-width="10px"
              sm-height="10px"
              sm-margin="0px 8px 0px 0px"
            />
            <Box
              background="--color-lightD2"
              width="12px"
              height="12px"
              border-radius="10px"
              sm-width="10px"
              sm-height="10px"
              sm-margin="0px 8px 0px 0px"
            />
            <Box
              background="--color-lightD2"
              width="12px"
              height="12px"
              border-radius="10px"
              sm-width="10px"
              sm-height="10px"
              sm-margin="0px 8px 0px 0px"
            />
          </Box>
        </Box>
      </Section>
      <Team3 />
      <Testimonial3 />
      <ContactUs4 id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
