import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Button } from "element-react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllBlogs } from "redux/Blogs/blogs-actions";
import { NavLink } from "react-router-dom";
import { Section } from "@quarkly/widgets";
import { Override } from "@quarkly/components";
const MyBlogs = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.BlogDetails.Blog);
  console.log("The data", data);

  const [blogs, setBlogs] = useState(data);
  console.log("my blogs are", blogs);

  useEffect(() => {
    console.log("my useEfffect runs and dispatched the action");
    dispatch(getAllBlogs(data));
  }, []);

  return (
    <Section display="flex" justify-content="center">
      <Override
        slot="SectionContent"
        margin="40px 80px 40px 80px"
        sm-margin="8px 15px 8px 15px"
        sm-align-items="center"
        sm-justify-content="center"
        lg-margin="40px auto 40px auto"
        className="w-full"
      />
      <div className="flex justify-between">
        <div className="">
          <p className="font-medium text-lg">All Blogs</p>
          <h1 className="fsize37 sm-fsize20 themefamily font-semibold">
            Checkout Blogs
          </h1>
        </div>
      </div>
      <div className="">
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper"
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          }}
        >
          <div className="z-10 swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>

          {data.map((element) => {
            return (
              <SwiperSlide>
                <NavLink to="/blog overview">
                  <div className="bg-white shadow p-2 my-3  rounded-lg">
                    <div className="slide-img">
                      <img
                        src={
                          process.env.REACT_APP_STORAGE_URL_2 + element.image
                        }
                        alt="element"
                        style={{ height: "200px", width: "100%" }}
                        className="rounded-lg"
                      />
                    </div>
                    <div className="bg-white px-2 pt-3 pb-7">
                      <div className="flex gap-2 items-center">
                        <p className="fsize15 themefamily font-semibold mb-1">
                          {element.categoryID.categoryName}
                        </p>
                        <p className="text-sm font-medium mb-1">
                          {new Date(element.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                      <h3 className="text-xl font-semibold themefamily">
                        {element.title}
                      </h3>

                      <p
                        className="text-sm mt-2 themefamily line-clamp3"
                        dangerouslySetInnerHTML={{ __html: element.content }}
                      ></p>
                    </div>
                  </div>
                </NavLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Section>
  );
};

export default MyBlogs;
