import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getVariations } from "redux/variations/variation-action";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { setCmsActive } from "redux/CMS/cms-actions.js";
import { useParams } from "react-router-dom";
import Home from "./Home.js";
import Home1 from "./Home1";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home5 from "./Home5";

export default () => {
  const dispatch = useDispatch();
  let homePage = useSelector((state) => state.Variations.variation);
  useEffect(() => {
    // console.log("mount");
    // dispatch(getVariations({ pageId: "6299b8fcd3a5d92deb0b908d" }));
    // dispatch(getBrandProfile());
  }, [dispatch]);

  const { token } = useParams();

  if (token === "1234") {
    dispatch(setCmsActive(true));
  } else {
    dispatch(setCmsActive(false));
  }
  // console.log(homePage, "main home page");
  return (
    <div>
      {homePage &&
      homePage["selectedVariation"] &&
      homePage["selectedVariation"].id ? (
        homePage["selectedVariation"].id === "648c3832aca4c7001264ac00" ? (
          <Home />
        ) : homePage["selectedVariation"].id === "648c326faca4c7001264abfb" ? (
          <Home1 />
        ) : homePage["selectedVariation"].id === "648c3281aca4c7001264abfc" ? (
          <Home2 />
        ) : homePage["selectedVariation"].id === "648c3291aca4c7001264abfd" ? (
          <Home3 />
        ) : homePage["selectedVariation"].id === "648c32a1aca4c7001264abfe" ? (
          <Home4 />
        ) : homePage["selectedVariation"].id === "648c32d7aca4c7001264abff" ? (
          <Home5 />
        ) : (
          ""
        )
      ) : (
        <Home />
      )}
    </div>
  );
};
