export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64523a64bf72b300207ea40c",
				"64523a64bf72b300207ea40f",
				"6453418f3dedc4001fcc1e91",
				"64536d9ebf72b300207eb9b0",
				"645392534b38c4002005ffce",
				"64539922aed6310020c0d557"
			]
		},
		"64523a64bf72b300207ea40c": {
			"id": "64523a64bf72b300207ea40c",
			"name": "404",
			"pageUrl": "404"
		},
		"64523a64bf72b300207ea40f": {
			"id": "64523a64bf72b300207ea40f",
			"name": "index",
			"pageUrl": "index"
		},
		"6453418f3dedc4001fcc1e91": {
			"pageUrl": "index1",
			"id": "6453418f3dedc4001fcc1e91",
			"name": "about us"
		},
		"64536d9ebf72b300207eb9b0": {
			"pageUrl": "index11",
			"id": "64536d9ebf72b300207eb9b0",
			"name": "service page"
		},
		"645392534b38c4002005ffce": {
			"pageUrl": "index111",
			"id": "645392534b38c4002005ffce",
			"name": "Contact Us"
		},
		"64539922aed6310020c0d557": {
			"pageUrl": "index2",
			"id": "64539922aed6310020c0d557",
			"name": "Portfolio"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}