import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Box, Image, Text, Hr, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import Team from "../../components/team/Team";
import ContactUs from "../../components/contact/Contact";
import Testimonial from "components/testimonial/Testimonial";
import { getAllMenu } from "redux/Menu/menu-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Portfolio"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index2"} />
      <Helmet>
        <title>Portfolio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section position="relative" display="none">
        <Override slot="SectionContent" margin="40px 80px 40px 80px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-between"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="45%"
            height="max-content"
          >
            <Box height="10PX" background="--color-secondary" />
            <Image
              src="https://images.unsplash.com/photo-1455894127589-22f75500213a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              object-fit="cover"
              height="578px"
              border-radius={0}
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 40PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
          >
            <Text margin="0px 0px 0px 0px" font="--subheadlined1">
              provide the right candidate with a hassle-free screening process{" "}
            </Text>
            <Box
              display="flex"
              width="100%"
              justify-content="space-between"
              align-items="flex-start"
            >
              <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 32px 0px"
                  font="--subheadlined3"
                  color="--primary"
                  padding="0px 0px 0 0px"
                >
                  Our Promise
                </Text>
                <Hr
                  min-height="4px"
                  min-width="40%"
                  margin="0px 0px 0px 0px"
                  border-color="--color-secondary"
                  background="--color-secondary"
                  width="60%"
                />
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Mission
                </Text>
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Vision{" "}
                </Text>
              </Box>
            </Box>
            <Text margin="0px 0px 0px 0px" font="--p2">
              dEEVOiR promises to ensure robust performance and effectiveness in
              achieving greater possessions. We will support and allow the core
              HR outsourcing criteria and the effective outcome to be addressed.
            </Text>
            <Components.Btn />
          </Box>
        </Box>
      </Section>
      <Section margin="0 auto 0 auto" display="flex" justify-content=" center">
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          md-margin="40px auto 40px auto"
        />
        <Box min-width="100px" min-height="100px">
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 0px 48px 0px"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--subheadlined3"
              letter-spacing="4PX"
              color="--primary"
              id="4888021212"
              className="hoverText"
              onClick={() => isSideBarOpen("4888021212")}
            >
              {data ? data["4888021212"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--subheadlined1"
              md-font="--subheadlined2"
              sm-text-align="center"
              sm-font="--subheadlined3"
              id="4722460818"
              className="hoverText"
              onClick={() => isSideBarOpen("4722460818")}
            >
              {data ? data["4722460818"] : "no data"}

              {/* Industries What We Serve */}
            </Text>
            <Box
              display="flex"
              width="60%"
              justify-content="center"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                md-font="--p3"
                sm-font="--p4"
                id="1923155945"
                className="hoverText"
                onClick={() => isSideBarOpen("1923155945")}
              >
                {data ? data["1923155945"] : "no data"}

                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="100%"
            display="grid"
            grid-template-columns="repeat(4, 1fr)"
            grid-gap="24px"
            md-grid-template-columns="repeat(3, 1fr)"
            sm-grid-template-columns="repeat(1, 1fr)"
          >
            <Box
              className="hoverText"
              id="3079288385"
              onClick={() => isSideBarOpen("3079288385")}
            >
              <Image
                src={
                  data
                    ? data["3079288385"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="4795555385"
              onClick={() => isSideBarOpen("4795555385")}
            >
              <Image
                src={
                  data
                    ? data["4795555385"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="8356006674"
              onClick={() => isSideBarOpen("8356006674")}
            >
              <Image
                src={
                  data
                    ? data["8356006674"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="6500271605"
              onClick={() => isSideBarOpen("6500271605")}
            >
              <Image
                src={
                  data
                    ? data["6500271605"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            {/* new */}
            <Box
              className="hoverText"
              id="7681515885"
              onClick={() => isSideBarOpen("7681515885")}
            >
              <Image
                src={
                  data
                    ? data["7681515885"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="8627718303"
              onClick={() => isSideBarOpen("8627718303")}
            >
              <Image
                src={
                  data
                    ? data["8627718303"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="0754275585"
              onClick={() => isSideBarOpen("0754275585")}
            >
              <Image
                src={
                  data
                    ? data["0754275585"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="6673009090"
              onClick={() => isSideBarOpen("6673009090")}
            >
              <Image
                src={
                  data
                    ? data["6673009090"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="8477046608"
              onClick={() => isSideBarOpen("8477046608")}
            >
              <Image
                src={
                  data
                    ? data["8477046608"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="1968070054"
              onClick={() => isSideBarOpen("1968070054")}
            >
              <Image
                src={
                  data
                    ? data["1968070054"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="8829987024"
              onClick={() => isSideBarOpen("8829987024")}
            >
              <Image
                src={
                  data
                    ? data["8829987024"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
            <Box
              className="hoverText"
              id="1251845196"
              onClick={() => isSideBarOpen("1251845196")}
            >
              <Image
                src={
                  data
                    ? data["1251845196"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                display="block"
                width="100%"
                height="350px"
                object-fit="cover"
                border-radius="16px"
                hover-border-width="0 0 10px 0"
                hover-border-color="--color-secondary"
                hover-box-shadow="--xl"
                hover-border-style="solid"
                box-shadow="0 0px 0 6px #ffffff,0 0px 0 7px #e1e1e1"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Testimonial />
      <Team />
      <ContactUs id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
