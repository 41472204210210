import React, { useEffect } from "react";
import theme from "theme";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import { Theme, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  addSingleProduct,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

import { getBrandProfile } from "redux/BrandDetails/bd-actions";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    setMounted(true);
  }, [dispatch, setMounted]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);

      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");

    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }

  function getImage(value) {
    if (value)
      return value.includes("blob")
        ? value
        : process.env.REACT_APP_STORAGE_URL_2 + value;
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <div
        className="py-4 padresponsive hoverText"
        id="8027085407"
        onClick={() => isSideBarOpen("8027085407")}
      >
        {/* <Marquee speed={70}> */}
        {/* <Swiper
            observer={true}
            observeParents={true}
            className="container mx-auto overflow-hidden"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
            }}
          > */}
        {/* <Box
            className="hoverText"
            width="100%"
            id="9308369570"
            onClick={() => isSideBarOpen("9308369570")}
          >
            <Image
              src={
                data
                  ? data["9308369570"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="180px"
              height="80px"
              object-fit="cover"
              sm-margin="0px 32px 0px 0px"
              sm-height="80px"
              className="hoverText  mx-5 rounde-10"
            />
          </Box>
          <Box
            className="hoverText"
            width="100%"
            id="9240141558"
            onClick={() => isSideBarOpen("9240141558")}
          >
            <Image
              src={
                data
                  ? data["9240141558"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="180px"
              height="80px"
              object-fit="cover"
              sm-margin="0px 32px 0px 0px"
              sm-height="80px"
              className="hoverText  mx-5 rounde-10"
            />
          </Box>
          <Box
            className="hoverText"
            width="100%"
            id="9914503298"
            onClick={() => isSideBarOpen("9914503298")}
          >
            <Image
              src={
                data
                  ? data["9914503298"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="180px"
              height="80px"
              object-fit="cover"
              sm-margin="0px 32px 0px 0px"
              sm-height="80px"
              className="hoverText  mx-5 rounde-10"
            />
          </Box>
          <Box
            className="hoverText"
            width="100%"
            id="7785075530"
            onClick={() => isSideBarOpen("7785075530")}
          >
            <Image
              src={
                data
                  ? data["7785075530"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="180px"
              height="80px"
              object-fit="cover"
              sm-margin="0px 32px 0px 0px"
              sm-height="80px"
              className="hoverText  mx-5 rounde-10"
            />
          </Box>
          <Box
            className="hoverText"
            width="100%"
            id="9308369570"
            onClick={() => isSideBarOpen("9308369570")}
          >
            <Image
              src={
                data
                  ? data["9308369570"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="180px"
              height="80px"
              object-fit="cover"
              sm-margin="0px 32px 0px 0px"
              sm-height="80px"
              className="hoverText  mx-5 rounde-10"
            />
          </Box> */}
        <div className="container mx-auto ">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              991: {
                slidesPerView: 4.5,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 2.2,
                spaceBetween: 15,
              },
              325: {
                slidesPerView: 2.2,
                spaceBetween: 15,
              },
            }}
          >
            {/* <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div> */}
            {data && data["8027085407"]
              ? data["8027085407"].map((e, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Box width="100%">
                        <img
                          src={
                            e
                              ? getImage(e.field1)
                              : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                          }
                          alt=""
                          className="object-contain d-block img-custom"
                        />
                      </Box>
                    </SwiperSlide>
                  );
                })
              : "no-data"}

            {/* <SwiperSlide>
              <Box
                className="hoverText"
                width="100%"
                id="9914503298"
                onClick={() => isSideBarOpen("9914503298")}
              >
                <img
                  src={
                    data
                      ? data["9914503298"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                className="hoverText"
                width="100%"
                id="7785075530"
                onClick={() => isSideBarOpen("7785075530")}
              >
                <img
                  src={
                    data
                      ? data["7785075530"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Box
                className="hoverText"
                width="100%"
                id="9308369570"
                onClick={() => isSideBarOpen("9308369570")}
              >
                <img
                  src={
                    data
                      ? data["9308369570"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  alt=""
                  className="object-cover d-block img-custom"
                />
              </Box>
            </SwiperSlide> */}
          </Swiper>
        </div>

        {/* </Marquee> */}
      </div>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
