import API from "./index";

const get = "get";

const apiBase = (route) => {
  return `miniStore/brandProfile/${route}`;
};

export default {
  getBrandProfile(payload) {
    return API.post(apiBase(get), payload);
  },
};
