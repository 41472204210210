import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"sm-align-items": "center",
	"sm-justify-content": "center",
	"align-items": "center",
	"justify-content": "center",
	"background": "--color-greyD1",
	"padding": "80px 0 230px 0",
	"margin": "0 0 1px 0",
	"md-padding": "40px 0 230px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 32px 40px 0px",
			"sm-flex-direction": "column",
			"flex-direction": "column",
			"align-items": "center",
			"justify-content": "center",
			"width": "80%",
			"sm-margin": "0px 0px 40px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-end",
			"margin": "0px 0px 12px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"md-font": "--subheadlined2",
			"lg-font": "--subheadlined2",
			"sm-font": "--subheadlined3",
			"sm-margin": "0px 0px 8px 0px",
			"className": "hoverText",
			"id": "0553716672",
			"font": "--subheadlined1",
			"color": "--light",
			"children": "Meet Our Team"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"sm-font": "--p3",
			"className": "hoverText",
			"display": "flex",
			"text-align": "center"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--p2",
			"md-font": "--p4",
			"lg-font": "--p4",
			"sm-font": "--p4",
			"className": "hoverText",
			"id": "2961327375",
			"color": "--light",
			"width": "100%",
			"children": "We are a dedicated group of experts passionate about digital marketing. With extensive experience and a commitment to excellence, we work collaboratively to deliver outstanding results for our clients."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "24px",
			"width": "80%",
			"sm-grid-template-columns": "repeat(1, 1fr)",
			"md-grid-gap": "16px",
			"md-margin": "0px 0px 32px 0px",
			"lg-grid-template-columns": "repeat(3, 1fr)",
			"lg-margin": "0px 0px 14px 0px",
			"align-items": "center",
			"justify-items": "center",
			"margin": "0px 0px 40px 0px",
			"md-grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"border-width": "1px",
			"border-color": "--color-grey",
			"width": "100%",
			"padding": "37px 24px 24px 24px",
			"border-radius": "12px",
			"height": "400px",
			"hover-background": "rgba(0, 0, 0, 0) linear-gradient(0deg,--color-primary 0%,rgba(0, 0, 0, 0.46) 100%) 0 0/auto no-repeat scroll padding-box",
			"display": "flex",
			"flex-direction": "column",
			"lg-height": "fit-content",
			"lg-padding": "18px 18px 18px 18px",
			"sm-display": "flex"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/64815ab06efcea001ae15969/images/Frame%20483.png?v=2023-06-08T12:40:28.548Z",
			"display": "block",
			"width": "250px",
			"margin": "0px 0px 24px 0px",
			"md-margin": "0px 0px 24px 0px",
			"border-radius": "150px",
			"height": "250px",
			"object-fit": "cover",
			"lg-width": "200px",
			"lg-height": "200px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 4px 0px",
			"font": "--subheadlined3",
			"md-margin": "0px 0px 8px 0px",
			"md-font": "--subheadlined4",
			"children": "Salehdin Ahdam",
			"lg-text-align": "center",
			"lg-margin": "0px 0px 8px 0px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"md-font": "--p4",
			"children": "Head of Power MTI"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"justify-content": "center",
			"border-width": "1px",
			"border-color": "--color-grey",
			"width": "100%",
			"sm-display": "none",
			"padding": "24px 24px 24px 24px",
			"border-radius": "12px",
			"height": "400px",
			"hover-background": "rgba(0, 0, 0, 0) linear-gradient(0deg,--color-primary 0%,rgba(0, 0, 0, 0.46) 100%) 0 0/auto no-repeat scroll padding-box",
			"display": "flex",
			"flex-direction": "column",
			"lg-height": "fit-content",
			"lg-padding": "18px 18px 18px 18px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/64815ab06efcea001ae15969/images/Frame%20483.png?v=2023-06-08T12:40:28.548Z",
			"display": "block",
			"width": "250px",
			"margin": "0px 0px 24px 0px",
			"md-margin": "0px 0px 24px 0px",
			"border-radius": "150px",
			"object-fit": "cover",
			"height": "250px",
			"lg-width": "200px",
			"lg-height": "200px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 4px 0px",
			"font": "--subheadlined3",
			"md-margin": "0px 0px 8px 0px",
			"md-font": "--subheadlined4",
			"children": "Salehdin Ahdam",
			"lg-text-align": "center",
			"lg-margin": "0px 0px 8px 0px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"md-font": "--p4",
			"children": "Head of Power MTI"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"justify-content": "center",
			"border-width": "1px",
			"border-color": "--color-grey",
			"width": "100%",
			"padding": "24px 24px 24px 24px",
			"border-radius": "12px",
			"height": "400px",
			"hover-background": "rgba(0, 0, 0, 0) linear-gradient(0deg,--color-primary 0%,rgba(0, 0, 0, 0.46) 100%) 0 0/auto no-repeat scroll padding-box",
			"display": "flex",
			"flex-direction": "column",
			"lg-height": "fit-content",
			"lg-padding": "18px 18px 18px 18px",
			"md-display": "none"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/64815ab06efcea001ae15969/images/Frame%20483.png?v=2023-06-08T12:40:28.548Z",
			"display": "block",
			"width": "250px",
			"margin": "0px 0px 24px 0px",
			"md-margin": "0px 0px 24px 0px",
			"className": "hoverText",
			"border-radius": "150px",
			"object-fit": "cover",
			"height": "250px",
			"lg-width": "200px",
			"lg-height": "200px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 4px 0px",
			"font": "--subheadlined3",
			"md-margin": "0px 0px 8px 0px",
			"md-font": "--subheadlined4",
			"children": "Salehdin Ahdam",
			"lg-text-align": "center",
			"lg-margin": "0px 0px 8px 0px"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"md-font": "--p4",
			"id": "7831282320",
			"children": "Head of Power MTI"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"width": "8%",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"sm-width": "20%",
			"md-justify-content": "space-between",
			"md-width": "10%",
			"lg-width": "12%",
			"lg-justify-content": "space-between",
			"id": "2798095952",
			"grid-column": "1 / span 2"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"background": "--color-greyD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"margin": "0px 0px 0px 0px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-margin": "0px 4px 0px 0px",
			"md-width": "10px",
			"md-height": "10px",
			"lg-margin": "0px 8px 0px 0px"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	}
};

const Team = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			sm-margin="24px auto 24px auto"
			md-margin="24px auto 24px auto"
			align-items="center"
			justify-content="center"
			margin="0px auto 0px auto"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Image {...override("image")} />
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box5")}>
				<Image {...override("image1")} />
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box6")}>
				<Image {...override("image2")} />
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		<Box {...override("box7")}>
			<Box {...override("box8")} />
			<Box {...override("box9")} />
			<Box {...override("box10")} />
			<Box {...override("box11")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Team, { ...Section,
	defaultProps,
	overrides
});
export default Team;