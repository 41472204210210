import apis from "../../apis/apis";
export const getProject = (payload) => {
  return async (dispatch) => {
    try {
      // console.log("getProject");
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.user.getProject(payload);
      // console.log(data, "project");
      if (data) dispatch(setProject(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUser = (payload) => {
  return async (dispatch) => {
    try {
      console.log("getProject");
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.user.getUser(payload);
      if (data) dispatch(setUser(data.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setProject = (data) => {
  return {
    type: "setProject",
    payload: data,
  };
};

export const setUser = (data) => {
  return {
    type: "setUser",
    payload: data,
  };
};
