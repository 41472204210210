import React, { useEffect, useState } from "react";
import theme from "theme";
import { getAllMenu } from "redux/Menu/menu-actions";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import {
  getAllBlogs,
  getAllCategory,
  addSingleBlog,
} from "redux/Blogs/blogs-actions";
import { getCms, setSelectedData } from "redux/CMS/cms-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import Contact from "components/contact/Contact";

export default () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  let data = useSelector((state) => state.cms.pages["Blog Categories"]);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let userData = useSelector((state) => state.cms.userData);
  let menuData = useSelector((state) => state.menu.menu);
  const [openDialog, handleDisplay] = useState(false);

  let blogData = useSelector((state) => state.BlogDetails.Blog);
  let latestBlog = blogData.reverse();
  const [blog, setBlog] = useState(blogData);
  latestBlog.slice(0, 2);
  let blogCategory = useSelector((state) => state.BlogDetails.Category);
  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllCategory());

    // dispatch(getCms());
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );

    setMounted(true);
    console.log("ss", blog);
  }, [dispatch, setMounted]);
  console.log("blogData", blogData);
  console.log("getAllCategory", blogCategory);
  console.log(latestBlog, "latestblog");
  function showSingleBlog(data) {
    console.log("helloiam", data);
    dispatch(addSingleBlog(data));
  }

  const filterCategoryProduct = async (categoryId) => {
    // console.log(categoryId, "catr");
    // let blogFilterData = blogData.filter(
    //   (blog) => blog.categoryID._id === categoryId
    // );
    // console.log(blog, "checkfilter");
    // console.log(blogFilterData);
    // setBlog(blogFilterData);
    let categoryID = [];
    categoryID.push(categoryId);
    await dispatch(getAllBlogs({ categoryID: categoryID }));
  };

  const clearFilterBlog = async () => {
    await dispatch(getAllBlogs());
  };

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  // let blogBanner = `rgba(0, 0, 0, 0) url(${
  //   data ? data["7574540516"] : "bg-f4f5f6"
  // }) 0% 80% /cover repeat scroll padding-box`;

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"detail1"} />
      <Helmet>
        <title>Blog</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        background="--color-primary"
        position="relative"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          margin="20px auto 56px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
        />
        <Box
          display="flex"
          justify-content="space-between"
          align-items="center"
          flex-direction="column"
          margin="90PX 0px 90px 0px"
          sm-margin=" 0px"
          width="60%"
          sm-width="100%"
          className="zindex999"
        >
          <Text
            margin="0px 0px 8px 0px"
            font="--subheadlined4"
            letter-spacing="6PX"
            color="--darkL1"
            md-font="--subheadlined4"
            className="hoverText"
            id="4755763141"
            onClick={() => isSideBarOpen("4755763141")}
          >
            {data ? data["4755763141"] : "Blog"}
            {/* Blog */}
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            text-align="center"
            display="flex"
            flex-direction="column"
            color="--light"
            font="--headline3"
            md-font="--subheadlined1"
            sm-font="--subheadlined2"
            className="hoverText"
            id="4195300817"
            onClick={() => isSideBarOpen("4195300817")}
          >
            {data ? data["4195300817"] : "No data"}

            {/* We Deliver
            <br />
            Digital Strategy */}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            text-align="center"
            display="flex"
            flex-direction="column"
            font="--p2"
            color="--light"
            md-font="--p4"
            sm-font="--p4"
            className="hoverText"
            id="1632401051"
            onClick={() => isSideBarOpen("1632401051")}
          >
            {data ? data["1632401051"] : " No data"}
          </Text>
        </Box>
        {/* <Box
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-344px"
          height="452.2083333333333px"
          left="auto"
          right="-498px"
          z-index="99"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          border-width="2px"
          border-style="solid"
          border-color="#ffffff"
          display="none"
        />
        <Box
          background="--color-darkL1"
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-364px"
          height="315.208333px"
          left="auto"
          right="-485px"
          z-index="99"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
        />
        <Box
          width="1715.22px"
          position="absolute"
          top="-225.312px"
          bottom="-49.297px"
          height="839px"
          left="AUTO"
          right="AUTO"
          z-index="89"
          transform="rotate(-45deg)"
          border-width="2px"
          border-style="solid"
          border-color="--color-secondary"
        />
        <Box
          width="2042.72px"
          position="absolute"
          top="-225.312px"
          bottom="-49.297px"
          height="839px"
          left="auto"
          right="auto"
          z-index="99"
          transform="rotate(45deg)"
          border-width="2px"
          border-color="rgba(255, 255, 255, 0.18)"
          border-style="dashed"
        />
        <Box
          background="--color-darkL1"
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-323px"
          height="315.208333px"
          left="-757px"
          right="auto"
          z-index="99"
          transform="rotate(10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          md-bottom="-323px"
          md-left="-694px"
          md-right="auto"
          md-top="auto"
        /> */}
      </Section>
      {/* <Section quarkly-title="Title" width="100%" className="sss p-0">
        <Box position="relative">
          <Box
            id="7574540516"
            className="hoverText "
            onClick={() => isSideBarOpen("7574540516")}
          >
            <Image
              height="500px"
              width="100%"
              max-width="100%"
              min-width="100%"
              sm-height="300px"
              md-height="1000px"
              object-fit="cover"
              opacity="90%"
              src={data ? data["7574540516"] : "bg-f4f5f6"}
              alt="image"
            />
          </Box>
          <Box
            height="100%"
            className="absolute top-0 left-0 h-full w-full flex justify-center items-center"
            width="100%"
            md-height="180px"
            lg-height="250px"
          >
            <Box>
              <Text
                text-align="center"
                width="100%"
                margin="0px 0px 10px 0px"
                opacity="100%"
                mix-blend-mode="multiply"
                className="hoverText fsize49 sm-fsize24 font-semibold px-12"
                id="4195300817"
                onClick={() => isSideBarOpen("4195300817")}
              >
                {data ? data["4195300817"] : "No data"}
              </Text>
              <Text
                text-align="center"
                width="80%"
                sm-width="100%"
                color="--color-secondary"
                margin="10px auto 0px auto"
                sm-margin="10px 0px 0px 0px"
                className="hoverText fsize26 sm-fsize14 font-semibold px-12"
                id="1632401051"
                onClick={() => isSideBarOpen("1632401051")}
              >
                {data ? data["1632401051"] : " No data"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section> */}
      <Section quarkly-title="Blog List" padding="50px 0 50px 0">
        <Override
          slot="SectionContent"
          md-margin="0px 80px 0px 80px"
          sm-margin="0px 10px 0px 10px"
          lg-margin="0px 100px 0px 100px"
        />

        <Box
          display="flex"
          sm-flex-direction="column-reverse"
          justify-content="space-between"
          md-flex-direction="column"
        >
          <Box
            width="70%"
            margin="0px 20px 0px 0px"
            sm-width="100%"
            // height="884px"
            // overflow-x="hidden"
            // overflow-y="hidden"
            lg-height="100%"
            sm-margin="40px 0px 40px 0px"
            sm-height="fit-content"
            sm-overflow-y="none"
            sm-overflow-x="visible"
            md-margin="0px 20px 20px 0px"

            // className="overflow-y-auto overscroll-y-none no-scrollbar"
          >
            <Text
              margin="0px 0px 15px 0px"
              font="--headline3"
              md-margin="0px 0px 20px 0px"
              id="9300653766"
              className="hoverText fsize28"
              onClick={() => isSideBarOpen("9300653766")}
            >
              {data ? data["9300653766"] : "Top 10 Blogs"}
            </Text>
            {blogData && !!blogData.length
              ? blogData.map((e) => {
                  return (
                    <Box padding="0px 10px 10px 10px" sm-padding="0px">
                      <LinkBox
                        display="flex"
                        align-items="center"
                        justify-content="center"
                        margin="0px 0px 10px 0px"
                        md-width="100%"
                        flex-direction="row"
                        md-flex-direction="column"
                        sm-margin="0px 0px 20px 0px"
                        href="/blog overview"
                        padding="15px"
                        sm-padding="5px"
                        border-radius="4px"
                        onClick={() => showSingleBlog(e)}
                        className="shadow-md"
                        background="#f4f5f6"
                      >
                        <Image
                          height="200px"
                          width="400px"
                          background="--color-lightD1"
                          src={process.env.REACT_APP_STORAGE_URL_2 + e.image}
                          object-fit="cover"
                          className="hoverText"
                          md-width="100%"
                          md-height="250px"
                          sm-width="100%"
                          id="3903108444"
                        />
                        <Box
                          width="100%"
                          margin="0px 0px 0px 10px"
                          padding="5px 0px 5px 10px"
                          display="flex"
                          align-items="flex-start"
                          flex-direction="column"
                          justify-content="center"
                          md-height="fit-content"
                          md-padding="20px 0px 20px 0px"
                          md-margin="0px 0px 0px 0px"
                          sm-padding="5px"
                        >
                          <Text
                            margin="0px 0px 5px 0px"
                            className="fsize20 sm-fsize18 themefamily font-semibold"
                            md-width="100%"
                            id="5264261019"
                          >
                            {e.title}
                          </Text>
                          <Text
                            margin="0px 0px 5px 0px"
                            font="--base"
                            id="9650116616"
                            className="line-clamp3 sm-fsize13 fsize14 themefamily"
                          >
                            <p
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></p>
                          </Text>
                          <Text
                            margin="0px 0px 0px 0px"
                            font="--base"
                            md-width="100%"
                            id="6024116615"
                            className="sm-fsize12 themefamily fsize12"
                          >
                            {new Date(e.postDate).toDateString()}
                          </Text>
                        </Box>
                      </LinkBox>
                    </Box>
                  );
                })
              : "no-data"}
            <Box></Box>
          </Box>
          <Box
            sm-width="100%"
            height="100%"
            width="30%"
            md-width="100%"
            md-height="fit-content"
          >
            <Box margin="0px 0px 20px 16px" sm-margin="0px">
              <div className="flex justify-between w-full">
                <Text
                  margin="0px 0px 20px 0px"
                  font="--lead"
                  width="fit-content"
                  padding="6px 0px 6px 0px"
                  border-radius="2px"
                  id="2379710576"
                  className="font-semibold"
                >
                  Category
                </Text>
                <p
                  className="cursor-pointer underline  font-medium text-lg "
                  onClick={() => clearFilterBlog()}
                >
                  Clear
                </p>
              </div>
              <Box
                width="100%"
                display="grid"
                grid-gap="16px"
                grid-template-rows="repeat(3, 1fr)"
                grid-template-columns="repeat(2, 1fr)"
              >
                {blogCategory.category
                  ? blogCategory.category.map((e) => {
                      return (
                        <Box
                          display="flex"
                          align-items="center"
                          margin="0px 0px 10px 0px"
                          padding="8px 16px 8px 16px"
                          border-radius="100px"
                          cursor="pointer"
                          border-color="--color-lightD2"
                          justify-content="center"
                          id="1046597388"
                          className="c-chips"
                          onClick={() => filterCategoryProduct(e._id)}
                        >
                          <Text
                            className="text-dark"
                            margin="0px 0px 0px 0px"
                            lg-margin="0px 0px 0px 10px"
                          >
                            {e.categoryName}
                          </Text>
                        </Box>
                      );
                    })
                  : "no-data"}
              </Box>
            </Box>
            {/* <Box margin="0px 0px 0px 16px">
              <Text
                margin="0px 0px 20px 0px"
                font="--lead"
                color="--darkL1"
                width="fit-content"
                padding="6px 24px 6px 24px"
                background="--color-secondary"
                border-radius="2px"
                id="8790483819"
              >
                LATEST POST
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="9395423365"
              >
                PM unveils Shiva statue in Coimbatore on Mahashivratri
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="8545467464"
              >
                PM Modi: Kanpur train accident was a conspiracy
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="7777296395"
              >
                Sena, BJP have no option but to join hands again
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="3364459041"
              >
                Thousands bid adieu to martyred jawan in Kashmir
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="4853155899"
              >
                Jayalalithaa’s niece Deepa floats political outfit
              </Text>
              <Text
                margin="0px 0px 14px 0px"
                padding="0px 0px 7px 0px"
                border-style="solid"
                border-width="0 0 2px 0"
                border-color="--color-lightD2"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="2698733069"
              >
                China made mistake of ignoring India’s talent
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                sm-font="--quickHeader"
                className=""
                id="0588437687"
              >
                Jayalalithaa’s niece Deepa floats political outfit
              </Text>
            </Box> */}
          </Box>
        </Box>
      </Section>
      <Contact id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}

      <RawHtml>
        <style place={"endOfHead"} rawKey={"63e20e3f3a0508001e073e4c"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
