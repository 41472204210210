import React from "react";
import { useOverrides } from "@quarkly/components";
import { Button } from "@quarkly/widgets";
const defaultProps = {
  background: "--color-secondary",
  color: "--dark",
  font: "--bt",
  padding: "12px 24px 12px 24px",
  "sm-font": "--bt2",
  "sm-display": "flex",
  "sm-flex-direction": "row",
  "sm-margin": "0px 8px 0px 0px",
  "md-display": "block",
};
const overrides = {};

const Btn = (props) => {
  const { children, rest } = useOverrides(props, overrides, defaultProps);
  return (
    <Button id="5748094947" {...rest}>
      Enquire Now {"\n\n"}
      {children}
    </Button>
  );
};

Object.assign(Btn, { ...Button, defaultProps, overrides });
export default Btn;
