import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { ThreeDots } from "react-loader-spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getCms } from "redux/CMS/cms-actions";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { getVariations } from "redux/variations/variation-action";

import { useLocation, NavLink } from "react-router-dom";
import { getAllMenu, setLoading } from "redux/Menu/menu-actions";
import { Button, Image } from "@quarkly/widgets";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
// import User from "apis/user";

const NavbarHeader = (props) => {
  let { pathname } = useLocation();
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);

  const dispatch = useDispatch();
  // const location = useLocation();
  // const { override, children, rest } = useOverrides(
  //   props,
  //   overrides,
  //   defaultProps
  // );
  let loading = useSelector((state) => state.menu.loading);
  let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let brandDetails = useSelector((state) => state.brandDetails);
  let project = useSelector((state) => state.userDetails.project);

  useEffect(() => {
    async function mount() {
      await dispatch(getCms());

      dispatch(getVariations({ pageId: "6299b8fcd3a5d92deb0b908d" }));
      // await dispatch(getProject());

      await dispatch(getAllMenu());
      await dispatch(setLoading(true));
      await dispatch(
        getBrandProfile({
          projectId: project._id,
          projectName: project.projectName,
        })
      );
      // await dispatch(getAllProduct());

      const timer = setTimeout(async () => {
        await dispatch(setLoading(false));
      }, 2000);

      return () => clearTimeout(timer);
    }
    mount();
  }, [dispatch]);

  // ;

  const handleClick = () => {
    const element = document.getElementById("contactpage");
    element.scrollIntoView();
  };

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nexuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  function applyFontFamily() {
    if (brandDetails && brandDetails.hasOwnProperty("primaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.primaryFont
      );
    // console.log(applyFontFamily.primaryFont, "font");
    if (brandDetails && brandDetails.hasOwnProperty("secondaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.secondaryFont
      );

    if (brandDetails && brandDetails.hasOwnProperty("tertiaryColor"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.tertiaryColor
      );
  }
  applyFontFamily();
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();
  if (window.location.pathname === "/login") return null;
  if (window.location.pathname === "/register") return null;
  if (window.location.pathname === "/forgot-password") return null;
  return (
    <div className="w-full">
      {loading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="--qtheme-color-primary"
          ariaLabel="three-dots-loading"
          wrapperClass="loader-set"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <Navbar expand="lg" className="zindex fixed flex top-0 w-full bg-white">
          <div className="flex justify-between items-center container mx-auto w-full lg:px-8 md:px-8">
            <Override
              slot="SectionContent"
              margin="0px auto 56px auto"
              align-items="center"
              justify-content="center"
              width="100%"
              position="static"
              sm-margin="0px auto 24px auto"
            />
            <div className="lg:block  hidden">
              <Navbar.Brand href="#" className="text-dark flex  items-center">
                <NavLink to={"/Home"}>
                  <Image
                    height="60px"
                    width="80px"
                    src={getImages(brandProfile)}
                  />
                </NavLink>
              </Navbar.Brand>
            </div>
            <div className="widthsm ">
              <div className="flex justify-between items-center w-full px-2">
                <div className="">
                  <Navbar.Brand href="#" className="lg:hidden block text-dark">
                    <NavLink to={"/Home"}>
                      <Image
                        height="35px"
                        width="45px"
                        src={getImages(brandProfile)}
                      />
                    </NavLink>
                  </Navbar.Brand>
                </div>
                <div>
                  <Navbar.Toggle
                    aria-controls="navbarScroll"
                    className="w-full"
                  />
                </div>
              </div>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto flex items-center nav-pad"
                  // style={{ maxHeight: "200px" }}
                  navbarScroll
                >
                  {data.map((e, i) => {
                    // console.log(data, "SACH");
                    return (
                      <div className="flex" key={i}>
                        {e.menutype === "page" && e.subMenu.length < 2 ? (
                          <div
                            className="lg:px-6 flex py-2 "
                            key={e._id}
                            // onClick={() => this}
                          >
                            <NavLink
                              exact
                              to={`/${e.pageData[0].page}`}
                              onClick={() => setnavbar(i)}
                              className={
                                navbar === i
                                  ? "themefamily fsize16 font-semibold textsecondary"
                                  : "themefamily fsize16 font-normal text-dark"
                              }
                            >
                              {e.menuName}
                            </NavLink>
                          </div>
                        ) : (
                          e.pageData.length > 1 && (
                            <p className="themefamily fsize14 font-bold  underlino-blck">
                              {/* {e.menuName} */}
                            </p>
                          )
                        )}
                        {e.menutype === "page" && e.subMenu.length > 1 ? (
                          <div className="flex py-0 new-drop ">
                            <NavDropdown
                              id="nav-dropdown-dark-example"
                              className="flex themefamily fsize14 font-semibold  underlino-blck items-center"
                              menuVariant="black"
                              title={e.menuName}
                            >
                              {e.subMenu.map((sub) => {
                                return (
                                  <NavDropdown.Item
                                    href="#"
                                    key={sub._id}
                                    className="themefamily fsize14 font-semibold flex  underlino-blck"
                                  >
                                    <NavLink
                                      exact
                                      to={`/${sub.pageData[0].page}`}
                                      className="themefamily fsize16 font-semibold  underlino-blck"
                                    >
                                      {sub.menuName}
                                    </NavLink>
                                  </NavDropdown.Item>
                                );
                              })}
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {e.pageData.length === 0 ? (
                          <div className="mega-menu">
                            <NavDropdown
                              className="themefamily fsize16 text-black lg:ml-1 font-semibold flex underlino-blck "
                              title={e.menuName}
                              id="basic-nav-dropdown"
                            >
                              <div className="eventsNav ">
                                <Row>
                                  <Col
                                    xs="12"
                                    md="12"
                                    className="text-left grid grid-cols-4 items-start gap-4 rounded-cancel"
                                  >
                                    {e.subMenu.map((y) => {
                                      return (
                                        <div className="pb-2 borderb">
                                          <Dropdown.Header className="fsize16 py-0 text-danger font-semibold">
                                            {y.menuName}
                                          </Dropdown.Header>
                                          {y.pageData.map((data) => {
                                            return (
                                              <Dropdown.Item className="fsize12 py-1 text-gray font-normal ">
                                                <NavLink
                                                  onClick={setclick}
                                                  exact
                                                  to={`/${y.pageData[0].page}`}
                                                  className="themefamily fsize16 font-semibold  underlino-blck"
                                                >
                                                  {data.name}
                                                </NavLink>{" "}
                                              </Dropdown.Item>
                                            );
                                          })}
                                        </div>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    );
                  })}
                  <div className="lg:hidden block">
                    <Button
                      background="--color-secondary"
                      font="--bt"
                      padding="12px 24px 12px 24px"
                      sm-font="--bt2"
                      sm-display="flex"
                      sm-flex-direction="row"
                      sm-margin="0px 8px 0px 0px"
                      md-display="block"
                      md-font="--bt2"
                      md-margin="0px 8px 0px 0px"
                      border-radius="10px"
                      className="skinButton textwhite"
                      onClick={handleClick}
                    >
                      Enquire Now
                    </Button>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </div>

            <div className="lg:block hidden">
              <Button
                background="--color-secondary"
                border-radius="10px"
                font="--bt"
                padding="12px 24px 12px 24px"
                sm-font="--bt2"
                sm-display="flex"
                sm-flex-direction="row"
                sm-margin="0px 8px 0px 0px"
                md-display="block"
                md-font="--bt2"
                md-margin="0px 8px 0px 0px"
                className=" brad-4 textwhite"
                onClick={handleClick}
              >
                Enquire Now
              </Button>
            </div>
          </div>
        </Navbar>
      )}
    </div>
  );
};

export default NavbarHeader;
