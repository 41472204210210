import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import Team from "../../components/team/Team";
import ContactUs from "../../components/contact/Contact";
import Testimonial from "components/testimonial/Testimonial";
import { getAllMenu } from "redux/Menu/menu-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import Check from "./components/Check";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Services"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index11"} />
      <Helmet>
        <title>Services</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        background="--color-primary"
        position="relative"
        overflow-x="hidden"
        overflow-y="hidden"
        sm-align-items="center"
        sm-justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 56px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
          sm-margin="0px auto 24px auto"
        />
        <div className="container mx-auto  ">
          <Box
            display="flex"
            justify-content="space-between"
            align-items="flex-start"
            flex-direction="column"
            margin="60PX 0px 90px 0px"
            width="100%"
            lg-width="90%"
            sm-margin="70px 0px 60px 0px"
            md-margin="60PX 0px 60px 0px"
          >
            <Text
              margin="10px 0px 8px 0px"
              font="--subheadlined3"
              color="--darkL1"
              padding="0px 0px 0 0px"
              letter-spacing="6px"
              sm-font="--subheadlined5"
              sm-margin="0px 0px 8px 0px"
              md-font="--subheadlined4"
              sm-letter-spacing="3px"
              id="8301518189"
              onClick={() => isSideBarOpen("8301518189")}
              className="hoverText"
            >
              {data ? data["8301518189"] : "no data"}

              {/* Our Services */}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--headline2"
              text-align="left"
              display="flex"
              flex-direction="column"
              color="--light"
              sm-font="--subheadlined2"
              md-font="--headline3"
              className="hoverText"
              id="3846383016"
              onClick={() => isSideBarOpen("3846383016")}
            >
              {data ? data["3846383016"] : "no data"}

              {/* We Deliver
            <br />
            Digital Strategy */}
            </Text>
            <Box width="60%" sm-width="100%" md-width="70%">
              <Text
                margin="0px 0px 0px 0px"
                text-align="left"
                display="flex"
                flex-direction="column"
                font="--p1"
                color="--light"
                sm-font="--p5"
                md-font="--p2"
                id="7835464182"
                className="hoverText"
                onClick={() => isSideBarOpen("7835464182")}
              >
                {data ? data["7835464182"] : "no data"}

                {/* We are boutique firm design for Private Equit, Family Offices and
              Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
          </Box>
        </div>
        {/* <Box
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-344px"
          height="300.208333px"
          left="auto"
          right="-498px"
          z-index="999"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          border-width="2px"
          border-style="solid"
          border-color="#ffffff"
        />
        <Box
          background="--color-darkL1"
          width="2100px"
          position="absolute"
          top="auto"
          bottom="-362px"
          height="392.208333px"
          left="-154px"
          right="-506px"
          z-index="999"
          transform="rotate(-4deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
        />
        <Box
          width="1715.22px"
          position="absolute"
          top="-200px"
          bottom="auto"
          height="839px"
          left="-285px"
          right="auto"
          z-index="0"
          transform="rotate(-45deg)"
          border-style="solid"
          border-color="--color-secondary"
        />
        <Box
          width="2042.72px"
          position="absolute"
          top="-235px"
          bottom="auto"
          height="839px"
          left="auto"
          right="-845px"
          z-index="999"
          transform="rotate(45deg)"
          border-color="rgba(255, 255, 255, 0.18)"
          border-style="dashed"
        /> */}
      </Section>
      <Check />

      <Section id="section1">
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="32px auto 32px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          align-items="center"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
          >
            <Image
              src={
                data
                  ? data["2509206899"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              onClick={() => isSideBarOpen("2509206899")}
              className="hoverText"
              id="2509206899"
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            lg-margin="0px 0px 0px 30px"
            lg-padding="0px 0 0px 0px"
            md-margin="0px 0px 0px 0"
            md-width="100%"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="7393491535"
              onClick={() => isSideBarOpen("7393491535")}
            >
              {data ? data["7393491535"] : "no data"}

              {/* SEO */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="0540734688"
              onClick={() => isSideBarOpen("0540734688")}
            >
              {data ? data["0540734688"] : "no data"}

              {/* Search Engine Optimization{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              sm-margin="0px 0px 20px 0px"
              font="--p2"
              lg-font="--p4"
              className="hoverText"
              id="2104667004"
              onClick={() => isSideBarOpen("2104667004")}
            >
              {data ? data["2104667004"] : "no data"}

              {/* The goal of SEO is to increase organic, or unpaid, traffic to a
              website by making it easier for search engines like Google, Bing,
              and Yahoo to crawl and index the site's pages. This is achieved by
              incorporating relevant keywords and phrases into the website's
              content, meta tags, and URLs, as well as improving the site's
              internal linking structure and overall user experience.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px 24px"
              md-grid-gap="16px"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="0817333755"
                  onClick={() => isSideBarOpen("0817333755")}
                >
                  {data ? data["0817333755"] : "no data"}

                  {/* Conduct Keyword Research{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="4645830777"
                  onClick={() => isSideBarOpen("4645830777")}
                >
                  {data ? data["4645830777"] : "no data"}

                  {/* Optimize Your Website{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="2602349492"
                  onClick={() => isSideBarOpen("2602349492")}
                >
                  {data ? data["2602349492"] : "no data"}

                  {/* Create Quality Content:{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="0572189373"
                  onClick={() => isSideBarOpen("0572189373")}
                >
                  {data ? data["0572189373"] : "no data"}

                  {/* Build High-Quality Backlinks{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="1057732521"
                  onClick={() => isSideBarOpen("1057732521")}
                >
                  {data ? data["1057732521"] : "no data"}

                  {/* Use Meta Tags{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--p4"
                  className="hoverText"
                  id="5160354234"
                  onClick={() => isSideBarOpen("5160354234")}
                >
                  {data ? data["5160354234"] : "no data"}

                  {/* Use Alt Tags for Images{"\n\n"} */}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        id="section2"
        background="--color-primary"
        position="relative"
        z-index="0"
        md-overflow-x="hidden"
        md-overflow-y="hidden"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="500px"
          height="500px"
          border-width="1px"
          border-style="solid"
          border-color="rgba(255, 255, 255, 0.3)"
          border-radius="500px"
          align-items="center"
          display="flex"
          justify-content="center"
          position="absolute"
          bottom="-291px"
          left="-246px"
          right="auto"
          top="auto"
          z-index="-1"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="400px"
            height="400px"
            border-width="1px"
            border-style="dashed"
            border-color="#ffffff"
            border-radius="500px"
            align-items="center"
            display="flex"
            justify-content="center"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="300px"
              height="300px"
              border-width="1px"
              border-style="solid"
              border-color="#ffffff"
              border-radius="500px"
              align-items="center"
              display="flex"
              justify-content="center"
            />
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          align-items="center"
          color="--darkL1"
          md-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            lg-margin="0px 0px 0px 0px"
            md-width="100%"
            md-margin="0px 0px 24px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--light"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="9686024799"
              onClick={() => isSideBarOpen("9686024799")}
            >
              {data ? data["9686024799"] : "no data"}

              {/* PCC */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="5581213565"
              onClick={() => isSideBarOpen("5581213565")}
            >
              {data ? data["5581213565"] : "no data"}

              {/* Pay-Per-Click{" "} */}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              font="--p2"
              color="#e1e1e1"
              lg-font="--p4"
              className="hoverText"
              id="8527959077"
              onClick={() => isSideBarOpen("8527959077")}
            >
              {data ? data["8527959077"] : "no data"}

              {/* Advertisers are only charged when someone clicks on their ad,
              which is why it's called "pay-per-click." The cost per click (CPC)
              varies depending on the competition for the keyword and the
              advertiser's maximum bid. The goal is to have a high click-through
              rate (CTR), which is the percentage of people who click on an ad
              after seeing it.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              md-grid-gap="16px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="9982957967"
                  onClick={() => isSideBarOpen("9982957967")}
                >
                  {data ? data["9982957967"] : "no data"}

                  {/* Targeted Ads:{"\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="9290393478"
                  onClick={() => isSideBarOpen("9290393478")}
                >
                  {data ? data["9290393478"] : "no data"}

                  {/* Keyword Research{"\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="8977540607"
                  onClick={() => isSideBarOpen("8977540607")}
                >
                  {data ? data["8977540607"] : "no data"}

                  {/* Ad Copy{"\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="3866275629"
                  onClick={() => isSideBarOpen("3866275629")}
                >
                  {data ? data["3866275629"] : "no data"}

                  {/* Landing Pages{"\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="2852816296"
                  onClick={() => isSideBarOpen("2852816296")}
                >
                  {data ? data["2852816296"] : "no data"}

                  {/* Bid Strategy{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  md-font="--p4"
                  className="hoverText"
                  id="4784933148"
                  onClick={() => isSideBarOpen("4784933148")}
                >
                  {data ? data["4784933148"] : "no data"}

                  {/* Use Analytics */}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px" width="50%" md-width="100%">
            <Image
              src={
                data
                  ? data["0391147068"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              onClick={() => isSideBarOpen("0391147068")}
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
              id="0391147068"
              className="hoverText"
            />
          </Box>
        </Box>
      </Section>
      <Section id="section3">
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
          align-items="center"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
          >
            <Image
              src={
                data
                  ? data["0938350377"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              onClick={() => isSideBarOpen("0938350377")}
              className="hoverText"
              id="0938350377"
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            lg-padding="0px 0 0px 0px"
            md-width="100%"
            md-margin="0px 0px 0px 0"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="6423002147"
              onClick={() => isSideBarOpen("6423002147")}
            >
              {data ? data["6423002147"] : "no data"}

              {/* CRO */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="2961585452"
              onClick={() => isSideBarOpen("2961585452")}
            >
              {data ? data["2961585452"] : "no data"}

              {/* Conversion Rate Optimization{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              font="--p2"
              lg-font="--p4"
              className="hoverText"
              id="9281903493"
              onClick={() => isSideBarOpen("9281903493")}
            >
              {data ? data["9281903493"] : "no data"}

              {/* CRO involves a variety of techniques and strategies, including
              analyzing website data and user behavior, testing different
              website layouts and messaging, and making data-driven decisions to
              improve the user experience and increase conversions.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="2677845832"
                  onClick={() => isSideBarOpen("2677845832")}
                >
                  {data ? data["2677845832"] : "no data"}

                  {/* Define your conversion goals */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="6850913476"
                  onClick={() => isSideBarOpen("6850913476")}
                >
                  {data ? data["6850913476"] : "no data"}

                  {/* Define your conversion goals */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="1536367038"
                  onClick={() => isSideBarOpen("1536367038")}
                >
                  {data ? data["1536367038"] : "no data"}

                  {/* Test different elements{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="0638938558"
                  onClick={() => isSideBarOpen("0638938558")}
                >
                  {data ? data["0638938558"] : "no data"}

                  {/* Optimize for mobile{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="7706715857"
                  onClick={() => isSideBarOpen("7706715857")}
                >
                  {data ? data["7706715857"] : "no data"}

                  {/* Use social proof{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="1215035046"
                  onClick={() => isSideBarOpen("1215035046")}
                >
                  {data ? data["1215035046"] : "no data"}

                  {/* Monitor and analyze results{"\n\n"} */}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        id="section4"
        background="--color-primary"
        position="relative"
        z-index="0"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="40px auto 40px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="500px"
          height="500px"
          border-width="1px"
          border-style="solid"
          border-color="rgba(255, 255, 255, 0.3)"
          border-radius="500px"
          display="flex"
          align-items="center"
          justify-content="center"
          position="absolute"
          top="-253px"
          right="-277px"
          bottom="auto"
          left="auto"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="400px"
            height="400px"
            border-width="1px"
            border-color="#ffffff"
            border-radius="500px"
            display="flex"
            align-items="center"
            justify-content="center"
            border-style="dashed"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="300px"
              height="300px"
              border-width="1px"
              border-style="solid"
              border-color="#ffffff"
              border-radius="500px"
              display="flex"
              align-items="center"
              justify-content="center"
            />
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          align-items="center"
          color="--darkL1"
          md-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            md-width="100%"
            md-margin="0px 0px 24px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--light"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="8070379433"
              onClick={() => isSideBarOpen("8070379433")}
            >
              {data ? data["8070379433"] : "no data"}

              {/* CM */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="3191019314"
              onClick={() => isSideBarOpen("3191019314")}
            >
              {data ? data["3191019314"] : "no data"}

              {/* Content Marketing{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              font="--p2"
              color="#e1e1e1"
              lg-font="--p4"
              className="hoverText"
              id="2438472567"
              onClick={() => isSideBarOpen("2438472567")}
            >
              {data ? data["2438472567"] : "no data"}

              {/* The key to content marketing is to provide information and
              resources that are useful and interesting to your target audience,
              rather than simply promoting your products or services. This can
              include a variety of content types, such as blog posts, videos,
              social media posts, e-books, infographics, and more.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="8721969426"
                  onClick={() => isSideBarOpen("8721969426")}
                >
                  {data ? data["8721969426"] : "no data"}

                  {/* Target Audience{"\n\n\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  className="hoverText"
                  id="3896281924"
                  onClick={() => isSideBarOpen("3896281924")}
                >
                  {data ? data["3896281924"] : "no data"}

                  {/* Establish Clear Goals{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="7216014762"
                  onClick={() => isSideBarOpen("7216014762")}
                >
                  {data ? data["7216014762"] : "no data"}

                  {/* Ad Establish Clear Goals{"\n\n\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  className="hoverText"
                  id="2690511351"
                  onClick={() => isSideBarOpen("2690511351")}
                >
                  {data ? data["2690511351"] : "no data"}

                  {/* Create Valuable Content{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="7143500752"
                  onClick={() => isSideBarOpen("7143500752")}
                >
                  {data ? data["7143500752"] : "no data"}

                  {/* Optimize Your Content{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  className="hoverText"
                  id="9363709508"
                  onClick={() => isSideBarOpen("9363709508")}
                >
                  {data ? data["9363709508"] : "no data"}

                  {/* Promote Your Content{"\n\n"} */}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px" width="50%" md-width="100%">
            <Image
              src={
                data
                  ? data["7941945398"]
                  : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
              }
              onClick={() => isSideBarOpen("7941945398")}
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
              className="hoverText"
              id="7941945398"
            />
          </Box>
        </Box>
      </Section>
      <Section id="section5">
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          align-items="center"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
          >
            <Image
              onClick={() => isSideBarOpen("5944693523")}
              src={
                data
                  ? data["5944693523"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
              className="hoverText"
              id="5944693523"
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            lg-padding="0px 0 0px 0px"
            md-margin="0px 0px 0px 0"
            md-width="100%"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="3322796554"
              onClick={() => isSideBarOpen("3322796554")}
            >
              {data ? data["3322796554"] : "no data"}

              {/* EM */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="4140072094"
              onClick={() => isSideBarOpen("4140072094")}
            >
              {data ? data["4140072094"] : "no data"}

              {/* Email Marketing{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              font="--p2"
              lg-font="--p4"
              className="hoverText"
              id="0804549749"
              onClick={() => isSideBarOpen("0804549749")}
            >
              {data ? data["0804549749"] : "no data"}

              {/* Email marketing can be an effective way for businesses to build
              relationships with their customers, generate leads, and increase
              sales. It allows businesses to communicate with their subscribers
              directly, providing them with personalized messages and offers
              that are tailored to their interests and preferences.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="0000491187"
                  onClick={() => isSideBarOpen("0000491187")}
                >
                  {data ? data["0000491187"] : "no data"}

                  {/* Build a High-Quality Email List{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="2487619626"
                  onClick={() => isSideBarOpen("2487619626")}
                >
                  {data ? data["2487619626"] : "no data"}
                  {/* Segment Your List{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="0420500559"
                  onClick={() => isSideBarOpen("0420500559")}
                >
                  {data ? data["0420500559"] : "no data"}
                  {/* Craft Compelling{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="8805054842"
                  onClick={() => isSideBarOpen("8805054842")}
                >
                  {data ? data["8805054842"] : "no data"}
                  {/* Personalize Your Emails{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="9584296966"
                  onClick={() => isSideBarOpen("9584296966")}
                >
                  {data ? data["9584296966"] : "no data"}

                  {/* Make Your CTA Clear{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-secondary"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  sm-font="--subheadlined5"
                  className="hoverText"
                  id="3215940957"
                  onClick={() => isSideBarOpen("3215940957")}
                >
                  {data ? data["3215940957"] : "no data"}
                  {/* Test and Optimize{"\n\n"} */}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        id="section6"
        background="--color-primary"
        position="relative"
        z-index="0"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          md-margin="40px auto 40px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="500px"
          height="500px"
          border-width="1px"
          border-style="solid"
          border-color="rgba(255, 255, 255, 0.3)"
          border-radius="500px"
          display="flex"
          align-items="center"
          justify-content="center"
          position="absolute"
          top="-292px"
          right="auto"
          bottom="auto"
          left="-295px"
          z-index="-1"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="400px"
            height="400px"
            border-width="1px"
            border-color="#ffffff"
            border-radius="500px"
            display="flex"
            align-items="center"
            justify-content="center"
            border-style="dashed"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="300px"
              height="300px"
              border-width="1px"
              border-style="solid"
              border-color="#ffffff"
              border-radius="500px"
              display="flex"
              align-items="center"
              justify-content="center"
            />
          </Box>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          align-items="center"
          color="--darkL1"
          md-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            md-width="100%"
            md-margin="0px 0px 24px 0px"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              color="--light"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="0918232670"
              onClick={() => isSideBarOpen("0918232670")}
            >
              {data ? data["0918232670"] : "no data"}

              {/* BCSM */}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined1"
              lg-font="--subheadlined3"
              className="hoverText"
              id="4763095649"
              onClick={() => isSideBarOpen("4763095649")}
            >
              {data ? data["4763095649"] : "no data"}

              {/* Branding and Creative Services Marketing{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--p2"
              color="#e1e1e1"
              lg-font="--p4"
              className="hoverText"
              id="6943724694"
              onClick={() => isSideBarOpen("6943724694")}
            >
              {data ? data["6943724694"] : "no data"}

              {/* Creative services, on the other hand, focus on producing visual
              and written content that communicates the brand's messaging and
              engages its audience. This includes the creation of marketing
              materials such as social media posts, website design, advertising
              campaigns, and other branded content such as videos, infographics,
              and illustrations.{"\n\n"} */}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="3543802946"
                  onClick={() => isSideBarOpen("3543802946")}
                >
                  {data ? data["3543802946"] : "no data"}

                  {/* Brand Identity{"\n\n\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="7351476037"
                  onClick={() => isSideBarOpen("7351476037")}
                >
                  {data ? data["7351476037"] : "no data"}

                  {/* Visual Design{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="6808109486"
                  onClick={() => isSideBarOpen("6808109486")}
                >
                  {data ? data["6808109486"] : "no data"}

                  {/* Content Creation{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="9162860681"
                  onClick={() => isSideBarOpen("9162860681")}
                >
                  {data ? data["9162860681"] : "no data"}

                  {/* Brand Guidelines{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="4660543607"
                  onClick={() => isSideBarOpen("4660543607")}
                >
                  {data ? data["4660543607"] : "no data"}

                  {/* Strategy Development{"\n\n"} */}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="white"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined4"
                  color="--lightD2"
                  text-align="left"
                  lg-font="--subheadlined5"
                  className="hoverText"
                  id="0319576611"
                  onClick={() => isSideBarOpen("0319576611")}
                >
                  {data ? data["0319576611"] : "no data"}

                  {/* Creative Consultation{"\n\n"} */}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box width="50%" height="470px" md-width="100%">
            <Image
              src={
                data
                  ? data["5898807031"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              }
              onClick={() => isSideBarOpen("5898807031")}
              className="hoverText"
              id="5898807031"
              display="block"
              width="100%"
              hover-border-width=" 0 0 0 10px"
              border-style="solid"
              hover-border-color="--color-secondary"
              height="500px"
              sm-height="100%"
              object-fit="cover"
            />
          </Box>
        </Box>
      </Section>
      <Testimonial />
      <Team />
      <ContactUs id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
