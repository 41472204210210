import React from "react";
import Banner from "./components/Banner";
import PrivacyText from "./components/PrivacyText";

const Privacy = () => {
  return (
    <div>
      <Banner />
      <PrivacyText />
    </div>
  );
};

export default Privacy;
