import React, { useEffect } from "react";
import theme from "theme";
import { useOverrides, Override } from "@quarkly/components";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import { Text, Box, Image, Section } from "@quarkly/widgets";
import { Swiper, SwiperSlide } from "swiper/react";
import { getAllMenu } from "redux/Menu/menu-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";

const defaultProps = {
  margin: "0 auto 0px auto",
  "sm-align-items": "center",
  "sm-justify-content": "center",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      width: "100%",
      "min-height": "100px",
      display: "block",
      "text-align": "center",
      "justify-content": "between",
      margin: "0px 0px 40px 0px",
      "sm-flex-direction": "column",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "100%",
      "sm-width": "100%",
      "padding-right": "50px",
      "sm-padding-right": "0px",
      "sm-font": "--subheadlined3",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--subheadlined1",
      "md-font": "--subheadlined2",
      "lg-font": "--subheadlined2",
      children: (
        <>
          Meet Aur <br />
          Professional teams{"  "}
        </>
      ),
      "sm-font": "--subheadlined3",
      "sm-margin": "0px 0px 8px 0px",
      className: "hoverText",
      id: "0553716672",
    },
  },
  box2: {
    kind: "Box",
    props: {
      width: "100%",
      "sm-width": "100%",
      "sm-font": "--p3",
      className: "hoverText",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "5px 0px 0px 0px",
      font: "--p2",
      "md-font": "--p4",
      "lg-font": "--p4",
      children:
        "Why I say old chap that is, spiffing off his nut color blimey and guvnords geeza bloke knees up bobby sloshed arse",
      "sm-font": "--p4",
      className: "hoverText",
      id: "2961327375",
    },
  },
  box4: {
    kind: "Box",
    props: {
      "min-width": "300px",
      "min-height": "300px",
      width: "100%",
      padding: "18px 18px 18px 18px",
    },
  },
  image: {
    kind: "Image",
    props: {
      src: "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg",
      display: "block",
      width: "100%",
      height: "200px",
      margin: "0px 0px 32px 0px",
      "md-margin": "0px 0px 24px 0px",
      "object-fit": "cover",
      className: "hoverText object-cover",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 12px 0px",
      font: "--subheadlined4",
      "md-margin": "0px 0px 8px 0px",
      children: "Salehdin Ahdam",
      "text-align": "center",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      color: "--greyD1",
      "md-font": "--p4",
      children: "Head of Power MTI",
      "text-align": "center",
    },
  },
};

const Tam = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Section background="--color-secondary">
      <Override
        slot="SectionContent"
        align-items="center"
        margin="24px auto 40px auto"
        sm-margin="24px auto 24px auto"
        md-margin="24px auto 24px auto"
      />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Reveal>
            <Text
              {...override("text")}
              onClick={() => isSideBarOpen("0553716672")}
            >
              {data ? data["0553716672"] : "no data"}
            </Text>
          </Reveal>
        </Box>
        <Box {...override("box2")}>
          <Reveal>
            <Text
              {...override("text1")}
              onClick={() => isSideBarOpen("2961327375")}
            >
              {" "}
              {data ? data["2961327375"] : "no data"}
            </Text>
          </Reveal>
        </Box>
      </Box>
      <div className="container mx-auto overflow-x-hidden">
        {" "}
        <Reveal>
          <Swiper
            observer={true}
            observeParents={true}
            className="mySwiper"
            breakpoints={{
              1536: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            <SwiperSlide>
              <div className=" widsmall  p-2 w-full rounde-10">
                <Box
                  className="hoverText flex justify-center w-full overflow-hidden"
                  id="7703106093"
                  onClick={() => isSideBarOpen("7703106093")}
                >
                  <Image
                    src={
                      data
                        ? data["7703106093"]
                        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                    }
                    display="block"
                    width="200px"
                    height="200px"
                    sm-height="180px"
                    sm-width="180px"
                    margin="0px 0px 32px 0px"
                    md-margin="0px 0px 24px 0px"
                    object-fit="cover"
                    className="hoverText rounded-full object-cover "
                  />
                </Box>

                <Text
                  {...override("text2")}
                  id="5293572808"
                  className="hoverText"
                  onClick={() => isSideBarOpen("5293572808")}
                >
                  {data ? data["5293572808"] : "no data"}
                </Text>
                <Text
                  {...override("text3")}
                  id="0555432398"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0555432398")}
                >
                  {" "}
                  {data ? data["0555432398"] : "no data"}
                </Text>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" widsmall  p-2 w-full rounde-10">
                <Box
                  className="hoverText flex justify-center w-full overflow-hidden"
                  id="1901429983"
                  onClick={() => isSideBarOpen("1901429983")}
                >
                  <Image
                    src={
                      data
                        ? data["1901429983"]
                        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                    }
                    display="block"
                    width="200px"
                    height="200px"
                    sm-height="180px"
                    sm-width="180px"
                    margin="0px 0px 32px 0px"
                    md-margin="0px 0px 24px 0px"
                    object-fit="cover"
                    className="hoverText rounded-full object-cover "
                  />
                </Box>

                <Text
                  {...override("text2")}
                  id="1079149335"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1079149335")}
                >
                  {data ? data["1079149335"] : "no data"}
                </Text>
                <Text
                  {...override("text3")}
                  id="3025112473"
                  className="hoverText"
                  onClick={() => isSideBarOpen("3025112473")}
                >
                  {" "}
                  {data ? data["3025112473"] : "no data"}
                </Text>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" widsmall  p-2 w-full rounde-10">
                <Box
                  className="hoverText flex justify-center w-full overflow-hidden"
                  id="8482190422"
                  onClick={() => isSideBarOpen("8482190422")}
                >
                  <Image
                    src={
                      data
                        ? data["8482190422"]
                        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                    }
                    display="block"
                    width="200px"
                    height="200px"
                    sm-height="180px"
                    sm-width="180px"
                    margin="0px 0px 32px 0px"
                    md-margin="0px 0px 24px 0px"
                    object-fit="cover"
                    className="hoverText rounded-full object-cover "
                  />
                </Box>

                <Text
                  {...override("text2")}
                  id="8080177534"
                  className="hoverText"
                  onClick={() => isSideBarOpen("8080177534")}
                >
                  {data ? data["8080177534"] : "no data"}
                </Text>
                <Text
                  {...override("text3")}
                  id="8153237130"
                  className="hoverText"
                  onClick={() => isSideBarOpen("8153237130")}
                >
                  {" "}
                  {data ? data["8153237130"] : "no data"}
                </Text>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" widsmall  p-2 w-full rounde-10">
                <Box
                  className="hoverText flex justify-center w-full overflow-hidden"
                  id="9047381143"
                  onClick={() => isSideBarOpen("9047381143")}
                >
                  <Image
                    src={
                      data
                        ? data["9047381143"]
                        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                    }
                    display="block"
                    width="200px"
                    height="200px"
                    sm-height="180px"
                    sm-width="180px"
                    margin="0px 0px 32px 0px"
                    md-margin="0px 0px 24px 0px"
                    object-fit="cover"
                    className="hoverText rounded-full object-cover "
                  />
                </Box>

                <Text
                  {...override("text2")}
                  id="4626526522"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4626526522")}
                >
                  {data ? data["4626526522"] : "no data"}
                </Text>
                <Text
                  {...override("text3")}
                  id="0102085817"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0102085817")}
                >
                  {" "}
                  {data ? data["0102085817"] : "no data"}
                </Text>
              </div>
            </SwiperSlide>
          </Swiper>
        </Reveal>
      </div>

      {children}
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

Object.assign(Tam, { ...Section, defaultProps, overrides });
export default Tam;
