import React, { useEffect } from "react";
import Slide from "react-reveal/Slide";
import Reveal from "react-reveal/Reveal";

import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Icon,
  Image,
  Section,
  Hr,
  LinkBox,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdPlayCircleFilled } from "react-icons/md";
import Portfolio from "../../components/portfolio/Portfolio";
import Team from "../../components/team/Team";
import ContactUs from "../../components/contact/Contact";
import Testimonial from "components/testimonial/Testimonial";
import Brands from "./components/Brands";
import { getAllMenu } from "redux/Menu/menu-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import MyBlogs from "components/myblogs/MyBlogs";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());
    console.log(data, "Home");
    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  const handleClick = () => {
    const element = document.getElementById("contactpage");
    element.scrollIntoView();
  };

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section background="--color-primary" position="relative">
        <Override
          slot="SectionContent"
          margin="0px auto 24px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
        />
        <Box
          display="flex"
          justify-content="space-between"
          align-items="center"
          flex-direction="column"
          margin="40px 0px 60px 0px"
          width="80%"
          sm-width="90%"
          md-width="70%"
          sm-margin="20px 0px 24px 0px"
        >
          <Text
            margin="0px 0px 32px 0px"
            font="--headline2"
            text-align="center"
            display="flex"
            flex-direction="column"
            color="--light"
            sm-width="100%"
            sm-font="--subheadlined2"
            md-font="--headline3"
            className="hoverText"
            id="8922600886"
            onClick={() => isSideBarOpen("8922600886")}
          >
            {data ? data["8922600886"] : "no data"}
            {/* We Deliver
            <br />
            Digital Strategy */}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            text-align="center"
            display="flex"
            flex-direction="column"
            font="--p1"
            color="--light"
            sm-font="--p5"
            lg-font="--p2"
            md-font="--p3"
            className="hoverText"
            id="8978450592"
            dangerouslySetInnerHTML={{
              __html: data ? data["8978450592"] : "no data",
            }}
            onClick={() => isSideBarOpen("8978450592")}
          >
            {/* We are boutique firm design for Private Equit, Family Offices and
            Growth - Stage Companies seeking to maximize results. */}
          </Text>
        </Box>
        <Box
          width="100%"
          align-items="center"
          display="flex"
          justify-content="center"
          position="relative"
          sm-width="90%"
          md-width="90%"
          md-padding="0px 0px 0px 0px"
          lg-width="90%"
          onClick={() => isSideBarOpen("2385650916")}
          className="hoverText cmstext-img"
          id="2385650916"
        >
          <Image
            src={
              data
                ? data["2385650916"]
                : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            }
            display="block"
            height="450px"
            object-fit="cover"
            border-radius="40px"
            sm-height="100%"
            md-width="100%"
            width="100%"
            className="hoverText"
          />
        </Box>
        <Box
          background="--color-darkL1"
          width="100%"
          position="absolute"
          top="auto"
          bottom="-1px"
          height="150px"
          left="auto"
          right="0px"
          z-index="-1"
        />
      </Section>
      <Brands />
      <Section
        sm-align-items="center"
        sm-justify-content="center"
        sm-padding="8px 0 8px 0"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          sm-margin="20px auto 20px auto"
          lg-margin="40px auto 40px auto"
          justify-content="justify-center"
          display="flex"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
          align-items="center"
          justify-content="center"
        >
          <Box
            width="60%"
            margin="0px 40px 0px 0px"
            padding="0px 60PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            sm-width="100%"
            sm-padding="0px 0 0px 0px"
            sm-margin="0px 0px 24px 0px"
            lg-padding="0px 0px 0px 0px"
            lg-height="max-content"
            lg-width="50%"
            md-justify-content="space-between"
            md-margin="0px 20px 0px 0px"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--subheadlined3"
              letter-spacing="4PX"
              color="--primary"
              sm-font="--subheadlined4"
              lg-margin="0px 0px 8px 0px"
              md-font="--subheadlined4"
              lg-font="--subheadlined4"
              className="hoverText"
              id="1632289585"
              onClick={() => isSideBarOpen("1632289585")}
            >
              {data ? data["1632289585"] : "no data"}
              {/* About Us */}
            </Text>
            <Text
              margin="0px 0px 16px 0px"
              font="--headline4"
              sm-font="--subheadlined2"
              sm-margin="0px 0px 16px 0px"
              lg-margin="0px 0px 16px 0px"
              md-font="--subheadlined2"
              md-margin="0px 0px 24px 0px"
              lg-font="--subheadlined2"
              className="hoverText sm-fsize14"
              id="7689255120"
              onClick={() => isSideBarOpen("7689255120")}
            >
              {data ? data["7689255120"] : "no data"}
              {/* One Group, <br />
              Many Disciplines */}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--p2"
              sm-font="--p4"
              lg-font="--p4"
              md-margin="0px 0px 24px 0px"
              className="hoverText"
              id="3313680024"
              onClick={() => isSideBarOpen("3313680024")}
            >
              {data ? data["3313680024"] : "no data"}
              {/* We started in 2003, with a very humble start but with great
              determination to help the clients GROW. With our passion &
              dedication and support from the customers, we were able to witness
              growth for both the client and us, and carve out a brand value
              within.
              <br />
              dEEVOiR is a management consulting firm providing end to end
              solutions to clienteles across industries. */}
            </Text>
            <Components.Btn
              onClick={handleClick}
              className="hoverText skinButton"
              id="0412288477"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            height="max-content"
            sm-width="100%"
            lg-border-width="8px 0 0  0"
            lg-border-style="solid"
            lg-border-color="--color-secondary"
            lg-height="450px"
          >
            <Box
              className="hoverText"
              id="4186580645"
              onClick={() => isSideBarOpen("4186580645")}
            >
              <Image
                src={
                  data
                    ? data["4186580645"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="578px"
                object-fit="cover"
                border-radius={0}
                sm-height="450px"
                lg-height="100%"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section position="relative" display="none">
        <Override slot="SectionContent" margin="40px 80px 40px 80px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-between"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="45%"
            height="max-content"
          >
            <Box height="10PX" background="--color-secondary" />
            <Image
              src="https://images.unsplash.com/photo-1455894127589-22f75500213a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              object-fit="cover"
              height="578px"
              border-radius={0}
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 40PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
          >
            <Text margin="0px 0px 0px 0px" font="--subheadlined1">
              provide the right candidate with a hassle-free screening process{" "}
            </Text>
            <Box
              display="flex"
              width="100%"
              justify-content="space-between"
              align-items="flex-start"
            >
              <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 32px 0px"
                  font="--subheadlined3"
                  color="--primary"
                  padding="0px 0px 0 0px"
                >
                  Our Promise
                </Text>
                <Hr
                  min-height="4px"
                  min-width="40%"
                  margin="0px 0px 0px 0px"
                  border-color="--color-secondary"
                  background="--color-secondary"
                  width="60%"
                />
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Mission
                </Text>
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Vision{" "}
                </Text>
              </Box>
            </Box>
            <Text margin="0px 0px 0px 0px" font="--p2">
              dEEVOiR promises to ensure robust performance and effectiveness in
              achieving greater possessions. We will support and allow the core
              HR outsourcing criteria and the effective outcome to be addressed.
            </Text>
            <Components.Btn />
          </Box>
        </Box>
      </Section>
      <Section position="relative" sm-align-items="flex-end">
        <Box
          margin="60px auto 80px auto"
          width="100%"
          sm-margin="0px auto 0px auto"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 0px 40px 0px"
            md-margin="0px 0px 40px 0px"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--subheadlined3"
              letter-spacing="4PX"
              color="--primary"
              sm-font="--subheadlined5"
              sm-letter-spacing="1px"
              md-font="--subheadlined4"
              md-margin="0px 0px 8px 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="0633521212"
              onClick={() => isSideBarOpen("0633521212")}
            >
              <Reveal>{data ? data["0633521212"] : "no data"} </Reveal>
              {/* Our Key Services */}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--subheadlined1"
              sm-font="--subheadlined3"
              sm-text-align="center"
              md-margin="0px 0px 24px 0px"
              md-font="--subheadlined2"
              sm-margin="0px 0px 12px 0px"
              className="hoverText"
              id="3734049364"
              onClick={() => isSideBarOpen("3734049364")}
            >
              <Reveal> {data ? data["3734049364"] : "no data"} </Reveal>
              {/* Industries What We Serve */}
            </Text>
            <Box
              display="flex"
              width="60%"
              sm-width="100%"
              md-width="80%"
              lg-width="80%"
              id="0629982000"
              className="hoverText"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                lg-font="--p3"
                lg-min-width="none"
                lg-min-height="none"
                className="hoverText"
                id="0629982000"
                onClick={() => isSideBarOpen("0629982000")}
                dangerouslySetInnerHTML={{
                  __html: data ? data["0629982000"] : "no data",
                }}
              >
                {/* <Reveal> {data ? data["0629982000"] : "no data"}</Reveal> */}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="grid"
            grid-template-columns="repeat(3, 1fr)"
            grid-template-rows="repeat(2, 1fr)"
            grid-gap="24PX"
            margin="60px 0px 0px 0px"
            sm-grid-template-columns="repeat(1, 1fr)"
            sm-width="fit-content"
            sm-min-height={0}
            sm-min-width={0}
            lg-grid-template-columns="repeat(2, 1fr)"
            md-margin="0px 0px 0px 0px"
          >
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["8380951052"]
                    : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                }
                onClick={() => isSideBarOpen("8380951052")}
                className="hoverText"
                id="8380951052"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                  text-align="center"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4459966185")}
                  id="4459966185"
                >
                  {data ? data["4459966185"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="2184136934"
                onClick={() => isSideBarOpen("2184136934")}
              >
                {data ? data["2184136934"] : " no data"}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["9338462607"]
                    : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20485.png?v=2023-06-08T07:03:21.205Z"
                }
                onClick={() => isSideBarOpen("9338462607")}
                className="hoverText"
                id="9338462607"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  color="--dark"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7573397008")}
                  id="7573397008"
                >
                  {data ? data["7573397008"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="7999605823"
                onClick={() => isSideBarOpen("7999605823")}
              >
                {data ? data["7999605823"] : "no data"}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["5127224140"]
                    : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20486.png?v=2023-06-08T07:03:21.208Z"
                }
                onClick={() => isSideBarOpen("5127224140")}
                className="hoverText"
                id="5127224140"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                  text-align="center"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  className="hoverText"
                  onClick={() => isSideBarOpen("5994371340")}
                  id="5994371340"
                >
                  {data ? data["5994371340"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="8506712069"
                onClick={() => isSideBarOpen("8506712069")}
              >
                {data ? data["8506712069"] : "no data"}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["5378513421"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                onClick={() => isSideBarOpen("5378513421")}
                className="hoverText"
                id="5378513421"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2932463085")}
                  id="2932463085"
                >
                  {data ? data["2932463085"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="6460377051"
                onClick={() => isSideBarOpen("6460377051")}
              >
                {data ? data["6460377051"] : "no data"}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["1540216442"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                onClick={() => isSideBarOpen("1540216442")}
                className="hoverText"
                id="1540216442"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4178111992")}
                  id="4178111992"
                >
                  {data ? data["4178111992"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="8216480916"
                onClick={() => isSideBarOpen("8216480916")}
              >
                {data ? data["8216480916"] : "no data "}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
              padding="55px 40px 55px 40px"
              background="--color-darkL1"
              hover-border-width="10px 0 0 0"
              border-style="solid"
              hover-border-color="--color-secondary"
              box-shadow="--l"
              href="/index11"
            >
              <Image
                src={
                  data
                    ? data["9041677121"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                onClick={() => isSideBarOpen("9041677121")}
                className="hoverText"
                id="9041677121"
                display="block"
                margin="0px 0px 24px 0px"
                width="60px"
                height="60px"
              />
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                  text-align="center"
                  sm-font="--subheadlined4"
                  md-font="--subheadlined4"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1457927004")}
                  id="1457927004"
                >
                  {data ? data["1457927004"] : "no data"}
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                text-align="center"
                sm-font="--p4"
                md-font="--p3"
                lg-font="--p4"
                className="fsize15 hoverText"
                id="4126878104"
                onClick={() => isSideBarOpen("4126878104")}
              >
                {data ? data["4126878104"] : "no data"}
                {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          background="--color-primary"
          width="100%"
          position="absolute"
          top="auto"
          bottom={0}
          height="590px"
          right={0}
          left={0}
          z-index="-1"
          sm-height="502.08217647058825px"
          sm-bottom="0px"
          sm-top="auto"
          sm-display="flex"
          sm-align-items="flex-end"
          sm-left="auto"
          sm-right="0px"
        />
      </Section>
      <Portfolio />
      <MyBlogs />
      <Testimonial />
      <Team />
      <ContactUs id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
