import React, { useEffect } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Section,
  Hr,
  Icon,
  Image,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDropDown } from "react-icons/md";
import { GiGolfFlag } from "react-icons/gi";
import Portfolio from "../../components/portfolio/Portfolio";
import Team from "../../components/team/Team";
import ContactUs from "../../components/contact/Contact";
import Testimonial from "components/testimonial/Testimonial";
import { getAllMenu } from "redux/Menu/menu-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  const [counterOn, setCounterOn] = useState(false);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["About Us"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index1"} />
      <Helmet>
        <title>About us</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        background="--color-primary"
        position="relative"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 56px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
        />
        <Box
          display="flex"
          justify-content="space-between"
          align-items="center"
          flex-direction="column"
          margin="90PX 0px 90px 0px"
          sm-margin=" 0px"
          width="60%"
          sm-width="100%"
          sm-padding="0px 10px 0px 10px"
          className="zindex999"
        >
          <Text
            margin="0px 0px 8px 0px"
            font="--subheadlined4"
            letter-spacing="6PX"
            color="--darkL1"
            md-font="--subheadlined4"
            className="hoverText"
            id="0455075999"
            onClick={() => isSideBarOpen("0455075999")}
          >
            {data ? data["0455075999"] : " About Us"}

            {/* About Us */}
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            font="--headline3"
            text-align="center"
            display="flex"
            flex-direction="column"
            color="--light"
            md-font="--subheadlined1"
            sm-font="--subheadlined2"
            className="hoverText"
            id="0862452960"
            onClick={() => isSideBarOpen("0862452960")}
          >
            {data ? data["0862452960"] : "We Deliver <br />  Digital Strategy"}

            {/* We Deliver
            <br />
            Digital Strategy */}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            text-align="center"
            display="flex"
            flex-direction="column"
            font="--p2"
            color="--light"
            md-font="--p4"
            sm-font="--p4"
            className="hoverText"
            id="1065979949"
            onClick={() => isSideBarOpen("1065979949")}
          >
            {data
              ? data["1065979949"]
              : " We are boutique firm design for Private Equit, Family Offices and Growth - Stage Companies seeking to maximize results."}

            {/* We are boutique firm design for Private Equit, Family Offices and
            Growth - Stage Companies seeking to maximize results. */}
          </Text>
        </Box>
        <Box
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-344px"
          height="452.2083333333333px"
          left="auto"
          right="-498px"
          z-index="99"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          border-width="2px"
          border-style="solid"
          border-color="#ffffff"
          display="none"
        />
        <Box
          background="--color-darkL1"
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-364px"
          height="315.208333px"
          left="auto"
          right="-485px"
          z-index="99"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
        />
        <Box
          width="1715.22px"
          position="absolute"
          top="-225.312px"
          bottom="-49.297px"
          height="839px"
          left="AUTO"
          right="AUTO"
          z-index="89"
          transform="rotate(-45deg)"
          border-width="2px"
          border-style="solid"
          border-color="--color-secondary"
        />
        <Box
          width="2042.72px"
          position="absolute"
          top="-225.312px"
          bottom="-49.297px"
          height="839px"
          left="auto"
          right="auto"
          z-index="99"
          transform="rotate(45deg)"
          border-width="2px"
          border-color="rgba(255, 255, 255, 0.18)"
          border-style="dashed"
        />
        <Box
          background="--color-darkL1"
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-323px"
          height="315.208333px"
          left="-757px"
          right="auto"
          z-index="99"
          transform="rotate(10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          md-bottom="-323px"
          md-left="-694px"
          md-right="auto"
          md-top="auto"
        />
      </Section>
      <Section
        padding="120px 0 80px 0"
        sm-padding="60px 0px 60px 0px"
        lg-padding="120px 20px 40px 20px"
        lg-align-items="center"
        lg-justify-content="center"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          lg-margin="0px 0px 0px 0px"
        />
        <Box
          display="flex"
          width="50%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Box
            id="3571374612"
            onClick={() => isSideBarOpen("3571374612")}
            className="hoverText"
            object-fit="cover"
            width="100%"
            height="100%"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            // hover-transform="translateY(-10px)"
            sm-min-height="100vw"
            border-radius="24px"
            // hover-border-width="12px 0 0 0"
            border-width="0px 0 0 0"
            border-style="solid"
            border-color="--secondary"
          >
            <Image
              src={
                data
                  ? data["3571374612"]
                  : "https://nimbuscluster.blob.core.windows.net/server01/portfolio-blogs/cms/default2.jpg-9WfpZklGlnceGDWGwQUrP-1686315933-bPWA6D4Qm_9sFDwtGzWlO-1686913788"
              }
              object-fit="cover"
              width="100%"
              height="100%"
              transform="translateY(0px)"
              transition="transform 0.2s ease-in-out 0s"
              hover-transform="translateY(-10px)"
              sm-min-height="100vw"
              border-radius="24px"
              border-width="12px 0 0 0"
              border-style="solid"
              border-color="--secondary"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="column"
          lg-flex-wrap="wrap"
          lg-display="flex"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 40px"
          height="fit-content"
        >
          <Text
            margin="0px 0px 10px 0px"
            color="--primary"
            font="--subheadlined3"
            lg-text-align="center"
            lg-width="100%"
            className="hoverText"
            id="2493412118"
            onClick={() => isSideBarOpen("2493412118")}
          >
            {data ? data["2493412118"] : "WHO WE ARE"}
            {/* WHO WE ARE */}
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            color="--darkGrey"
            font="--subheadlined1"
            lg-text-align="center"
            sm-font="--s36B"
            lg-width="100%"
            className="hoverText"
            id="3188664762"
            onClick={() => isSideBarOpen("3188664762")}
          >
            {data
              ? data["3188664762"]
              : "Technology, connectivity, online, screens, devices."}
            {/* Technology, connectivity, online, screens, devices. */}
          </Text>
          <Text
            margin="0px 103px 20px 0px"
            color="--greyD1"
            font="--base"
            lg-text-align="center"
            lg-width="100%"
            md-margin="0px 103px 26px 0px"
            className="hoverText"
            id="6659108079"
            onClick={() => isSideBarOpen("6659108079")}
          >
            {data
              ? data["6659108079"]
              : "Digital disconnection allows individuals to take a break fromtechnology, promoting mental well-being and restoring a sense of balance."}
            {/* Digital disconnection allows individuals to take a break from
            technology, promoting mental well-being and restoring a sense of
           balance. */}
          </Text>
          <Box
            display="grid"
            grid-template-rows="repeat(3, 1fr)"
            grid-template-columns="repeat(1, 1fr)"
            lg-grid-gap="24px"
            md-grid-gap="24px"
          >
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 32px 0px"
              lg-width="100%"
              lg-margin="0px 0px 0px 0px"
              lg-padding="16px 16px 16px 16px"
              md-width="100%"
              md-padding="16px 16px 16px 16px"
              padding="26px 20px 26px 20px"
              border-radius="4px"
              box-shadow="0 0 6px 0 rgba(0, 0, 0, 0.21)"
              sm-flex-direction="column"
              sm-padding="12px 8px 12px 8px"
            >
              <Box margin="0px 0px 0px 0px" width="100%">
                <Text
                  margin="0px 0px 8px 0px"
                  color="--darkL2"
                  font="--subheadlined4"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="9651458155"
                  onClick={() => isSideBarOpen("9651458155")}
                >
                  {data ? data["9651458155"] : " When we started"}
                  {/* When we started */}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--greyD2"
                  font="--base"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="8101990246"
                  onClick={() => isSideBarOpen("8101990246")}
                >
                  {data
                    ? data["8101990246"]
                    : "Overall, digital disconnection offers a way to reclaim one's time and attention, foster a deeper connection with oneself and the physical world."}
                  {/* Overall, digital disconnection offers a way to reclaim one's
                  time and attention, foster a deeper connection with oneself
                  and the physical world. */}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 32px 0px"
              lg-width="100%"
              lg-margin="0px 0px 0px 0px"
              lg-padding="16px 16px 16px 16px"
              md-width="100%"
              md-padding="16px 16px 16px 16px"
              padding="26px 20px 26px 20px"
              border-radius="4px"
              box-shadow="0 0 6px 0 rgba(0, 0, 0, 0.21)"
              sm-flex-direction="column"
              sm-padding="12px 8px 12px 8px"
            >
              <Box margin="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 8px 0px"
                  color="--darkL2"
                  font="--subheadlined4"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="7063782934"
                  onClick={() => isSideBarOpen("7063782934")}
                >
                  {data ? data["7063782934"] : "Vision"}
                  {/* Vision */}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--greyD2"
                  font="--base"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="5229731918"
                  onClick={() => isSideBarOpen("5229731918")}
                >
                  {data
                    ? data["5229731918"]
                    : " Overall, digital disconnection offers a way to reclaim one's time and attention, foster a deeper connection with oneself and the physical world."}
                  {/* Overall, digital disconnection offers a way to reclaim one's
                  time and attention, foster a deeper connection with oneself
                  and the physical world. */}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 10px 0px"
              lg-width="100%"
              lg-margin="0px 0px 0px 0px"
              lg-padding="16px 16px 16px 16px"
              md-width="100%"
              md-padding="16px 16px 16px 16px"
              padding="26px 20px 26px 20px"
              border-radius="4px"
              box-shadow="0 0 6px 0 rgba(0, 0, 0, 0.21)"
              sm-flex-direction="column"
              sm-padding="12px 8px 12px 8px"
            >
              <Box margin="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 8px 0px"
                  color="--darkL2"
                  font="--subheadlined4"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="8794303499"
                  onClick={() => isSideBarOpen("8794303499")}
                >
                  {data ? data["8794303499"] : "Mision"}
                  {/* Mision */}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--greyD2"
                  font="--base"
                  lg-text-align="left"
                  sm-text-align="center"
                  className="hoverText"
                  id="1214905136"
                  onClick={() => isSideBarOpen("1214905136")}
                >
                  {data
                    ? data["1214905136"]
                    : "Overall, digital disconnection offers a way to reclaim one's time and attention, foster a deeper connection with oneself and the physical world.sion"}
                  {/* Overall, digital disconnection offers a way to reclaim one's
                   time and attention, foster a deeper connection with oneself
                   and the physical world. */}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>

      <Section
        align-items="center"
        flex-direction="column"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          sm-margin="16px auto 16px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          align-items="center"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 40px 0px 0px"
            padding="0px 60PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
            height="95%"
            md-width="50%"
            md-padding="0px 20px 0px 0px"
            md-margin="0px 16px 0px 0px"
            sm-width="100%"
            sm-margin="0px 0 16px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined3"
              letter-spacing="4PX"
              color="--primary"
              md-font="--subheadlined4"
              md-margin="0px 0px 8px 0px"
              className="hoverText"
              id="8968208824"
              onClick={() => isSideBarOpen("8968208824")}
            >
              {data ? data["8968208824"] : "About Us"}

              {/* About Us */}
            </Text>
            <Text
              margin="0px 0px 16px 0px"
              font="--subheadlined1"
              md-font="--subheadlined3"
              className="hoverText"
              id="9435050942"
              onClick={() => isSideBarOpen("9435050942")}
            >
              {data ? data["9435050942"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 16px 0px"
              font="--p2"
              md-font="--p4"
              sm-color="--greyD1"
              className="hoverText"
              id="5210339122"
              onClick={() => isSideBarOpen("5210339122")}
            >
              {data ? data["5210339122"] : "no data"}

              {/* We started in 2003, with a very humble start but with great
              determination to help the clients GROW. With our passion &
              dedication and support from the customers, we were able to witness
              growth for both the client and us, and carve out a brand value
              within.
              <br />
              <br />
              dEEVOiR is a management consulting firm providing end to end
              solutions to clienteles across industries. */}
            </Text>
            <Components.Btn
              id="2758634412"
              onClick={() => isSideBarOpen("2758634412")}
              className="hoverText skinButton"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            height="max-content"
            border-radius="16px"
            overflow-x="visible"
            overflow-y="visible"
            position="relative"
            display="flex"
            align-items="center"
            justify-content="center"
            sm-width="100%"
          >
            <Box
              id="2504776333"
              onClick={() => isSideBarOpen("2504776333")}
              className="hoverText"
              height="437.69642857142856px"
              width="314.27628571428573px"
              display="block"
              position="absolute"
              bottom="auto"
              z-index="1"
              left="63px"
              right="auto"
              top="auto"
              object-fit="cover"
              border-radius="16px"
              border-color="--color-darkL1"
              box-shadow="4px 0 0 8px #ffffff"
              md-bottom="auto"
              md-height="281.3243636363636px"
              md-top="84px"
              md-left="-67px"
              md-right="auto"
              md-width="230.6296363636364px"
              md-display="none"
            >
              <Image
                src={
                  data
                    ? data["2504776333"]
                    : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
                }
                height="437.69642857142856px"
                width="314.27628571428573px"
                display="block"
                position="absolute"
                bottom="auto"
                z-index="1"
                left="63px"
                right="auto"
                top="auto"
                object-fit="cover"
                border-radius="16px"
                border-color="--color-darkL1"
                box-shadow="4px 0 0 8px #ffffff"
                md-bottom="auto"
                md-height="281.3243636363636px"
                md-top="84px"
                md-left="-67px"
                md-right="auto"
                md-width="230.6296363636364px"
                md-display="none"
              />
            </Box>
            <Box
              border-width="20px 0 0 0"
              border-style="solid"
              border-color="--color-secondary"
              position="static"
              overflow-x="hidden"
              overflow-y="hidden"
              align-items="center"
              display="flex"
              justify-content="center"
              border-radius="16px"
              md-height="400px"
              margin="0 0 0 120px"
              sm-margin="0 0 0 0px"
            >
              <Box
                onClick={() => isSideBarOpen("7294347655")}
                id="7294347655"
                display="flex"
                width="400px"
                height="578px"
                object-fit="cover"
                border-radius={0}
                margin="-10px 0px 0px 0px"
                align-items="center"
                justify-content="center"
                className="hoverText"
              >
                <Image
                  src={
                    data
                      ? data["7294347655"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  display="flex"
                  width="400px"
                  height="578px"
                  object-fit="cover"
                  border-radius={0}
                  margin="-10px 0px 0px 0px"
                  align-items="center"
                  justify-content="center"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                position="absolute"
                bottom="auto"
                height="579.9887142857143px"
                top="auto"
                left="0px"
                right="auto"
                width="322.8477142857143px"
                background="rgba(255, 255, 255, 0.65)"
                filter="blur(0px)"
                md-left="0px"
                md-right="79.8152909090909px"
                md-width="211.9349090909091px"
                md-bottom="1.363636363636374px"
                md-height="429.0743636363636px"
                md-top="19.5611px"
                md-display="none"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        background="--color-primary"
        position="relative"
        overflow-x="hidden"
        overflow-y="hidden"
        align-items="center"
        flex-direction="column"
        justify-content="center"
        md-align-items="center"
        md-justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="60px 80px 60px 80px"
          display="flex"
          md-margin="24px auto 24px auto"
        />
        <Box
          border-width="1px"
          width="550px"
          height="550px"
          border-color="rgba(255, 255, 255, 0.31)"
          border-style="solid"
          border-radius="500px"
          position="absolute"
          top="-244px"
          right="auto"
          bottom="auto"
          left="-242px"
          display="flex"
          align-items="center"
          justify-content="center"
          sm-bottom="auto"
          sm-left="-384px"
          sm-right="auto"
          sm-top="-371px"
        >
          <Box
            border-width="1px"
            width="400px"
            height="400px"
            border-color="rgba(255, 255, 255, 0.31)"
            border-style="dashed"
            border-radius="500px"
            position="static"
            bottom="-183px"
            left="1226px"
            right="-226px"
            top="23.932299999999998px"
          />
        </Box>
        <Box
          border-width="1px"
          width="550px"
          height="550px"
          border-color="rgba(255, 255, 255, 0.31)"
          border-style="solid"
          border-radius="500px"
          position="absolute"
          top="auto"
          bottom="-244px"
          left="auto"
          display="flex"
          align-items="center"
          justify-content="center"
          right="-280px"
          sm-bottom="-362px"
          sm-left="auto"
          sm-right="-405px"
          sm-top="auto"
        >
          <Box
            border-width="1px"
            width="400px"
            height="400px"
            border-color="rgba(255, 255, 255, 0.31)"
            border-radius="500px"
            position="static"
            top="23.932299999999998px"
            right="-226px"
            bottom="-183px"
            left="1226px"
            border-style="dashed"
          />
        </Box>
        <Box
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="24px"
          color="--darkL1"
          sm-grid-template-columns="repeat(2, 1fr)"
        >
          <Box
            display="flex"
            align-items="center"
            flex-direction="column"
            justify-content="center"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              md-font="--headline4"
              id="4836337094"
              className="hoverText sm-fsize24"
              onClick={() => isSideBarOpen("4836337094")}
            >
              {/* {data ? data["4836337094"] : "no data"} */}
              {data ? data["4836337094"] : "40+ "}

              {/* 40+ */}
              {/* <div className="flex">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {" "}
                  {counterOn && (
                    <CountUp start={0} end={40} duration={4} delay={0} />
                  )}{" "}
                </ScrollTrigger>
                +
              </div> */}
            </Text>

            <Hr
              min-height="10px"
              margin="0px 0px 24px 0px"
              background="--color-secondary"
              width="20%"
              md-padding="0px 0px 0PX 0px"
              md-height="4px"
              md-min-height="auto"
              opacity="90"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined4"
              md-font="--p4"
              className="hoverText "
              id="5339492997"
              onClick={() => isSideBarOpen("5339492997")}
            >
              {data ? data["5339492997"] : "Project Complited"}

              {/* Project Complited */}
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            justify-content="center"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              md-font="--headline4"
              id="0028248914"
              className="hoverText sm-fsize24"
              onClick={() => isSideBarOpen("0028248914")}
            >
              {data ? data["0028248914"] : "500+ "}

              {/* 500+ */}
              {/* <div className="flex">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {" "}
                  {counterOn && (
                    <CountUp start={0} end={500} duration={4} delay={0} />
                  )}{" "}
                </ScrollTrigger>
                +
              </div> */}
            </Text>
            <Hr
              min-height="10px"
              margin="0px 0px 24px 0px"
              background="--color-secondary"
              width="20%"
              md-padding="0px 0px 0PX 0px"
              md-height="4px"
              md-min-height="auto"
              opacity="90"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined4"
              md-font="--p4"
              className="hoverText"
              id="7760638978"
              onClick={() => isSideBarOpen("7760638978")}
            >
              {data ? data["7760638978"] : "Project Complited"}

              {/* Project Complited */}
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            justify-content="center"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              md-font="--headline4"
              className="hoverText sm-fsize24"
              id="7314266212"
              onClick={() => isSideBarOpen("7314266212")}
            >
              {/* {data ? data["7314266212"] : "no data"} */}
              {data ? data["7314266212"] : "15+ "}

              {/* 15+ */}
              {/* <div className="flex">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {" "}
                  {counterOn && (
                    <CountUp start={0} end={15} duration={4} delay={0} />
                  )}{" "}
                </ScrollTrigger>
                +
              </div> */}
            </Text>
            <Hr
              min-height="10px"
              margin="0px 0px 24px 0px"
              background="--color-secondary"
              width="20%"
              md-padding="0px 0px 0PX 0px"
              md-height="4px"
              md-min-height="auto"
              opacity="90"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined4"
              md-font="--p4"
              className="hoverText"
              id="0100466103"
              onClick={() => isSideBarOpen("0100466103")}
            >
              {data ? data["0100466103"] : "Project Complited"}

              {/* Project Complited */}
            </Text>
          </Box>
          <Box
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              md-font="--headline4"
              id="4763648395"
              className="hoverText sm-fsize24"
              onClick={() => isSideBarOpen("4763648395")}
            >
              {/* {data ? data["4763648395"] : "no data"} */}
              {data ? data["4763648395"] : "60+ "}
              {/* 60+ */}
              {/* <div className="flex">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {" "}
                  {counterOn && (
                    <CountUp start={0} end={60} duration={4} delay={0} />
                  )}{" "}
                </ScrollTrigger>
                +
              </div> */}
            </Text>
            <Hr
              min-height="10px"
              margin="0px 0px 24px 0px"
              background="--color-secondary"
              width="20%"
              md-padding="0px 0px 0PX 0px"
              md-height="4px"
              md-min-height="auto"
              opacity="90"
            />
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined4"
              md-font="--p4"
              className="hoverText"
              id="8131773976"
              onClick={() => isSideBarOpen("8131773976")}
            >
              {data ? data["8131773976"] : "Project Complited"}

              {/* Project Complited */}
            </Text>
          </Box>
        </Box>
      </Section>
      <Section position="relative" display="none">
        <Override slot="SectionContent" margin="40px 80px 40px 80px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-between"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="45%"
            height="max-content"
          >
            <Box height="10PX" background="--color-secondary" />
            <Image
              src="https://images.unsplash.com/photo-1455894127589-22f75500213a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              object-fit="cover"
              height="578px"
              border-radius={0}
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 40PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
          >
            <Text margin="0px 0px 0px 0px" font="--subheadlined1">
              provide the right candidate with a hassle-free screening process{" "}
            </Text>
            <Box
              display="flex"
              width="100%"
              justify-content="space-between"
              align-items="flex-start"
            >
              <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 32px 0px"
                  font="--subheadlined3"
                  color="--primary"
                  padding="0px 0px 0 0px"
                >
                  Our Promise
                </Text>
                <Hr
                  min-height="4px"
                  min-width="40%"
                  margin="0px 0px 0px 0px"
                  border-color="--color-secondary"
                  background="--color-secondary"
                  width="60%"
                />
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Mission
                </Text>
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Vision{" "}
                </Text>
              </Box>
            </Box>
            <Text margin="0px 0px 0px 0px" font="--p2">
              dEEVOiR promises to ensure robust performance and effectiveness in
              achieving greater possessions. We will support and allow the core
              HR outsourcing criteria and the effective outcome to be addressed.
            </Text>
            <Components.Btn />
          </Box>
        </Box>
      </Section>
      <Section
        margin="0 auto 0px auto"
        align-items="center"
        flex-direction="column"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          margin="16px 80px 16px 80px"
        />
        <Box
          display="flex"
          margin="40px 0px 40px 0px"
          flex-direction="column"
          width="60%"
          align-items="center"
          justify-content="center"
          md-margin="0 0px 16px 0px"
          sm-width="100%"
        >
          <Box width="100%" margin="0px 0px 16px 0px" text-align="center">
            <Text
              margin="0px 0px 0px 0px"
              font="--subheadlined1"
              md-font="--subheadlined3"
              sm-font="--subheadlined4"
              className="hoverText"
              id="8960240624"
              onClick={() => isSideBarOpen("8960240624")}
            >
              {data ? data["8960240624"] : "Meet Aur Professional teams"}

              {/* Meet Aur Professional teams{"  "} */}
            </Text>
          </Box>
          <Box width="100%" text-align="center">
            <Text
              margin="0px 0px 0px 0px"
              font="--p2"
              md-font="--p4"
              sm-color="--greyD1"
              className="hoverText"
              id="1282676006"
              onClick={() => isSideBarOpen("1282676006")}
            >
              {data
                ? data["1282676006"]
                : " Why I say old chap that is, spiffing off his nut color blimey and guvnords geeza bloke knees up bobby sloshed arse"}

              {/* Why I say old chap that is, spiffing off his nut color blimey and
              guvnords geeza bloke knees up bobby sloshed arse */}
            </Text>
          </Box>
        </Box>
        <Box
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="24px"
          margin="0px 0px 56px 0px"
          width="100%"
          md-grid-template-columns="repeat(3, 1fr)"
          md-margin="0px 0px 0px 0px"
          md-grid-gap="16px"
          sm-grid-template-columns="repeat(1, 1fr)"
        >
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("2562722450")}
              id="2562722450"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["2562722450"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="9308046155"
              onClick={() => isSideBarOpen("9308046155")}
            >
              {data ? data["9308046155"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              font="--p3"
              md-font="--p4"
              sm-font="--p5"
              id="5144480868"
              className="hoverText"
              onClick={() => isSideBarOpen("5144480868")}
            >
              {data ? data["5144480868"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("4039117737")}
              id="4039117737"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["4039117737"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="8827763214"
              onClick={() => isSideBarOpen("8827763214")}
            >
              {data ? data["8827763214"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="4006478143"
              className="hoverText"
              onClick={() => isSideBarOpen("4006478143")}
            >
              {data ? data["4006478143"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("4329618718")}
              id="4329618718"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["4329618718"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="4962009694"
              onClick={() => isSideBarOpen("4962009694")}
            >
              {data ? data["4962009694"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="9231581246"
              className="hoverText"
              onClick={() => isSideBarOpen("9231581246")}
            >
              {data ? data["9231581246"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("9071874650")}
              id="9071874650"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["9071874650"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="7391245374"
              onClick={() => isSideBarOpen("7391245374")}
            >
              {data ? data["7391245374"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="5918541351"
              className="hoverText"
              onClick={() => isSideBarOpen("5918541351")}
            >
              {data ? data["5918541351"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("0319645961")}
              id="0319645961"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["0319645961"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="3936509743"
              onClick={() => isSideBarOpen("3936509743")}
            >
              {data ? data["3936509743"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="0368539620"
              className="hoverText"
              onClick={() => isSideBarOpen("0368539620")}
            >
              {data ? data["0368539620"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("7395735593")}
              id="7395735593"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["7395735593"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="3350294913"
              onClick={() => isSideBarOpen("3350294913")}
            >
              {data ? data["3350294913"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="4799451538"
              className="hoverText"
              onClick={() => isSideBarOpen("4799451538")}
            >
              {data ? data["4799451538"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("1657644308")}
              id="1657644308"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["1657644308"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="6589452607"
              onClick={() => isSideBarOpen("6589452607")}
            >
              {data ? data["6589452607"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="8849045588"
              className="hoverText"
              onClick={() => isSideBarOpen("8849045588")}
            >
              {data ? data["8849045588"] : "no data"}
            </Text>
          </Box>
          <Box
            padding="18px 18px 18px 18px"
            align-items="center"
            display="flex"
            flex-direction="column"
            justify-content="center"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
            width="100%"
            border-radius="10px"
            md-padding="16px 16px 16px 16px"
          >
            <div
              onClick={() => isSideBarOpen("2308184170")}
              id="2308184170"
              className="hoverText"
            >
              <Image
                src={
                  data
                    ? data["2308184170"]
                    : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                }
                display="block"
                width="100%"
                height="200px"
                margin="0px 0px 12px 0px"
                object-fit="cover"
                md-margin="0px 0px 16px 0px"
              />
            </div>
            <Text
              margin="0px 0px 12px 0px"
              font="--subheadlined4"
              md-font="--bt"
              md-margin="0px 0px 6px 0px"
              sm-font="--bt2"
              className="hoverText"
              id="1903939313"
              onClick={() => isSideBarOpen("1903939313")}
            >
              {data ? data["1903939313"] : "no data"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD1"
              md-font="--p4"
              sm-font="--p5"
              id="9992472575"
              className="hoverText"
              onClick={() => isSideBarOpen("9992472575")}
            >
              {data ? data["9992472575"] : "no data"}
            </Text>
          </Box>
        </Box>
      </Section>
      <Portfolio />
      <Testimonial />
      <ContactUs id="contactpage" />

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
