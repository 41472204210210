import axios from "axios";
import Rfactory from "../../apis/apis";
const Product = Rfactory.product;
// export default {
export const getAllProduct = () => {
  return async (dispatch) => {
    try {
      let { data } = await Product.getAllProduct();
      dispatch(product_reducer(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSingleProduct = (data) => {
  console.log(data);
  return {
    type: "ADD_SINGLE_PRODUCT",
    payload: data,
  };
};
export const defaultImage = (data) => {
  return {
    type: "ADD_DEFAULT_IMAGE",
    payload: data,
  };
};
export const defaultVariantData = (data) => {
  return {
    type: "ADD_DEFAULT_Variant",
    payload: data,
  };
};

export const addSelectedColor = (data) => {
  return {
    type: "ADD_SELECTED_COLOR",
    payload: data,
  };
};

export const product_reducer = (data) => {
  return {
    type: "setProductData",
    payload: data,
  };
};
