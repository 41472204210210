import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Hr, Image, Icon, Section } from "@quarkly/widgets";
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowRoundForward } from "react-icons/io";
const defaultProps = {
	"position": "static",
	"sm-align-items": "center",
	"sm-flex-direction": "column",
	"sm-justify-content": "center",
	"md-height": "max-content"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-between",
			"sm-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"margin": "0px 0px 0px 0px",
			"padding": "0px 40PX 0px 0px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-around",
			"align-items": "flex-start",
			"sm-width": "100%",
			"sm-padding": "0px 0 0px 0px",
			"md-margin": "0px 8px 0px 0px",
			"md-padding": "0px 32px 0px 0px",
			"md-justify-content": "flex-start",
			"md-align-items": "flex-start",
			"sm-margin": "0px 0px 24px 0"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--subheadlined3",
			"color": "--primary",
			"padding": "0px 0px 0 0px",
			"sm-margin": "0px 0px 4px 0px",
			"sm-letter-spacing": "1px",
			"lg-letter-spacing": "4px",
			"md-font": "--subheadlined4",
			"md-margin": "0px 0px 8px 0px",
			"lg-font": "--subheadlined4",
			"children": "Testimonials",
			"sm-font": "--subheadlined5",
			"letter-spacing": "4px",
			"className": "hoverText",
			"id": "5117533237"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--subheadlined1",
			"sm-width": "100%",
			"sm-font": "--subheadlined3",
			"sm-margin": "0px 0px 8px 0px",
			"lg-font": "--subheadlined2",
			"md-font": "--subheadlined3",
			"md-margin": "0px 0px 16px 0px",
			"children": "Check what our satisfied clients said",
			"className": "hoverText",
			"id": "1170245677"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--p2",
			"sm-margin": "0px 0px 0px 0px",
			"lg-font": "--p4",
			"md-font": "--p4",
			"children": "Why I say old chap that is, spiffing off his nut color blimey and guvnords geeza bloke knees up bobby sloshed arse",
			"sm-color": "--greyD1",
			"sm-font": "--p4",
			"className": "hoverText",
			"id": "1385195005"
		}
	},
	"hr": {
		"kind": "Hr",
		"props": {
			"min-height": "10px",
			"min-width": "3px",
			"margin": "0px 0px 0px 0px",
			"sm-height": "2px",
			"sm-min-height": 0,
			"sm-min-width": 0,
			"sm-margin": "0px 0px 16px 0px",
			"sm-background": "--color-lightD2",
			"sm-width": "80%",
			"sm-display": "none",
			"md-width": "2px",
			"md-background": "--color-lightD1",
			"background": "--color-lightD2"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "70%",
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"sm-width": "100%",
			"sm-margin": "0px 0px 16px 0px",
			"md-margin": "0px 0px 0px 8px",
			"lg-margin": "0px 0px 0px 8px",
			"sm-border-radius": "8px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "90%",
			"padding": "24px 24px 24px 24px",
			"background": "--color-darkL1",
			"box-shadow": "--l",
			"sm-width": "100%",
			"sm-box-shadow": "0 2px 15px -3px rgba(0, 0, 0, 0.32)",
			"md-box-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.17)",
			"md-width": "100% ",
			"lg-width": "100%",
			"sm-border-radius": "8px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"font": "--p2",
			"lg-font": "--p4",
			"md-font": "--p4",
			"lg-color": "--darkL2",
			"children": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus consectetur euismod aenean. Urna nibh risus nisl consequat consectetur. Ornare et, lorem mauris ligula. Et a tortor, velit vel.",
			"sm-color": "--greyD1",
			"sm-font": "--p4",
			"className": "hoverText",
			"id": "8564704404"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"sm-align-items": "center",
			"align-items": "center",
			"justify-content": "space-around",
			"md-align-items": "flex-end",
			"lg-justify-content": "space-between",
			"lg-align-items": "flex-end",
			"sm-justify-content": "flex-start"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"lg-width": "60px",
			"lg-height": "60px",
			"lg-min-width": "none",
			"lg-min-height": "none",
			"width": "90px",
			"height": "80px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"margin": "0px 24px 0px 0px",
			"md-width": "100px",
			"md-height": "60px",
			"md-margin": "0px 16px 0px 0px",
			"lg-margin": "0px 8px 0px 0px",
			"sm-width": "80px",
			"sm-margin": "0px 8px 0px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=200",
			"display": "block",
			"height": "60px",
			"object-fit": "cover",
			"border-radius": "40px",
			"margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"width": "100%",
			"md-margin": "0px 0 0px 0px",
			"md-width": "70%",
			"sm-width": "60px",
			"className": "hoverText",
			"id": "8395731289"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"height": "max-content",
			"width": "100%",
			"lg-width": "60%"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"font": "--subheadlined3",
			"color": "--dark",
			"lg-font": "--subheadlined4",
			"md-font": "--subheadlined4",
			"children": "Vijay D. Chauhan",
			"sm-margin": "0px 0px 4px 0px",
			"sm-font": "--subheadlined5",
			"className": "hoverText",
			"id": "9796888065"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--p2",
			"text-align": "left",
			"lg-font": "--p3",
			"md-font": "--p4",
			"children": "CEO & Co Founder",
			"sm-font": "--p5",
			"className": "hoverText",
			"id": "9261270788"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-end",
			"sm-display": "none",
			"md-width": "60px",
			"lg-width": "20%"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsArrowLeft,
			"size": "32px",
			"width": "50px",
			"height": "50px",
			"color": "--grey",
			"className": "hoverText",
			"id": "3579432792"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosArrowRoundForward,
			"size": "32px",
			"width": "50px",
			"height": "50px",
			"className": "hoverText",
			"id": "3579432792"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"display": "none",
			"align-items": "flex-end",
			"sm-display": "flex",
			"sm-align-items": "center",
			"sm-justify-content": "center"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsArrowLeft,
			"size": "64px",
			"width": "50px",
			"height": "50px",
			"color": "--grey",
			"sm-height": "40px",
			"sm-width": "40px"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "io",
			"icon": IoIosArrowRoundForward,
			"width": "50px",
			"height": "50px",
			"sm-height": "40px",
			"size": "40px"
		}
	},
	"SectionContent": {
		"props": {
			"sm-margin": "8px 18.75px 8px 18.75px"
		}
	}
};

const Testimonial = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" margin="80px auto 80px auto" sm-margin="16px auto 16px auto" md-margin="8px auto 8px auto" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
			<Hr {...override("hr")} />
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text3")} />
					<Box {...override("box4")}>
						<Box {...override("box5")}>
							<Image {...override("image")} />
						</Box>
						<Box {...override("box6")}>
							<Text {...override("text4")} />
							<Text {...override("text5")} />
						</Box>
						<Box {...override("box7")}>
							<Icon {...override("icon")} />
							<Icon {...override("icon1")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		<Box {...override("box8")}>
			<Icon {...override("icon2")} />
			<Icon {...override("icon3")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Testimonial, { ...Section,
	defaultProps,
	overrides
});
export default Testimonial;