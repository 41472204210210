const initialState = {
  menu: [],
  loading: true,
};
const menu_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setMenuData":
      return { ...state, menu: action.payload };

    case "setLoader":
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default menu_reducer;
