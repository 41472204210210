import React, { useEffect, useState } from "react";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import theme from "theme";
import { getAllMenu } from "redux/Menu/menu-actions";

import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import {
  getAllBlogs,
  getAllCategory,
  addSingleBlog,
} from "redux/Blogs/blogs-actions";
import { getCms, setSelectedData } from "redux/CMS/cms-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

const PrivacyText = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  let data = useSelector((state) => state.cms.pages["Privacy Policy"]);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let userData = useSelector((state) => state.cms.userData);
  let menuData = useSelector((state) => state.menu.menu);
  const [openDialog, handleDisplay] = useState(false);

  let blogData = useSelector((state) => state.BlogDetails.Blog);
  let latestBlog = blogData.reverse();
  const [blog, setBlog] = useState(blogData);
  latestBlog.slice(0, 2);
  let blogCategory = useSelector((state) => state.BlogDetails.Category);
  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllCategory());

    // dispatch(getCms());
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );

    setMounted(true);
    console.log("ss", blog);
  }, [dispatch, setMounted]);
  console.log("blogData", blogData);
  console.log("getAllCategory", blogCategory);
  console.log(latestBlog, "latestblog");
  function showSingleBlog(data) {
    console.log("helloiam", data);
    dispatch(addSingleBlog(data));
  }

  const filterCategoryProduct = async (categoryId) => {
    // console.log(categoryId, "catr");
    // let blogFilterData = blogData.filter(
    //   (blog) => blog.categoryID._id === categoryId
    // );
    // console.log(blog, "checkfilter");
    // console.log(blogFilterData);
    // setBlog(blogFilterData);
    let categoryID = [];
    categoryID.push(categoryId);
    await dispatch(getAllBlogs({ categoryID: categoryID }));
  };

  const clearFilterBlog = async () => {
    await dispatch(getAllBlogs());
  };

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  return (
    <div className="container mx-auto lg:px-24 lg:py-16">
      <div className="my-6">
        <p
          id="5125873007"
          dangerouslySetInnerHTML={{
            __html: data ? data["5125873007"] : "no data",
          }}
          onClick={() => isSideBarOpen("5125873007")}
          className="fsize15 text-gray themefamily mb-2 hoverText"
        ></p>
        {/* <h2 className="fsize20 mb-3 font-semibold themefamily">
          Lorem Ipsum dolor sit amet,
        </h2> */}
        {/* <ul>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            nulla eleifend a neque, egestas tincidunt erat dignissim at ac
            aliquam vitae volutpat. Justo sed nibh vulputate at. Fringilla
            molestie integer sed non ut dictum eget mauris. Aliquam mauris,
            bibendum vel
          </li>
          <li className="fsize15 list themefamily mb-2">
            egestas tincidunt erat dignissim at ac aliquam vitae volutpat. Justo
            sed nibh vulputate at. Fringilla molestie integer sed non ut dictum
          </li>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            nulla eleifend a neque, eget mauris. Aliquam mauris, bibendum vel
          </li>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            nulla eleifend a neque, eget egestas tincidunt erat dignissim at ac
            aliquam vitae volutpat. Justo sed nibh vulputate at. Fringilla
            molestie integer sed non ut dictummauris. Aliquam mauris, bibendum
            vel
          </li>
        </ul> */}
      </div>
      {/* <div>
        <h2 className="fsize20 mb-3 font-semibold themefamily">
          Lorem Ipsum dolor sit amet,
        </h2>
        <p className="fsize15 text-gray themefamily mb-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
          nulla eleifend a neque, eget mauris. Aliquam mauris, bibendum vel
          facilisis ornare. Nibh purus arcu nisi, pulvinar tincidunt elit, urna.
          Odio et habitasse non auctor. Nibh faucibus mi vulputate sit dictum
          pretium mattis aliquet. Consequat amet habitant viverra eget risus
          cras leo et. Turpis condimentum nisl elit scelerisque. Dolor est sit
          ipsum viverra. Imperdiet quam vel lobortis aliquet fusce aliquet sit
          malesuada sed. Velit ullamcorper lorem lacinia condimentum adipiscing
          nunc. Nec ultrices erat bibendum eget. Duis volutpat lorem nisl
          fermentum, posuere ac. Malesuada accumsan tristique faucibus non
          scelerisque aliquam. Elit egestas tincidunt erat dignissim at ac
          aliquam vitae volutpat. Justo sed nibh vulputate at. Fringilla
          molestie integer sed non ut dictum
        </p>
      </div>
      <div className="my-6">
        <h2 className="fsize20 mb-3 font-semibold themefamily ">
          Lorem Ipsum dolor sit amet,
        </h2>
        <ul>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            nulla eleifend a neque, egestas tincidunt erat dignissim at ac
            aliquam vitae volutpat. Justo sed nibh vulputate at. Fringilla
            molestie integer sed non ut dictum eget mauris. Aliquam mauris,
            bibendum vel
          </li>
          <li className="fsize15 list themefamily mb-2">
            egestas tincidunt erat dignissim at ac aliquam vitae volutpat. Justo
            sed nibh vulputate at. Fringilla molestie integer sed non ut dictum
          </li>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
            nulla eleifend a neque, eget mauris. Aliquam mauris, bibendum vel
          </li>
          <li className="fsize15 list themefamily mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. egestas
            tincidunt erat dignissim at ac aliquam vitae volutpat. Justo sed
            nibh vulputate at. Fringilla molestie integer sed non ut dictum
            Malesuada nulla eleifend a neque, eget mauris. Aliquam mauris,
            bibendum vel
          </li>
        </ul>
      </div>
      <div>
        <h2 className="fsize20 mb-3 font-semibold themefamily">
          Lorem Ipsum dolor sit amet,
        </h2>
        <p className="fsize15 text-gray themefamily mb-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada
          nulla eleifend a neque, eget mauris. Aliquam mauris, bibendum vel
          facilisis ornare. Nibh purus arcu nisi, pulvinar tincidunt elit, urna.
          Odio et habitasse non auctor. Nibh faucibus mi vulputate sit dictum
          pretium mattis aliquet. Consequat amet habitant viverra eget risus
          cras leo et. Turpis condimentum nisl elit scelerisque. Dolor est sit
          ipsum viverra. Imperdiet quam vel lobortis aliquet fusce aliquet sit
          malesuada sed. Velit ullamcorper lorem lacinia condimentum adipiscing
          nunc. Nec ultrices erat bibendum eget. Duis volutpat lorem nisl
          fermentum, posuere ac. Malesuada accumsan tristique faucibus non
          scelerisque aliquam. Elit egestas tincidunt erat dignissim at ac
          aliquam vitae volutpat. Justo sed nibh vulputate at. Fringilla
          molestie integer sed non ut dictum
        </p>
      </div> */}
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PrivacyText;
