import React, { useEffect } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  LinkBox,
  Icon,
  Link,
  Section,
  Image,
} from "@quarkly/widgets";
import { getAllMenu } from "redux/Menu/menu-actions";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";

import Contact from "components/contact/Contact";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { setSelectedData } from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

export default () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  let data = useSelector((state) => state.cms.pages["Work"]);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let menuData = useSelector((state) => state.menu.menu);
  let userData = useSelector((state) => state.cms.userData);
  const [openDialog, handleDisplay] = useState(false);

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);

      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");

    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function getImage(value) {
    if (value)
      return value.includes("blob")
        ? value
        : process.env.REACT_APP_STORAGE_URL_2 + value;
  }
  useEffect(() => {
    // dispatch(getAllMenu());
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
    setMounted(true);
  }, [dispatch, setMounted]);
  const openDialogBox = () => {
    handleDisplay(true);
  };

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"work"} />
      <Helmet>
        <title>Work</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        padding="80px 0 80px 0"
        margin="0 auto 0 auto"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          justify-content="center"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline2"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
            id="9108207387"
            className="hoverText"
            onClick={() => isSideBarOpen("9108207387")}
          >
            {data ? data["9108207387"] : "Works"}
          </Text>
          <Text
            font="--lead"
            color="--darkL2"
            max-width="600px"
            className="hoverText"
            id="2625076007"
            onClick={() => isSideBarOpen("2625076007")}
          >
            {data
              ? data["2625076007"]
              : " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"}
          </Text>
        </Box>

        <Box
          id="8849090685"
          onClick={() => isSideBarOpen("8849090685")}
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          md-grid-template-columns="1fr"
          width="100%"
          margin-margin="0px 0px 20px 0px"
          className="hoverText"
        >
          {data && data["8849090685"]
            ? data["8849090685"].map((e) => {
                return (
                  <Box
                    position="relative"
                    display="flex"
                    flex-direction="column"
                    href="/detail Overview"
                    className="elshadow-md"
                    border-radius="10px"
                  >
                    <Box>
                      {" "}
                      <Image
                        src={
                          e
                            ? getImage(e.field1)
                            : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                        }
                        margin-height="350px"
                        border-radius=" 10px 10px 0 0"
                        width="100%"
                        object-fit="cover"
                      />
                    </Box>

                    <Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
                      <Text
                        margin="24px 0px 0px 0px"
                        font="--subheadlined3"
                        sm-font="--subheadlined4"
                        md-font="--subheadlined4"
                        display="block"
                        text-align="left"
                      >
                        {e.field2}
                      </Text>
                      <Text
                        margin="16px 0px 0px 0px"
                        font="normal 400 14px/1.2 --fontFamily-sans"
                        display="block"
                        text-align="left"
                        color="#808080"
                        letter-spacing="2.8px"
                      >
                        {e.field3}
                      </Text>
                      <Text
                        margin="16px 0px 0px 0px"
                        font="--base"
                        display="block"
                        text-align="left"
                        color="--darkL2"
                        className="fontmedium"
                        dangerouslySetInnerHTML={{
                          __html: e.field4,
                        }}
                      >
                        {/* {e.field4} */}
                      </Text>
                    </Box>
                    {/* <Box
              className="flex justify-start w-full"
              padding="0px 12px 18px 12px"
            >
              <SocialMedia
                facebook="https://www.facebook.com/quarklyapp/"
                twitter="https://twitter.com/quarklyapp"
                instagram="https://instagram.com/instagram"
              >
                <Override
                  slot="link"
                  border-radius="50%"
                  color="--grey"
                  hover-color="--light"
                  background="transparent"
                  hover-background="transparent"
                  margin="0 5px 0 5px"
                  padding="0px 0px 0px 0px"
                />
                <Override slot="icon-facebook" color="#000000" />
                <Override
                  slot="icon"
                  color="--darkL1"
                  padding="10px 10px 10px 10px"
                  border-color="--color-grey"
                  border-style="solid"
                  size="40px"
                  border-radius="50px"
                  background="--color-lightD2"
                  border-width="0px"
                />
              </SocialMedia>
            </Box> */}
                  </Box>
                );
              })
            : "no-data"}
        </Box>
      </Section>
      <Contact id="contactpage" />
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"63e20e3f3a0508001e073e4c"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
