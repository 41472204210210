import API from "./index";

const getVariations = "getVariations";

const variations = (route) => {
  return `variations/${route}`;
};

export default {
  getVariations(payload) {
    return API.post(variations(getVariations), payload);
  },
};
