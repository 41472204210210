const initialState = {
  User: {},
};

const AuthDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SetLogin":
      return { ...state, UserDetails: action.payload };

    case "userLogout":
      console.log("red");
      return { ...state, UserDetails: {} };

    default:
      return state;
  }
};

export default AuthDetails;
