import React, { useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

import "../../../node_modules/react-quill/dist/quill.snow.css";

const EditorQuill = () => {
  const [text, setText] = useState("");

  const handleChange = (value) => {
    console.log(value);
    setText(value);
  };

  return (
    <div className="quill-container w-9/12 mx-auto h-screen">
      <h1 className="pt-24 pb-2 text-2xl font-bold">Write Your Blog Here :</h1>
      <div className="mt-2 text-editor">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={text}
          onChange={handleChange}
          placeholder="Write Your Blog Here..."
          modules={modules}
          formats={formats}
        />
      </div>

      <p className="font-bold text-md mt-2 mb-2">Blog Preview</p>
      <div className="w-5/12">
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </div>
  );
};

export default EditorQuill;
