import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import {
  Image,
  LinkBox,
  Link,
  Box,
  Button,
  Icon,
  Section,
} from "@quarkly/widgets";
import { FiMenu } from "react-icons/fi";
const defaultProps = {
  background: "--color-primary",
  position: "fixed",
  "z-index": "2",
  height: "fit-content",
  padding: "8px 0px 8px 0px",
  "md-align-items": "center",
  "md-justify-content": "center",
  "sm-top": "0px",
  "sm-bottom": "7159.294117647059px",
  "sm-height": "70.70588235294117px",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      height: "max-content",
      "md-align-items": "center",
      "sm-width": "100%",
      "sm-justify-content": "space-between",
      "md-justify-content": "space-between",
    },
  },
  linkBox: {
    kind: "LinkBox",
    props: {},
  },
  image: {
    kind: "Image",
    props: {
      display: "block",
      height: "70px",
      width: "70px",
      src: "https://uploads.quarkly.io/64523a64bf72b300207ea407/images/logo.png?v=2023-05-05T13:58:38.684Z",
      "object-fit": "contain",
      filter: "invert(0)",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "50%",
      display: "flex",
      "justify-content": "space-around",
      color: "#ffffff",
      "sm-display": "none",
      "md-display": "none",
    },
  },
  link: {
    kind: "Link",
    props: {
      href: "/index",
      color: "#ffffff",
      "text-decoration-line": "initial",
      children: "Home",
    },
  },
  link1: {
    kind: "Link",
    props: {
      href: "/index1",
      color: "#ffffff",
      "text-decoration-line": "initial",
      children: <>About Us </>,
    },
  },
  link2: {
    kind: "Link",
    props: {
      href: "/index11",
      color: "#ffffff",
      "text-decoration-line": "initial",
      children: "Services",
    },
  },
  link3: {
    kind: "Link",
    props: {
      href: "/index2",
      color: "#ffffff",
      "text-decoration-line": "initial",
      children: "Portfolio",
    },
  },
  link4: {
    kind: "Link",
    props: {
      href: "/index111",
      color: "#ffffff",
      "text-decoration-line": "initial",
      children: "Contact Us",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-height": "100p",
      "sm-height": "max-content",
      "sm-min-height": "max-content",
      "sm-display": "flex",
      "md-min-height": "auto",
      "md-min-width": "auto",
      "md-height": "max-content",
      "md-display": "flex",
      "md-flex-direction": "row",
      display: "flex",
      "align-items": "center",
    },
  },
  button: {
    kind: "Button",
    props: {
      background: "--color-secondary",
      color: "--dark",
      font: "--bt",
      padding: "12px 24px 12px 24px",
      "sm-font": "--bt2",
      "sm-display": "flex",
      "sm-flex-direction": "row",
      "sm-margin": "0px 8px 0px 0px",
      "md-display": "block",
      "md-font": "--bt2",
      "md-margin": "0px 8px 0px 0px",
      className: "hoverText skinButton",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      color: "--darkL1",
      "sm-display": "flex",
      "lg-display": "none",
      display: "none",
      "md-display": "flex",
      category: "fi",
      icon: FiMenu,
      size: "40px",
      "sm-width": "36px",
    },
  },
};

const Nav = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        margin="0px auto 0px auto"
        sm-flex-direction="row"
      />
      <Box {...override("box")}>
        <LinkBox {...override("linkBox")}>
          <Image {...override("image")} />
        </LinkBox>
        <Box {...override("box1")}>
          <Link {...override("link")} />
          <Link {...override("link1")} />
          <Link {...override("link2")} />
          <Link {...override("link3")} />
          <Link {...override("link4")} />
        </Box>
        <Box {...override("box2")}>
          <Button {...override("button")}>{" "}Enquire Now</Button>
          <Icon {...override("icon")} />
        </Box>
      </Box>
      {children}
    </Section>
  );
};

Object.assign(Nav, { ...Section, defaultProps, overrides });
export default Nav;
