import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0 auto 0px auto",
	"sm-align-items": "center",
	"sm-justify-content": "center"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"margin": "0px 0px 40px 0px",
			"sm-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"sm-font": "--subheadlined3"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--subheadlined1",
			"md-font": "--subheadlined2",
			"lg-font": "--subheadlined2",
			"children": <>
				Meet Aur{" "}
				<br />
				Professional teams{"  "}
			</>,
			"sm-font": "--subheadlined3",
			"sm-margin": "0px 0px 8px 0px",
			"className": "hoverText",
			"id": "0553716672"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"sm-font": "--p3",
			"className": "hoverText"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--p2",
			"md-font": "--p4",
			"lg-font": "--p4",
			"children": "Why I say old chap that is, spiffing off his nut color blimey and guvnords geeza bloke knees up bobby sloshed arse",
			"sm-font": "--p4",
			"className": "hoverText",
			"id": "2961327375"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "24px",
			"margin": "0px 0px 56px 0px",
			"width": "100%",
			"sm-grid-template-columns": "repeat(1, 1fr)",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "16px",
			"md-margin": "0px 0px 32px 0px",
			"lg-grid-template-columns": "repeat(3, 1fr)",
			"lg-margin": "0px 0px 14px 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "18px 18px 18px 18px",
			"align-items": "center",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-grey",
			"width": "100%"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"display": "block",
			"width": "100%",
			"margin": "0px 0px 32px 0px",
			"md-margin": "0px 0px 24px 0px",
			"className": "hoverText"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--subheadlined4",
			"md-margin": "0px 0px 8px 0px",
			"children": "Salehdin Ahdam"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD1",
			"md-font": "--p4",
			"children": "Head of Power MTI",
			"id": "7831282320"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "18px 18px 18px 18px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"justify-content": "center",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-grey",
			"width": "100%",
			"sm-display": "none"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"display": "block",
			"width": "100%",
			"margin": "0px 0px 32px 0px",
			"md-margin": "0px 0px 24px 0px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--subheadlined4",
			"md-margin": "0px 0px 8px 0px",
			"children": "Salehdin Ahdam"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD1",
			"md-font": "--p4",
			"children": "Head of Power MTI"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"padding": "18px 18px 18px 18px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"justify-content": "center",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-grey",
			"width": "100%",
			"sm-display": "none"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"display": "block",
			"width": "100%",
			"margin": "0px 0px 32px 0px",
			"md-margin": "0px 0px 24px 0px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--subheadlined4",
			"md-margin": "0px 0px 8px 0px",
			"children": "Salehdin Ahdam"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD1",
			"md-font": "--p4",
			"children": "Head of Power MTI"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"padding": "18px 18px 18px 18px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"justify-content": "center",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-grey",
			"width": "100%",
			"sm-display": "none",
			"lg-display": "none",
			"md-display": "none"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000",
			"display": "block",
			"width": "100%",
			"margin": "0px 0px 32px 0px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"font": "--subheadlined4",
			"children": "Salehdin Ahdam"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD1",
			"children": "Head of Power MTI"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"width": "8%",
			"display": "flex",
			"justify-content": "space-between",
			"align-items": "center",
			"sm-width": "20%",
			"md-justify-content": "space-between",
			"md-width": "10%",
			"lg-width": "12%",
			"lg-justify-content": "space-between",
			"id": "2798095952"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"background": "--color-greyD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"margin": "0px 0px 0px 0px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-margin": "0px 4px 0px 0px",
			"md-width": "10px",
			"md-height": "10px",
			"lg-margin": "0px 8px 0px 0px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"background": "--color-lightD2",
			"width": "12px",
			"height": "12px",
			"border-radius": "10px",
			"sm-width": "10px",
			"sm-height": "10px",
			"sm-margin": "0px 8px 0px 0px",
			"md-width": "10px",
			"md-height": "10px"
		}
	}
};

const Tam = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			align-items="center"
			margin="24px auto 40px auto"
			sm-margin="24px auto 24px auto"
			md-margin="24px auto 24px auto"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Image {...override("image")} />
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box5")}>
				<Image {...override("image1")} />
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box6")}>
				<Image {...override("image2")} />
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
			<Box {...override("box7")}>
				<Image {...override("image3")} />
				<Text {...override("text8")} />
				<Text {...override("text9")} />
			</Box>
		</Box>
		<Box {...override("box8")}>
			<Box {...override("box9")} />
			<Box {...override("box10")} />
			<Box {...override("box11")} />
			<Box {...override("box12")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Tam, { ...Section,
	defaultProps,
	overrides
});
export default Tam;