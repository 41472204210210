import API from "./index";
const get = "clientMenu";

const apiBase = (route) => {
  return `menu/${route}`;
};

export default {
  getAllMenu() {
    return API.get(apiBase(get));
  },
};
