import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Input, Section } from "@quarkly/widgets";
import Slide from "react-reveal/Slide";
import Btn from "./Btn";
import { Fade } from "react-reveal";
const defaultProps = {
  background: "--color-primary",
  "sm-align-items": "center",
  "sm-justify-content": "center",
  "align-items": "center",
  "justify-content": "center",
};
const overrides = {
  box: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      display: "flex",
      "justify-content": "space-between",
      "sm-flex-direction": "column",
    },
  },
  box1: {
    kind: "Box",
    props: {
      width: "50%",
      padding: "0px 12px 0px 0px",
      "lg-font": "--headline4",
      "sm-width": "100%",
      "sm-margin": "0px 0px 48px 0px",
      "md-width": "50%",
      "md-display": "flex",
      "md-align-items": "flex-start",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      "sm-width": "100%",
      "sm-font": "--headline4",
      "md-font": "--headline3",
      "lg-font": "--headline3",
      font: "--headline1",
      children: (
        <>
          Let’s <br />
          Work Together{" "}
        </>
      ),
      className: "hoverText",
      id: "3740607205",
    },
  },
  box2: {
    kind: "Box",
    props: {
      "min-width": "100px",
      "min-height": "100px",
      width: "60%",
      "sm-width": "100%",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 16px 0px",
      font: "--subheadlined3",
      color: "#ffffff",
      padding: "0px 0px 0 0px",
      "letter-spacing": "4px",
      "sm-font": "--subheadlined4",
      "md-font": "--subheadlined4",
      "md-display": "none",
      children: "Connect Us",
      id: "3265742750",
      className: "hoverText",
    },
  },
  box3: {
    kind: "Box",
    props: {
      display: "flex",
      "align-items": "center",
      margin: "0px 0px 40px 0px",
      "sm-margin": "0px 0px 16px 0px",
    },
  },
  input: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      width: "100%",
      margin: "0px 0px 0px 0px",
      height: "60px",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "--color-primary",
      placeholder: "Full Name*",
      color: "black",
      id: "5339832869",
      "font-weight": "500",
    },
  },
  box4: {
    kind: "Box",
    props: {
      display: "flex",
      "align-items": "center",
      margin: "0px 0px 32px 0px",
      "sm-display": "flex",
      "sm-flex-direction": "column",
      "sm-margin": "0px 0px 16px 0px",
    },
  },
  input1: {
    kind: "Input",
    props: {
      "placeholder-color": "LightGray",
      width: "100%",
      margin: "0px 24px 0px 0px",
      height: "60px",
      color: "black",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "--color-primary",
      placeholder: "Phone Number*",
      display: "flex",
      "sm-margin": "0px 0 16px 0px",
      id: "6885361906",
      "font-weight": "500",
    },
  },
  input2: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      width: "100%",
      height: "60px",
      color: "black",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "--color-primary",
      placeholder: "Email Id*",
      id: "0302473180",
      "font-weight": "500",
    },
  },
  box5: {
    kind: "Box",
    props: {
      display: "flex",
      "align-items": "center",
      margin: "0px 0px 32px 0px",
    },
  },
  input3: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      width: "100%",
      margin: "0px 0px 0px 0px",
      height: "60px",
      "border-width": "0 0 1px 0",
      "border-style": "solid",
      background: "--color-primary",
      placeholder: "Your Message",
      color: "black",
      id: "2426214357",
      "font-weight": "500",
    },
  },
  btn: {
    kind: "Btn",
    props: {
      width: "100%",
      height: "max-content",
      padding: "24px 24px 24px 24px",
      "sm-font": "--bt2",
      "md-padding": "16px 24px 16px 24px",
      "sm-align-items": "center",
      "sm-justify-content": "center",
      className: "hoverText  skinButton",
      id: "5748094947",
    },
  },
};

const ContactUs = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        color="#ffffff"
        margin="60px auto 60px auto"
        sm-margin="16px auto 16px auto"
      />
      <Box {...override("box")}>
        <Box {...override("box1")}>
          <Fade bottom>
            <Text {...override("text")} />
          </Fade>
        </Box>
        <Box {...override("box2")}>
          <Fade bottom>
            <Text {...override("text1")} />
          </Fade>
          <Box {...override("box3")}>
            <Input {...override("input")} />
          </Box>
          <Box {...override("box4")}>
            <Input {...override("input1")} />
            <Input {...override("input2")} />
          </Box>
          <Box {...override("box5")}>
            <Input {...override("input3")} />
          </Box>
          <Btn className="text-white" {...override("btn")} />
        </Box>
      </Box>
      {children}
    </Section>
  );
};

Object.assign(ContactUs, { ...Section, defaultProps, overrides });
export default ContactUs;
