import React, { useEffect, useState } from "react";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import theme from "theme";
import { getAllMenu } from "redux/Menu/menu-actions";

import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import {
  getAllBlogs,
  getAllCategory,
  addSingleBlog,
} from "redux/Blogs/blogs-actions";
import { getCms, setSelectedData } from "redux/CMS/cms-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";

export default () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  let data = useSelector((state) => state.cms.pages["Privacy Policy"]);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let userData = useSelector((state) => state.cms.userData);
  let menuData = useSelector((state) => state.menu.menu);
  const [openDialog, handleDisplay] = useState(false);

  let blogData = useSelector((state) => state.BlogDetails.Blog);
  let latestBlog = blogData.reverse();
  const [blog, setBlog] = useState(blogData);
  latestBlog.slice(0, 2);
  let blogCategory = useSelector((state) => state.BlogDetails.Category);
  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getAllCategory());

    // dispatch(getCms());
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );

    setMounted(true);
    console.log("ss", blog);
  }, [dispatch, setMounted]);
  console.log("blogData", blogData);
  console.log("getAllCategory", blogCategory);
  console.log(latestBlog, "latestblog");
  function showSingleBlog(data) {
    console.log("helloiam", data);
    dispatch(addSingleBlog(data));
  }

  const filterCategoryProduct = async (categoryId) => {
    // console.log(categoryId, "catr");
    // let blogFilterData = blogData.filter(
    //   (blog) => blog.categoryID._id === categoryId
    // );
    // console.log(blog, "checkfilter");
    // console.log(blogFilterData);
    // setBlog(blogFilterData);
    let categoryID = [];
    categoryID.push(categoryId);
    await dispatch(getAllBlogs({ categoryID: categoryID }));
  };

  const clearFilterBlog = async () => {
    await dispatch(getAllBlogs());
  };

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  return (
    // <div className="w-full height-privacy bg-dark relative">
    //   <img
    //     src="https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/dummy1.jpg"
    //     alt="bannerImage"
    //     className="w-full height-privacy filter-b6 z-20"
    //   />
    //   <div className="absolute top-0 flex left-0 right-0 w-full height-privacy items-center justify-center">
    //     <div className="relative z-50">
    //       <h1 className="text-white fsize32 font-semibold hoverText">
    //         Privacy Policy
    //       </h1>
    //     </div>
    //   </div>
    // </div>
    <Section
      background="--color-primary"
      position="relative"
      overflow-x="hidden"
      overflow-y="hidden"
    >
      <Override
        slot="SectionContent"
        margin="20px auto 56px auto"
        align-items="center"
        justify-content="center"
        width="100%"
        position="static"
      />
      <Box
        display="flex"
        justify-content="space-between"
        align-items="center"
        flex-direction="column"
        margin="90PX 0px 90px 0px"
        sm-margin=" 0px"
        width="60%"
        sm-width="100%"
        className="zindex999"
      >
        <Text
          margin="0px 0px 24px 0px"
          text-align="center"
          display="flex"
          flex-direction="column"
          color="--light"
          font="--headline3"
          md-font="--subheadlined1"
          sm-font="--subheadlined2"
          className="hoverText"
          id="6324549552"
          onClick={() => isSideBarOpen("6324549552")}
        >
          {data ? data["6324549552"] : "No data"}
        </Text>
      </Box>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};
