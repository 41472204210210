import React, { useEffect, useState } from "react";
import Reveal from "react-reveal/Reveal";

import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Icon,
  Image,
  Section,
  Hr,
  LinkBox,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { setSelectedData } from "redux/CMS/cms-actions";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import ContentUpdate from "components/CMS/ContentUpdate";

export default () => {
  let { pathname } = useLocation();
  const [navabar, setnavbar] = useState(true);
  const setclick = () => setnavbar(false);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  // const location = useLocation();
  // const { override, children, rest } = useOverrides(
  //   props,
  //   overrides,
  //   defaultProps
  // );

  let data = useSelector((state) => state.cms.pages["Footer"]);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  // console.log(project, "brand");

  let cmsStatus = useSelector((state) => state.cms.cmsActive);
  useEffect(() => {
    // console.log("Header");
    async function mount() {
      // await dispatch(getProject());
      // await dispatch(getUser({ projectId: project._id }));
      // await dispatch(getProject());
      // await dispatch(
      //   getBrandProfile({
      //     projectId: project._id,
      //     projectName: project.projectName,
      //   })
      // );
      // await dispatch(getCms());
      // await dispatch(getAllProduct());
    }
    mount();
  }, [dispatch]);

  // const renderButton = (props, ref) => {
  //   return (
  //     <button {...props} ref={ref} className="flex ite">
  //       <FeatherIcon icon="user" className="textblack " size="20px" />
  //       <FeatherIcon icon="chevron-down" className="textblack " size="20px" />
  //     </button>
  //   );
  // };
  const { token } = useParams();

  if (cmsStatus) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStylefooter");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  async function isSideBarOpen(id) {
    if (cmsStatus) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }
  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function getImages(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondaryColor",
        brandProfile.secondayColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-teritary",
        brandProfile.tertiaryColor
      );
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  applyThemeToDocument();
  if (window.location.pathname === "/login") return null;
  if (window.location.pathname === "/register") return null;
  if (window.location.pathname === "/forgot-password") return null;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        background="--color-dark"
        padding="15px 0 10px 0"
        sm-padding="10px 0 10px 0"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          color="--darkL1"
        />
        <Box
          empty-border-color="LightGray"
          justify-content="space-between"
          display="flex"
          sm-flex-direction="column"
          width="100%"
          align-items="center"
        >
          <div className="pt-8 pb-2 w-full">
            <Reveal>
              <div className="lg:flex md:flex">
                <div className="lg:w-1/2 md:w-1/2   sm-text-center">
                  <Image
                    max-width="171px"
                    margin="0px 0px 0px 0px"
                    src={getImages(brandProfile)}
                  />
                  {/* <div className="mt-6 lg:px-0 md:px-0 px-20 flex justify-start">
                    <LinkBox href="/home1">
                      <SocialMedia
                        facebook="https://www.facebook.com/quarklyapp/"
                        twitter="https://twitter.com/quarklyapp"
                        telegram="https://t.me/joinchat/DqSYDhS0R9nMRvOtFbIxrQ"
                        instagram="https://www.instagram.com/instagram/"
                        align-items="flex-start"
                        justify-content="flex-start"
                      >
                        <Override
                          slot="link"
                          lg-margin="0 12px 0 0px"
                          hover-background="--color-indigo"
                          background="--color-greyD1"
                          lg-height="28px"
                          sm-margin="0px 5px"
                          lg-padding="4px 4px 4px 4px"
                          lg-display="flex"
                          lg-align-items="center"
                          border-radius="50%"
                          lg-justify-content="center"
                          lg-width="28px"
                          color="--light"
                          width="40px"
                          height="40px"
                        >
                          <div display="none !important" />
                        </Override>
                        <Override slot="icon" lg-font="18px sans-serif" />
                        <Override slot="link-telegram">
                          <div display="none !important" />
                        </Override>
                      </SocialMedia>
                    </LinkBox>
                  </div> */}
                </div>

                <div className=" lg:w-1/2 md:w-1/2 lg:pt-0 md:pt-0 pt-4 ">
                  <p
                    className="text-gray-400 hoverText"
                    onClick={() => isSideBarOpen("5483978407")}
                    id="5483978407"
                  >
                    {data
                      ? data["5483978407"]
                      : "sssLorem ipsum dolor sit amet consectetur adipisicing elit Quidem Lorem ipsum dolor sit amet."}
                    {/* sssLorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quidem Lorem ipsum dolor sit amet. */}
                  </p>
                  <div className="mt-4 lg:flex md:flex gap-12">
                    <div className="flex gap-3 lg:mb-0 md:mb-0 mb-4 items-start ">
                      <div
                        onClick={() => isSideBarOpen("2711793455")}
                        className="hoverText"
                        id="2711793455"
                      >
                        <img
                          src={
                            data
                              ? data["2711793455"]
                              : "https://nimbuscluster.blob.core.windows.net/server01/portfolioblogs/images/mail.svg"
                          }
                          // src="https://nimbuscluster.blob.core.windows.net/server01/portfolioblogs/images/mail.svg"
                          alt="Call"
                          className="lg:w-12 md:w-12 w-10"
                        />
                      </div>
                      <div>
                        <p
                          className="text-gray-400 hoverText"
                          onClick={() => isSideBarOpen("3603737724")}
                          id="3603737724"
                        >
                          {data ? data["3603737724"] : "Have a question?"}
                          {/* Have a question? */}
                        </p>
                        <p
                          className="fsize18 sm-fsize14 hoverText"
                          onClick={() => isSideBarOpen("3031455710")}
                          id="3031455710"
                        >
                          <a href="tel:8928795300">
                            {data ? data["3031455710"] : "8928795300"}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-start  ">
                      <div
                        onClick={() => isSideBarOpen("0917506654")}
                        id="0917506654"
                        className="hoverText"
                      >
                        <img
                          src={
                            data
                              ? data["0917506654"]
                              : "https://nimbuscluster.blob.core.windows.net/server01/portfolioblogs/images/call.svg"
                          }
                          // src="https://nimbuscluster.blob.core.windows.net/server01/portfolioblogs/images/call.svg"
                          alt="mail"
                          className="lg:w-12 md:w-12 w-10"
                        />
                      </div>

                      <div>
                        <p
                          className="text-gray-400 hoverText"
                          onClick={() => isSideBarOpen("5205749587")}
                          id="5205749587"
                        >
                          {data ? data["5205749587"] : "Contact us at"}
                          {/* Contact us at */}
                        </p>
                        <p
                          className="fsize18 sm-fsize14 hoverText"
                          onClick={() => isSideBarOpen("3036214042")}
                          id="3036214042"
                        >
                          <a href="mailto:helpdesk@magitt.com">
                            {data ? data["3036214042"] : "helpdesk@magitt.com"}
                          </a>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center w-full mt-8 pt-3 border-topgrey ">
                <div>
                  {project.poweredBy ? (
                    <div className="">
                      <p className="fsize14 ">powered by Brained</p>
                    </div>
                  ) : (
                    <div className=" ">
                      <p className="fsize14  ">@2023, All rights reserved</p>
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-4">
                  <Link href="privacy">
                    <p className="fsize14 ">Privacy Policy</p>
                  </Link>
                  <Link href="terms">
                    <p className="fsize14 ">Terms & Conditions</p>
                  </Link>
                </div>
              </div>
            </Reveal>
          </div>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
