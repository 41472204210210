import React from "react";
import Banner from "./components/Banner";
import TermsText from "./components/TermsText";

const Terms = () => {
  return (
    <div>
      <Banner />
      <TermsText />
    </div>
  );
};

export default Terms;
