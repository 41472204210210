import React, { useEffect } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import theme from "theme/index2";
import {
  Theme,
  Image,
  Link,
  Box,
  Button,
  Icon,
  Section,
  Text,
  Hr,
  Span,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { MdPlayCircleFilled } from "react-icons/md";
import Brands from "./components/Brands";
import Portfolio2 from "components/portfolio/Portfolio2";
import Testimonial2 from "components/testimonial/Testimonial2";
import Team2 from "components/team/Team2";
import { getAllMenu } from "redux/Menu/menu-actions";
import Fade from "react-reveal/Fade";

import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import Subscribe from "./components/Subscribe";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  const handleClick = () => {
    const element = document.getElementById("contactpage");
    element.scrollIntoView();
  };

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home-2</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        position="relative"
        padding="20px 60px 180px 60px"
        sm-padding="80px 20px 90px 20px"
        align-items="center"
        justify-content="center"
        background="--color-primary"
        quarkly-title="Hero Section"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 24px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
          flex-direction="column"
          md-flex-direction="column"
          sm-align-items="flex-start"
          sm-margin="0px auto 0px auto"
          md-height="fit-content"
        />
        <Reveal>
          <Box
            display="flex"
            justify-content="space-between"
            align-items="center"
            flex-direction="column"
            md-width="100%"
            sm-margin="20PX 0px 24px 0px"
            width="100%"
            lg-width="100%"
            md-margin="0px 0px 0px 0px"
            margin="20px 0px 20px 0px"
            lg-margin="60px 0px 20px 0px"
          >
            <Text
              font="--poppin72B"
              text-align="left"
              display="flex"
              flex-direction="column"
              color="#fff"
              sm-width="90%"
              sm-font="--subheadlined2"
              md-font="--headline4"
              className="hoverText"
              align-items="center"
              width="100%"
              align-content="flex-end"
              sm-margin="0px 0px 8px 0px"
              lg-text-align="center"
              id="8922600886"
              onClick={() => isSideBarOpen("8922600886")}
            >
              {data ? data["8922600886"] : "no data"}
            </Text>

            <Text
              margin="0px 0px 30px 0px"
              text-align="center"
              display="flex"
              justify-content="center"
              font="--p1"
              color="#fff"
              sm-font="--p5"
              lg-font="--p2"
              md-font="--p3"
              className="hoverText sm-textwidth"
              align-items="flex-start"
              width="100%"
              sm-width="100%"
              lg-text-align="center"
              lg-width="80%"
              line-height="28px"
              id="8978450592"
              dangerouslySetInnerHTML={{
                __html: data ? data["8978450592"] : "no data",
              }}
              onClick={() => isSideBarOpen("8978450592")}
            ></Text>
          </Box>
        </Reveal>
        {/* <Reveal> */}
        <Box
          align-items="center"
          display="flex"
          justify-content="center"
          position="relative"
          sm-height="260px"
          md-width="100%"
          md-padding="0px 0px 0px 0px"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          width="100%"
          md-margin="0px 0px 0px 0px"
          border-width="0px"
          lg-height="400px"
          className="hoverText"
          id="9399343161"
          onClick={() => isSideBarOpen("9399343161")}
        >
          <Image
            display="block"
            height="400px"
            object-fit="fill"
            border-radius="40px"
            sm-height="100%"
            md-width="100%"
            className=" w-10/12 h-80 "
            md-height="360px"
            sm-border-radius="20px"
            src={
              data
                ? data["9399343161"]
                : "https://nimbuscluster.blob.core.windows.net/server01/digital-agency/cms/default2.jpg-vTMGz4RUpEI_d4KtybVGZ-1686316156"
            }
            border-width="6px"
            border-style="solid"
            border-color="--color-darkL1"
          />
        </Box>
        {/* </Reveal> */}
      </Section>
      <Section
        sm-align-items="center"
        sm-justify-content="center"
        sm-padding="22px 0 8px 0"
        align-items="center"
        justify-content="center"
        height="auto"
        quarkly-title="About Section"
        padding="150px 0 24px 0"
        position="relative"
        lg-padding="130px 0 24px 0"
        md-padding="100px 0 24px 0"
      >
        <Override
          slot="SectionContent"
          margin="40px 80px 40px 80px"
          lg-margin="40px auto 40px auto"
          sm-margin="20px auto 16px auto"
          md-margin="40px auto 0px auto"
        />
        <Section
          sm-height="max-content"
          sm-padding="8px 0 8px 0"
          align-items="center"
          justify-content="center"
          quarkly-title="Client Section"
          position="absolute"
          background="--color-light"
          padding="40px 0 40px 0"
          box-shadow="0 0px 25px 10px rgba(0, 0, 0, 0.1)"
          width="90%"
          sm-width="100%"
          right="auto"
          top="-70px"
          bottom="auto"
          left="72px"
          height="160px"
          border-radius="8px"
          lg-bottom="auto"
          lg-left="auto"
          lg-right="49px"
          lg-top="-49px"
          lg-padding="20px 0 20px 0"
          lg-height="140px"
          md-height="120px"
          sm-left="0px"
        >
          <Override slot="SectionContent" margin="0px auto 0px auto" />
          <Brands />
        </Section>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
          align-items="center"
          justify-content="center"
          flex-direction="row-reverse"
        >
          <Box
            width="50%"
            margin="0px 40px 0px 0px"
            padding="0px 60PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            sm-width="100%"
            sm-padding="0px 0 0px 0px"
            sm-margin="80px 0px 24px 0px"
            lg-padding="0px 0px 0px 0px"
            lg-height="max-content"
            lg-width="50%"
            md-justify-content="space-between"
            md-margin="0px 20px 0px 0px"
          >
            <Fade bottom>
              <div className="flex items-end">
                <Text
                  margin="0px 0px 8px 0px"
                  font="--subheadlined3"
                  letter-spacing="4PX"
                  color="--primary"
                  sm-font="--subheadlined4"
                  lg-margin="0px 0px 8px 0px"
                  md-font="--subheadlined4"
                  lg-font="--subheadlined4"
                  className="hoverText"
                  id="1632289585"
                  onClick={() => isSideBarOpen("1632289585")}
                >
                  {data ? data["1632289585"] : "no data"}
                  {/* About Us */}
                </Text>
                {/* <Hr
                width="150px"
                height="1px"
                margin="0px 0px 0px 0px"
                border-color="--color-darkL2"
              /> */}
              </div>
              <Text
                margin="0px 0px 16px 0px"
                font="--headline4"
                sm-font="--subheadlined2"
                sm-margin="0px 0px 16px 0px"
                lg-margin="0px 0px 16px 0px"
                md-font="--subheadlined2"
                md-margin="0px 0px 24px 0px"
                lg-font="--subheadlined2"
                className="hoverText sm-fsize24"
                id="7689255120"
                onClick={() => isSideBarOpen("7689255120")}
              >
                {data ? data["7689255120"] : "no data"}
                {/* One Group, <br />
              Many Disciplines */}
              </Text>
              <Text
                margin="0px 0px 24px 0px"
                font="--p2"
                sm-font="--p4"
                lg-font="--p4"
                md-margin="0px 0px 24px 0px"
                className="hoverText"
                id="3313680024"
                dangerouslySetInnerHTML={{
                  __html: data ? data["3313680024"] : "no data",
                }}
                onClick={() => isSideBarOpen("3313680024")}
              >
                {/* We started in 2003, with a very humble start but with great
              determination to help the clients GROW. With our passion &
              dedication and support from the customers, we were able to witness
              growth for both the client and us, and carve out a brand value
              within.
              <br />
              dEEVOiR is a management consulting firm providing end to end
              solutions to clienteles across industries. */}
              </Text>
              <Components.Btn
                className="hoverText skinButton brad-4"
                id="0412288477"
              />
            </Fade>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            height="max-content"
            sm-width="100%"
            // lg-border-width="8px 0 0  0"
            lg-border-style="solid"
            lg-border-color="--color-secondary"
            lg-height="450px"
            border-radius="10px"
            padding="0px 60px 0px 60px"
            sm-padding="0px 0px 0px 0px"
          >
            <Box
              className="hoverText"
              id="4186580645"
              onClick={() => isSideBarOpen("4186580645")}
            >
              <Fade bottom>
                <Image
                  src={
                    data
                      ? data["4186580645"]
                      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  }
                  display="block"
                  width="100%"
                  height="578px"
                  object-fit="cover"
                  border-radius="10px"
                  sm-height="450px"
                  sm-width="100%"
                  lg-height="100%"
                />
              </Fade>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        sm-align-items="flex-end"
        align-items="center"
        justify-content="center"
        padding="60px 0 60px 0"
        sm-padding="28px 0 28px 0"
        quarkly-title="Service Section"
        lg-padding="30px 0 60px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          align-items="center"
          justify-content="center"
        />
        <Box
          display="flex"
          flex-direction="column"
          md-margin="0px 0px 20px 0px"
          width="100%"
          margin="20px 0px 20px 0px"
          padding="20px 28px 20px 28px"
          lg-padding="0 28px 20px 28px"
          md-padding="0 28px 20px 0px"
          align-items="center"
        >
          <Reveal>
            <Box
              display="flex"
              flex-direction="column"
              align-items="center"
              margin="0px 0px 40px 0px"
              md-margin="0px 0px 40px 0px"
            >
              <Text
                margin="0px 0px 16px 0px"
                font="--subheadlined3"
                letter-spacing="4PX"
                color="--primary"
                sm-font="--subheadlined5"
                sm-letter-spacing="1px"
                md-font="--subheadlined4"
                md-margin="0px 0px 8px 0px"
                lg-font="--subheadlined4"
                className="hoverText"
                id="0633521212"
                onClick={() => isSideBarOpen("0633521212")}
              >
                {data ? data["0633521212"] : "no data"}
                {/* Our Key Services */}
              </Text>
              <Text
                margin="0px 0px 24px 0px"
                font="--subheadlined1"
                sm-font="--subheadlined3"
                sm-text-align="center"
                md-margin="0px 0px 24px 0px"
                md-font="--subheadlined2"
                sm-margin="0px 0px 12px 0px"
                className="hoverText"
                id="3734049364"
                onClick={() => isSideBarOpen("3734049364")}
              >
                {data ? data["3734049364"] : "no data"}
                {/* Industries What We Serve */}
              </Text>
              <Box
                display="flex"
                width="60%"
                sm-width="100%"
                md-width="80%"
                lg-width="80%"
                id="0629982000"
                className="hoverText"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  text-align="center"
                  sm-font="--p4"
                  lg-font="--p3"
                  lg-min-width="none"
                  lg-min-height="none"
                  className="hoverText"
                  id="0629982000"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["0629982000"] : "no data",
                  }}
                  onClick={() => isSideBarOpen("0629982000")}
                >
                  {/* We are boutique firm design for Private Equit, Family Offices
                and Growth - Stage Companies seeking to maximize results. */}
                </Text>
              </Box>
            </Box>
          </Reveal>
        </Box>
        <Box width="100%" display="flex" flex-direction="column">
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              background="--color-grey"
              border-color="--color-primary"
              box-shadow="inset 0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              justify-content="center"
              padding="24px 28px 24px 28px"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              width="100%"
              margin="0px 0px 24px 0px"
              sm-margin="0px 0px 15px 0px"
              align-items="center"
              height="120px"
              sm-height="auto"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              flex-direction="row"
              hover-border-color="--color-greyD1"
            >
              <div
                onClick={() => isSideBarOpen("8380951052")}
                className="hoverText"
                id="8380951052"
              >
                <Image
                  src={
                    data
                      ? data["8380951052"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  display="block"
                  color="--primary"
                  height="60px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  margin="0px 32px 0px 0px"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--darkL2"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("4459966185")}
                id="4459966185"
              >
                {data ? data["4459966185"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                color="--darkL2"
                className=" hoverText"
                id="2184136934"
                dangerouslySetInnerHTML={{
                  __html: data ? data["2184136934"] : "no data",
                }}
                onClick={() => isSideBarOpen("2184136934")}
              ></Text>
            </Box>
          </Fade>
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              sm-height="auto"
              background="#ffffff"
              border-color="--color-primary"
              box-shadow="0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              padding="24px 28px 24px 28px"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              justify-content="center"
              width="100%"
              margin="0px 0px 24px 0px"
              sm-margin="0px 0px 15px 0px"
              align-items="center"
              height="120px"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              flex-direction="row"
            >
              <div
                onClick={() => isSideBarOpen("9338462607")}
                className="hoverText"
                id="9338462607"
              >
                <Image
                  src={
                    data
                      ? data["9338462607"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20485.png?v=2023-06-08T07:03:21.205Z"
                  }
                  display="block"
                  color="--primary"
                  height="60px"
                  margin="0px 32px 0px 0px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--dark"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("7573397008")}
                id="7573397008"
              >
                {data ? data["7573397008"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                className=" hoverText"
                id="7999605823"
                dangerouslySetInnerHTML={{
                  __html: data ? data["7999605823"] : "no data",
                }}
                onClick={() => isSideBarOpen("7999605823")}
              ></Text>
            </Box>
          </Fade>
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              sm-height="auto"
              background="--color-grey"
              border-color="--color-primary"
              box-shadow="inset 0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              padding="24px 28px 24px 28px"
              justify-content="center"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              grid-row="3"
              grid-column="1"
              width="100%"
              margin="0px 0px 24px 0px"
              sm-margin="0px 0px 15px 0px"
              align-items="center"
              height="120px"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              flex-direction="row"
              hover-border-color="--color-greyD1"
            >
              <div
                onClick={() => isSideBarOpen("5127224140")}
                className="hoverText"
                id="5127224140"
              >
                <Image
                  src={
                    data
                      ? data["5127224140"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20486.png?v=2023-06-08T07:03:21.208Z"
                  }
                  display="block"
                  color="--primary"
                  height="60px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  margin="0px 32px 0px 0px"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--darkL2"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("5994371340")}
                id="5994371340"
              >
                {data ? data["5994371340"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                color="--darkL2"
                className=" hoverText"
                id="8506712069"
                dangerouslySetInnerHTML={{
                  __html: data ? data["8506712069"] : "no data",
                }}
                onClick={() => isSideBarOpen("8506712069")}
              ></Text>
            </Box>
          </Fade>
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              sm-height="auto"
              background="--color-darkL1"
              border-color="--color-primary"
              box-shadow="0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              padding="24px 28px 24px 28px"
              justify-content="center"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              width="100%"
              margin="0px 0px 24px 0px"
              sm-margin="0px 0px 15px 0px"
              align-items="center"
              height="120px"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              flex-direction="row"
            >
              <div
                onClick={() => isSideBarOpen("5378513421")}
                className="hoverText"
                id="5378513421"
              >
                <Image
                  src={
                    data
                      ? data["5378513421"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  display="block"
                  margin="0px 32px 0px 0px"
                  color="--primary"
                  height="60px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--dark"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("2932463085")}
                id="2932463085"
              >
                {data ? data["2932463085"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                className=" hoverText"
                id="6460377051"
                dangerouslySetInnerHTML={{
                  __html: data ? data["6460377051"] : "no data",
                }}
                onClick={() => isSideBarOpen("6460377051")}
              ></Text>
            </Box>
          </Fade>
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              sm-height="auto"
              background="--color-grey"
              border-color="--color-primary"
              box-shadow="inset 0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              padding="24px 28px 24px 28px"
              justify-content="center"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              grid-row="3"
              grid-column="2"
              width="100%"
              align-items="center"
              height="120px"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              margin="0px 0px 24px 0px"
              sm-margin="0px 0px 15px 0px"
              flex-direction="row"
              hover-border-color="--color-greyD1"
            >
              <div
                onClick={() => isSideBarOpen("1540216442")}
                className="hoverText"
                id="1540216442"
              >
                <Image
                  src={
                    data
                      ? data["1540216442"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20485.png?v=2023-06-08T07:03:21.205Z"
                  }
                  display="block"
                  color="--primary"
                  height="60px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  margin="0px 32px 0px 0px"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--darkL2"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("4178111992")}
                id="4178111992"
              >
                {data ? data["4178111992"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                color="--darkL2"
                className=" hoverText"
                id="8216480916"
                dangerouslySetInnerHTML={{
                  __html: data ? data["8216480916"] : "no data",
                }}
                onClick={() => isSideBarOpen("8216480916")}
              ></Text>
            </Box>
          </Fade>
          <Fade bottom>
            <Box
              display="flex"
              sm-display="block"
              sm-height="auto"
              background="--color-darkL1"
              border-color="--color-primary"
              box-shadow="0 0px 10px 4px rgba(0, 0, 0, 0.1)"
              href="/index11"
              padding="24px 28px 24px 28px"
              justify-content="center"
              sm-padding="18px 16px 18px 16px"
              sm-justify-content="center"
              sm-align-items="center"
              grid-column="3"
              grid-row="3"
              width="100%"
              align-items="center"
              height="120px"
              border-radius="8px"
              hover-border-width="0px 0px 0px 10px"
              hover-border-style="solid"
              flex-direction="row"
            >
              <div
                onClick={() => isSideBarOpen("9041677121")}
                className="hoverText"
                id="9041677121"
              >
                <Image
                  src={
                    data
                      ? data["9041677121"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20486.png?v=2023-06-08T07:03:21.208Z"
                  }
                  display="block"
                  margin="0px 32px 0px 0px"
                  color="--primary"
                  height="60px"
                  width="60px"
                  padding="10px 10px 10px 10px"
                  border-radius="100px"
                  background="--color-lightD1"
                  flex="0 0 auto"
                  sm-margin="0px 16px 0px 0px"
                />
              </div>
              <Text
                margin="0px 10px 0px 0px"
                font="--subheadlined3"
                color="--dark"
                sm-font="--subheadlined4"
                md-font="--subheadlined4"
                width="60%"
                className="hoverText"
                onClick={() => isSideBarOpen("1457927004")}
                id="1457927004"
              >
                {data ? data["1457927004"] : "no data"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--p2"
                sm-font="--p5"
                md-font="--p3"
                lg-font="--p4"
                className=" hoverText"
                id="4126878104"
                dangerouslySetInnerHTML={{
                  __html: data ? data["4126878104"] : "no data",
                }}
                onClick={() => isSideBarOpen("4126878104")}
              ></Text>
            </Box>
          </Fade>
        </Box>
      </Section>
      <Portfolio2 />
      <Testimonial2 />
      <Team2 />
      {/* <Subscribe /> */}

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
