import axios from "axios";
export const getCms = (payload) => {
  return (dispatch) => {
    try {
      // console.log("slice start");
      axios
        .post(`${process.env.REACT_APP_SERVICE_BASE_URL}cms/getClientCms`, {
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
        .then((response) => {
          // console.log(response.data.data);
          let data = response.data.data;
          let newData = {};
          for (let i = 0; i < data.length; i++) {
            let interaldata = {};
            if (!newData.hasOwnProperty(data[i].pageName)) {
              if (data[i].contentType === "Image")
                interaldata[data[i].fieldId] =
                  process.env.REACT_APP_STORAGE_URL + data[i].content;
              else if (data[i].contentType === "Cards")
                interaldata[data[i].fieldId] = data[i].loop;
              else interaldata[data[i].fieldId] = data[i].content;
              newData[data[i].pageName] = interaldata;
            } else {
              if (data[i].contentType === "Image")
                interaldata[data[i].fieldId] =
                  process.env.REACT_APP_STORAGE_URL + data[i].content;
              else if (data[i].contentType === "Cards")
                interaldata[data[i].fieldId] = data[i].loop;
              else interaldata[data[i].fieldId] = data[i].content;
              Object.assign(newData[data[i].pageName], interaldata);
            }
          }
          // console.log(newData, data);
          dispatch(getCms_reducer(newData));
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getElementById = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      console.log("get element by id", payload);
      await axios
        .post(`${process.env.REACT_APP_SERVICE_BASE_URL}cms/getClientCms`, {
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
          elementId: payload,
        })
        .then(async (response) => {
          record = response.data.data;
          // await dispatch(setSelectedData(response.data.data[0]));
        });
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const updateCms = (payload) => {
  return async (dispatch) => {
    try {
      console.log("update action", payload);
      let newPayload;
      if (payload.contentType === "Image") {
        let formData = new FormData();
        formData.set("contentImage", payload.image);
        formData.set("_id", payload._id);
        formData.set("path", payload.path);
        formData.set("projectName", payload.projectName);
        newPayload = formData;
        await axios
          .post(
            `${process.env.REACT_APP_SERVICE_BASE_URL}cms/updateClient`,
            newPayload,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                projectName: process.env.REACT_APP_COMMUNITY_NAME,
              },
            }
          )
          .then(async (response) => {
            await dispatch(getCms());
          });
      } else {
        newPayload = payload;
        await axios
          .post(
            `${process.env.REACT_APP_SERVICE_BASE_URL}cms/updateClient`,
            newPayload,
            {
              headers: {
                projectName: process.env.REACT_APP_COMMUNITY_NAME,
              },
            }
          )
          .then(async (response) => {
            await dispatch(getCms());
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const uploadImage = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let newPayload;

      let formData = new FormData();
      formData.set("files", payload.image);

      formData.set("path", payload.path);

      await axios
        .post(`${process.env.REACT_APP_BASE_URL}upload/helper`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            projectName: process.env.REACT_APP_COMMUNITY_NAME,
          },
        })
        .then(async (response) => {
          record = response.data.data;
        });
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getUser = (payload) => {
  return async (dispatch) => {
    try {
      console.log("get user action");

      let payload = {
        project: ["63f08ae168bd188782a32f1b"],
      };

      await axios
        .post(`${process.env.REACT_APP_BASE_URL}project/getUser`, payload)
        .then(async (response) => {
          console.log(response, "user res");
          await dispatch(setUserData(response.data.data.data));
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCms_reducer = (data) => {
  return {
    type: "getcms",
    payload: data,
  };
};

export const setSelectedData = (data) => {
  return {
    type: "setSelectedData",
    payload: data,
  };
};

export const updateStore = (data) => {
  return {
    type: "updateStore",
    payload: data,
  };
};

export const setUserData = (data) => {
  return {
    type: "setUserData",
    payload: data,
  };
};

export const setCmsActive = (data) => {
  // console.log(data, "action cms");
  return {
    type: "setCmsActive",
    payload: data,
  };
};
