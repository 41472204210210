import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Image, Box, Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-primary",
	"padding": "80px 0 20px 0",
	"md-padding": "40px 0 40px 0",
	"align-items": "center",
	"justify-content": "center"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"display": "flex",
			"flex-direction": "row",
			"justify-content": "space-between"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"empty-border-color": "LightGray",
			"flex-direction": "column",
			"md-justify-content": "center",
			"display": "flex",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"md-align-items": "center",
			"justify-content": "flex-start"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"display": "block",
			"height": "120px",
			"width": "120px",
			"src": "https://uploads.quarkly.io/64523a64bf72b300207ea407/images/logo.png?v=2023-05-05T13:58:38.684Z",
			"object-fit": "contain",
			"filter": "invert(0)",
			"margin": "0px 0px 10px 42px"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"facebook": "https://www.facebook.com/quarklyapp/",
			"twitter": "https://twitter.com/quarklyapp",
			"telegram": "https://t.me/joinchat/DqSYDhS0R9nMRvOtFbIxrQ",
			"instagram": "https://www.instagram.com/instagram/",
			"align-items": "flex-start",
			"justify-content": "flex-start"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"margin": "0 12px 0 0px",
			"hover-background": "--color-indigo",
			"lg-height": "28px",
			"lg-padding": "4px 4px 4px 4px",
			"lg-display": "flex",
			"lg-align-items": "center",
			"border-radius": "50%",
			"lg-justify-content": "center",
			"lg-width": "28px",
			"width": "40px",
			"height": "40px",
			"children": <div display="none !important" />,
			"color": "--primary",
			"background": "--color-darkL1"
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"lg-font": "18px sans-serif"
		}
	},
	"socialMediaOverride2": {
		"kind": "Override",
		"props": {
			"slot": "link-telegram",
			"children": <div display="none !important" />
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "fit-content"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"width": "220px",
			"display": "flex",
			"md-width": "100%",
			"md-justify-content": "center",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"flex-direction": "column",
			"padding": "16px 16px 16px 16px",
			"md-align-items": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--white",
			"font": "--subheadlined4",
			"margin": "0px 0px 20px 0px",
			"sm-font": "--subheadlined4",
			"children": "Navigate To"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"opacity": ".8",
			"sm-font": "--p4",
			"children": "Home"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "Services"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "Portfolio"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "About Us"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"href": "#",
			"sm-font": "--p4",
			"children": "Contact"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"display": "flex",
			"flex-direction": "column",
			"md-justify-content": "center",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"md-align-items": "center",
			"empty-border-width": "1px",
			"width": "220px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "--white",
			"font": "--subheadlined4",
			"margin": "0px 0px 20px 0px",
			"sm-font": "--subheadlined4",
			"children": "Services"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "SEO"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"opacity": ".8",
			"sm-font": "--p4",
			"children": "Email Marketing"
		}
	},
	"link7": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"sm-font": "--p4",
			"children": "Google ads"
		}
	},
	"link8": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"sm-font": "--p4",
			"children": "Mobile Marketing"
		}
	},
	"link9": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "Social Media Marketing"
		}
	},
	"link10": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"display": "flex",
			"flex-direction": "column",
			"md-justify-content": "center",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"padding": "46px 16px 16px 16px",
			"md-width": "100%",
			"md-align-items": "center",
			"empty-border-width": "1px",
			"width": "220px"
		}
	},
	"link11": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "12px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": "Content Marketing"
		}
	},
	"link12": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"opacity": ".8",
			"sm-font": "--p4",
			"children": "Ecommerce Marketing"
		}
	},
	"link13": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"sm-font": "--p4",
			"children": "SEM Service"
		}
	},
	"link14": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"sm-font": "--p4",
			"children": "Pay Per Click"
		}
	},
	"link15": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": <>
				Influencer Marketing{" "}
			</>
		}
	},
	"link16": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4"
		}
	},
	"link17": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--p4",
			"href": "#",
			"color": "--white",
			"sm-font": "--p4",
			"children": <>
				© 2023, DigiChefs. All Rights Reserved.{"\n\n"}
			</>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="column"
			md-flex-wrap="wrap"
			color="--darkL1"
			justify-content="space-between"
			align-items="center"
			height="340px"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Image {...override("image")} />
				<SocialMedia {...override("socialMedia")}>
					<Override {...override("socialMediaOverride")} />
					<Override {...override("socialMediaOverride1")} />
					<Override {...override("socialMediaOverride2")} />
				</SocialMedia>
			</Box>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text")} />
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
					<Link {...override("link4")} />
				</Box>
				<Box {...override("box4")}>
					<Text {...override("text1")} />
					<Link {...override("link5")} />
					<Link {...override("link6")} />
					<Link {...override("link7")} />
					<Link {...override("link8")} />
					<Link {...override("link9")} />
					<Link {...override("link10")} />
				</Box>
				<Box {...override("box5")}>
					<Link {...override("link11")} />
					<Link {...override("link12")} />
					<Link {...override("link13")} />
					<Link {...override("link14")} />
					<Link {...override("link15")} />
					<Link {...override("link16")} />
				</Box>
			</Box>
		</Box>
		<Link {...override("link17")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;