import API from "./index";

const Login = "login";
const Signup = "signup";
const Logout = "logout";
const CreateNewPassword = "createNewPassword";
const Update = "update";
const GenerateOTP = "generateOTP";
const VerifyOTP = "verifyOTP";
const ForgetPassword = "forgotPassword";

const auth = (route) => {
  return `auth/${route}`;
};

export default {
  loginwithPassword(payload) {
    return API.post(auth(Login), payload);
  },

  signupWithPassword(payload) {
    return API.post(auth(Signup), payload);
  },

  logout(payload) {
    return API.post(auth(Logout), payload);
  },

  createPassword(payload) {
    return API.post(auth(CreateNewPassword), payload);
  },
  updateImage(payload) {
    return API.post(auth(Update), payload);
  },

  generateOTP(payload) {
    return API.post(auth(GenerateOTP), payload);
  },

  verifyOTP(payload) {
    return API.post(auth(VerifyOTP), payload);
  },

  forgetPassword(payload) {
    return API.post(auth(ForgetPassword), payload);
  },
};
