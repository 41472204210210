import React, { useState, useEffect } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Section,
  Image,
  Hr,
  Icon,
  Input,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { createContact } from "redux/marcom/marcom-action";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[^\s]+(\s[^\s]+)*[^\s]$/, "Invalid Format")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Full Name is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(6, "Enter valid number")
    .max(10, "Enter valid number"),
  email: Yup.string().email("Not Valid Email").required("Email id is required"),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Field cannot exceed 500 characters"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
);

export default () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formik.values.username.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      description: formik.values.message.trim(),
    };

    if (
      payload.name !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== ""
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Thankyou for reaching out",
          text: "Our team will connect with you shortly!",
          icon: "success",
        });
        console.log(data.code, "registerCode");
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all the required details",
        icon: "error",
      });
      console.log(data.message, "datamessage");
    }
  };
  const MapProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Contact Us"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    // dispatch(getBrandProfile());

    setMounted(true);
    // dispatch(
    //   getBrandProfile({
    //     projectId: project._id,
    //     projectName: project.projectName,
    //   })
    // );
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index111"} />
      <Helmet>
        <title>Contact Us</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        background="--color-primary"
        position="relative"
        z-index="0"
        overflow-x="hidden"
        overflow-y="hidden"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          margin="90px auto 90px auto"
          sm-margin="70px auto 70px auto"
          align-items="center"
          justify-content="center"
          width="100%"
          position="static"
        />
        <Box
          display="flex"
          justify-content="center"
          align-items="center"
          flex-direction="column"
          margin="PX 0px 0px 0px"
          width="60%"
          sm-width="100%"
        >
          <Text
            margin="0px 0px 0px 0px"
            font="--subheadlined4"
            color="--darkL1"
            padding="0px 0px 0 0px"
            letter-spacing="6px"
            sm-font="--subheadlined5"
            sm-margin="0px 0px 8px 0px"
            md-font="--subheadlined4"
            md-margin="0px 0px 8px 0px"
            id="4027500065"
            onClick={() => isSideBarOpen("4027500065")}
            className="hoverText"
          >
            {data ? data["4027500065"] : "no data"}
          </Text>
          <Text
            margin="0px 0px 24px 0px"
            font="--headline3"
            md-font="--subheadlined1"
            sm-font="--subheadlined2"
            sm-padding="0px 10px 0px 10px"
            text-align="center"
            display="flex"
            flex-direction="column"
            color="--light"
            align-items="center"
            className="hoverText"
            id="6501437680"
            onClick={() => isSideBarOpen("6501437680")}
          >
            {data ? data["6501437680"] : "no data"}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            text-align="center"
            display="flex"
            flex-direction="column"
            font="--p2"
            color="--light"
            md-font="--p4"
            sm-font="--p4"
            className="hoverText"
            id="7983065823"
            onClick={() => isSideBarOpen("7983065823")}
          >
            {data
              ? data["7983065823"]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique."}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique. */}
          </Text>
          <Box width="60%" text-align="center" />
        </Box>
        <Box
          width="1442.2222222222222px"
          position="absolute"
          top="auto"
          bottom="-344px"
          height="452.2083333333333px"
          left="auto"
          right="-498px"
          z-index="-1"
          transform="rotate(-10deg)"
          box-shadow="0 -40px 35px 0 rgba(0, 0, 0, 0.2)"
          border-width="2px"
          border-style="solid"
          border-color="#ffffff"
          display="none"
        />
        <Box
          width="1715.22px"
          position="absolute"
          top="-200px"
          bottom="auto"
          height="839px"
          left="-285px"
          right="auto"
          z-index="-2"
          transform="rotate(-45deg)"
          border-style="solid"
          border-color="--color-secondary"
        />
        <Box
          width="2042.72px"
          position="absolute"
          top="-235px"
          bottom="auto"
          height="839px"
          left="auto"
          right="-845px"
          z-index="-2"
          transform="rotate(45deg)"
          border-color="rgba(255, 255, 255, 0.18)"
          border-style="dashed"
        />
      </Section>
      <Section position="relative" display="none">
        <Override slot="SectionContent" margin="40px 80px 40px 80px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-between"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="45%"
            height="max-content"
          >
            <Box height="10PX" background="--color-secondary" />
            <Image
              src="https://images.unsplash.com/photo-1455894127589-22f75500213a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              object-fit="cover"
              height="578px"
              border-radius={0}
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 40PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="space-around"
            align-items="flex-start"
          >
            <Text margin="0px 0px 0px 0px" font="--subheadlined1">
              provide the right candidate with a hassle-free screening process{" "}
            </Text>
            <Box
              display="flex"
              width="100%"
              justify-content="space-between"
              align-items="flex-start"
            >
              <Box margin="0px 0px 0px 0px" padding="0px 0px 0px 0px">
                <Text
                  margin="0px 0px 32px 0px"
                  font="--subheadlined3"
                  color="--primary"
                  padding="0px 0px 0 0px"
                >
                  Our Promise
                </Text>
                <Hr
                  min-height="4px"
                  min-width="40%"
                  margin="0px 0px 0px 0px"
                  border-color="--color-secondary"
                  background="--color-secondary"
                  width="60%"
                />
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Mission
                </Text>
              </Box>
              <Box margin="0px 0px 0px 0px" padding="0px 0px 32px 0px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheadlined3"
                  color="--dark"
                >
                  Our Vision{" "}
                </Text>
              </Box>
            </Box>
            <Text margin="0px 0px 0px 0px" font="--p2">
              dEEVOiR promises to ensure robust performance and effectiveness in
              achieving greater possessions. We will support and allow the core
              HR outsourcing criteria and the effective outcome to be addressed.
            </Text>
            <Components.Btn />
          </Box>
        </Box>
      </Section>
      <Section
        background="--color-darkL1"
        align-items="center"
        flex-direction="column"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          color="--darkL2"
          margin="60px 80px 60px 80px"
          justify-content="center"
          align-items="center"
          sm-margin="24px 80px 24px 80px"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="100%"
          display="flex"
          justify-content="space-around"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            sm-width="100%"
            sm-padding="0px 0 0px 0px"
            sm-margin="0px 0px 24px 0px"
            text-align="left"
          >
            <Text
              margin="0px 0px 40px 0px"
              font="--subheadlined4"
              sm-font="--subheadlined5"
              sm-margin="0px 0px 16px 0px"
              md-font="--subheadlined4"
              className="hoverText lh-30px"
              id="7611967677"
              text-align="left"
              onClick={() => isSideBarOpen("7611967677")}
            >
              {data ? data["7611967677"] : "no data"}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(1, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
              >
                <Icon
                  category="md"
                  icon={MdPhone}
                  size="24px"
                  margin="0px 8px 0px 0px"
                  color="--secondary"
                  className=""
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  color="--greyD1"
                  text-align="left"
                  width="max-content"
                  sm-font="--p5"
                  md-font="--p3"
                  className="hoverText"
                  id="7005010153"
                  onClick={() => isSideBarOpen("7005010153")}
                >
                  {data ? data["7005010153"] : "no data"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="flex-end"
              >
                <Icon
                  category="md"
                  icon={MdEmail}
                  size="24px"
                  margin="0px 8px 0px 0px"
                  color="--secondary"
                  className=""
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  color="--greyD1"
                  text-align="left"
                  width="max-content"
                  sm-font="--p5"
                  md-font="--p3"
                  className="hoverText"
                  id="8546689365"
                  onClick={() => isSideBarOpen("8546689365")}
                >
                  {data ? data["8546689365"] : "no data"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="flex-start"
              >
                <Icon
                  category="md"
                  icon={MdLocationOn}
                  size="25px"
                  margin="0px 8px 0px 0px"
                  color="--secondary"
                  className=""
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--p2"
                  color="--greyD1"
                  text-align="left"
                  sm-font="--p5"
                  md-font="--p3"
                  className="hoverText"
                  id="9526554068"
                  onClick={() => isSideBarOpen("9526554068")}
                >
                  {data ? data["9526554068"] : "no data"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box width="60%" sm-width="100%">
            <form>
              <Box
                align-items="center"
                margin="0px 0px 40px 0px"
                sm-margin="0px 0px 16px 0px"
              >
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  width="100%"
                  margin="0px 0 0px 0px"
                  height="60px"
                  border-width="0 0 1px 0"
                  border-style="solid"
                  placeholder="Full Name*"
                  font-weight="500"
                  className="bordb2"
                  type="text"
                  id="username"
                  name="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // value={name}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username && (
                  <ErrorComponent error={formik.errors.username} />
                )}
              </Box>

              <Box
                display="flex"
                align-items="center"
                margin="0px 0px 32px 0px"
                sm-flex-direction="column"
                sm-margin="0px 0px 16px 0px"
                className="lg:gap-8 md:gap-8"
              >
                <Box width="50%" sm-width="100%">
                  <Input
                    display="block"
                    placeholder-color="LightGray"
                    width="100%"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="Phone Number*"
                    className="bordb2"
                    font-weight="500"
                    margin="0px 24px 0px 0px"
                    sm-margin="0px 0 16px 0px"
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <ErrorComponent error={formik.errors.phoneNumber} />
                  )}
                </Box>

                <Box width="50%" sm-width="100%">
                  <Input
                    display="block"
                    placeholder-color="LightGray"
                    font-weight="500"
                    width="100%"
                    height="60px"
                    border-width="0 0 1px 0"
                    border-style="solid"
                    placeholder="Email Id*"
                    className="bordb2"
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email && (
                    <ErrorComponent error={formik.errors.email} />
                  )}
                </Box>
              </Box>

              <Box align-items="center" margin="0px 0px 32px 0px">
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  font-weight="500"
                  width="100%"
                  margin="0px 0px 0px 0px"
                  height="60px"
                  border-width="0 0 1px 0"
                  border-style="solid"
                  placeholder="Your Message"
                  className="bordb2"
                  type="message"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message && (
                  <ErrorComponent error={formik.errors.message} />
                )}
              </Box>

              <Box
                className="lg:mt-8  md:mt-8 mt-4 hoverText"
                id="3847755296"
                onClick={() => isSideBarOpen("3847755296")}
              >
                <Components.Btn
                  width="100%"
                  height="max-content"
                  color="--light"
                  padding="24px 24px 24px 24px"
                  sm-padding="16px 24px 16px 24px"
                  sm-align-items="center"
                  sm-justify-content="center"
                  md-padding="16px 24px 16px 24px"
                  border-radius="10px"
                  className="textwhite skinButton"
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          </Box>
        </Box>
      </Section>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={MapProps.center}
        defaultZoom={MapProps.zoom}
      >
        {/* <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" /> */}
      </GoogleMapReact>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
