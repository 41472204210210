const initialState = {
  brandProfile: {},
};
const get_brandDetails = (state = initialState, action) => {
  switch (action.type) {
    case "getBrandDetails":
      return { ...state, brandProfile: action.payload };
    
    default:
      return state;
  }
};

export default get_brandDetails;